import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import ErrorIcon from '@material-ui/icons/Error';
import styles from './styles';
import clsx from 'clsx';

export const CustomTextField = ({ id, fullWidth, label, error, redError, helperText, type, value, handleBlur, handleChange, ...props }) => {
  const classes = styles();

  return (
    <div className={clsx(classes.mainContainer, { [classes.fullWidthContainer]: fullWidth }, { [classes.multiLineContainer]: props.multiline })}>
      <FormControl variant="filled" className={classes.form}>
        <InputLabel color="secondary" error={error} classes={{ root: classes.rootError, error: classes.error }} htmlFor="filled-adornment-textfield">{label}</InputLabel>
        <FilledInput
          classes={{ root: clsx(classes.root, { [classes.rootMultiline]: props.multiline }, { [classes.rootFullWidth]: fullWidth }) }}
          disableUnderline
          error={error}
          type={type}
          id={id}
          name={id}
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
          inputProps={{ min: 0 }}
          {...props}
        />
        <FormHelperText error={error} classes={{ root: redError ? classes.redRootError : classes.rootError, error: classes.error }} htmlFor="filled-adornment-textfield">
          <span className={classes.helperText}>
            {helperText
              && <ErrorIcon className={classes.icon} />}
            {helperText}
          </span>
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default CustomTextField;
