import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { IconButton, Typography, Grid, List, ListItem, ListItemIcon, ListItemText, Box, Hidden } from '@material-ui/core';

import Loader from '../../Loader';
import QuoteCategoriesIcon from '../QuoteCategoriesIcon';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import { setQuotePage } from '../../../store/reducer';
import { renderCategoryChapterTitle } from '../../../utils/services/formats';
import styles from './styles';

export const CotationDrawerContent = ({list, hourSelected, openCategoryList, setListOpen }) => {
  const classes = styles({hourSelected});
  const dispatch = useDispatch();

  const { getMedicActsLoading } = useSelector((state) => ({
    getMedicActsLoading: state.getIn(['store', 'getMedicActsLoading']),
  }));

  const handleGoToQuotePage1 = () => {
    dispatch(setQuotePage(1));
    setListOpen(false);
  };

  const chapters = [
    [...new Set(list?.filter(x => x.chapter.includes("Chapitre 1"))?.map(item => item.type))],
    [...new Set(list?.filter(x => x.chapter.includes("Chapitre 2"))?.map(item => item.type))],
    [...new Set(list?.filter(x => !x.chapter)?.map(item => item.type))]
  ]

  return (
    <div className={classes.container}>
      <Grid item container alignItems="center" justify="center" className={classes.titleContainer}>
        <Hidden mdDown implementation="css">
            <Typography className={classes.title}>CATÉGORIES</Typography>
        </Hidden>
      </Grid>
      {getMedicActsLoading
        ? <Loader loaderColor="white" />
        : (
          <List className={classes.list}>
            {chapters?.map((chapter, chapterKey) => {
              return (
                <div key={chapterKey}>
                  <Box m={2}>
                    {chapter?.length > 0 && (
                      <Typography variant="h5" className={classes.chapter}>{chapterKey + 1} <span className={classes.titleHidden}>- {renderCategoryChapterTitle(chapterKey)}</span></Typography>
                    )}
                  </Box>
                  {chapter.map((item, key) => {
                    return (
                      <ListItem
                        className={classes.itemList}
                        button
                        key={key}
                        disabled={!hourSelected}
                        onClick={() => openCategoryList(list?.filter(category => category?.type === item && (category?.chapter.includes(chapterKey + 1) || category?.type?.includes("téléconsultation"))))}
                      >
                        <ListItemIcon className={classes.itemIcon}>
                          <QuoteCategoriesIcon title={item.toUpperCase()} />
                        </ListItemIcon>
                        <Hidden smDown>
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body1', className: classes.itemText}}
                            primary={item?.trim().toUpperCase()}
                          />
                        </Hidden>
                      </ListItem>
                    )
                  })}
                </div>
              )
            })}
          </List>
        )}
      <Grid item container justify="center" className={classes.backButtonContainer}>
        <IconButton onClick={handleGoToQuotePage1}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} className={classes.backButton}/>
        </IconButton>
      </Grid>
    </div>
  );
};
export default CotationDrawerContent;

CotationDrawerContent.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    medicalAct: PropTypes.string.isRequired
  })),
  hourSelected: PropTypes.bool,
  openCategoryList: PropTypes.func.isRequired,
};

CotationDrawerContent.defaultProps = {
  hourSelected: false,
};