import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    minHeight: '40vh'
  },
  bold: {
    fontWeight: 'bold'
  }
}));
