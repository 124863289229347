import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography, Box } from '@material-ui/core';
import { RequestForm, SubmitForm } from '../../components/ForgotPasswordForm';
import { requestPasswordCodeLaunched, submitNewPasswordLaunched } from '../../components/App/reducer';
import styles from './styles';
import { CustomNavLink } from '../../components/NavLink';
import Subtitle from '../../components/Subtitle';

const ForgotPassword = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { forgotPasswordStep } = useSelector((state) => ({
    forgotPasswordStep: state.getIn(['app', 'forgotPasswordStep'])
  }));
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  //  Forms Data
  const RequestInitialValues = {
    email: ''
  };

  const SubmissionInitialValues = {
    code: '',
    password: '',
    confirmPassword: ''
  };

  // Forms Submitting Functions
  const requestCode = (payload) => {
    setEmail(payload.email);
    dispatch(requestPasswordCodeLaunched(payload));
  };

  const submitNewPassword = (payload) => {
    const data = { username: email, ...payload }
    dispatch(submitNewPasswordLaunched(data));
  };

  // Forms Validation Schemas
  const RequestValidationSchema = Yup.object().shape({
    email: Yup
      .string()
      .email('emailNotValid')
      .required('emailRequired')

  });

  const SubmissionValidationSchema = Yup.object().shape({
    code: Yup
      .string()
      .required('Merci de renseigner le code reçu par email'),
    password: Yup
      .string()
      .min(8, "8 caractères minimum")
      .required('Merci de renseigner votre nouveau mot de passe'),
    confirmPassword: Yup.string().test('newPassword-match', "Les mots de passe ne correspondent pas", function (confNewPass) {
      return confNewPass === this.parent.password;
    }).required("Merci de confirmer votre nouveau mot de passe")
  });

  const renderForms = () => {
    if (forgotPasswordStep === 1) {
      return (
        // Requesting Code
        <>
          <Grid item sm={10}>
            <Box my={5}>
              <Typography className={classes.subtitle}>{t('forgotPassword.text')}</Typography>
            </Box>
          </Grid>
          <Formik
            render={(props) => <RequestForm {...props} />}
            initialValues={RequestInitialValues}
            validationSchema={RequestValidationSchema}
            onSubmit={requestCode}
          />
        </>
      );
    }
    return (
      // Sending New Password
      <>
        <Grid container direction="column" alignItems="center">
          <Grid item sm={10}>
            <Box my={5}>
              <Typography className={classes.subtitle}>{t('forgotPassword.submitSubtitle')} <span className={classes.emailAddress}>{email} </span>{t('forgotPassword.submitSubtitle2')}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Formik
          render={(props) => <SubmitForm {...props} />}
          initialValues={SubmissionInitialValues}
          validationSchema={SubmissionValidationSchema}
          onSubmit={submitNewPassword}
        />
      </>
    );
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.wrapper}
    >
      <Grid item md={8} container justify="center">
        <Typography variant="h1">{t('forgotPassword.title')}</Typography>
        {renderForms()}
        <CustomNavLink to="/login" text={t('loginButton')} />
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
