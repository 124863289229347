import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@material-ui/core';
import { CustomButton } from '../Button';
import { CustomTextField } from '../Inputs/CustomTextField';
import styles from '../../utils/styles';

const RequestForm = (props) => {
  const { t } = useTranslation();
  const classes = styles();

  const { requestCodeLoading } = useSelector((state) => ({
    requestCodeLoading: state.getIn(['app', 'requestCodeLoading'])
  }));
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit
  } = props;
  const { email } = values;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item className={classes.fieldWrapper}>
        <Box my={3}>
          <CustomTextField
            id="email"
            name="email"
            value={email}
            onBlur={handleBlur}
            onChange={handleChange}
            label={t('email')}
            error={!!touched.email && !!errors.email}
            helperText={touched.email && errors.email ? t(errors.email) : ''}
            fullWidth
          />
        </Box>
      </Grid>
      <CustomButton
        type="submit"
        handleClick={() => { handleSubmit({ email }); }}
        loading={requestCodeLoading}
        title={t('requestCodeButton')}
      />
    </Grid>
  );
};

export default RequestForm;
