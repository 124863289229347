import {
  all, put, takeLatest, delay
} from 'redux-saga/effects';
import { API } from 'aws-amplify';
import { config, share } from '../conf/amplify';
import { push } from 'connected-react-router';
import { SEARCH_LIMIT } from '../utils/constants';

import {
  postContractsSuccess,
  postContractsFailure,
  getContractsSuccess,
  getContractsFailure,
  getInvoicesSuccess,
  getInvoicesFailure,
  postQuotesSuccess,
  postQuotesFailure,
  getQuotesSuccess,
  getQuotesFailure,
  getMedicActsSuccess,
  getMedicActsFailure,
  getAllContentsSuccess,
  getAllContentsFailure,
  getContentSuccess,
  getContentFailure,
  contactSuccess,
  contactFailure,
  getAllQuotesSuccess,
  getAllQuotesFailure,
  getAllContractsByUserSuccess,
  getAllContractsByUserFailure,
  updateContractsSuccess,
  updateContractsFailure, getAllContractsByUserLaunched,
  getSearchSuccess,
  getSearchFailure,
  getContentTypeEntriesSuccess,
  getContentTypeEntriesFailure
} from './reducer';
import news from '../mock/news';
import article from '../mock/new';
import traceabilities from '../mock/traceabilities';
import legalFrame from '../mock/legalFrame';
import litigation from '../mock/litigation';
import formations from '../mock/formations';
import { FORMATION } from '../utils/constants';
import { sortArrayOfObjectsOnFieldName, addTimestampToObjectFromNestedField } from '../utils/services/formats';
import { openPopup } from '../components/App/reducer';
import { quotes } from '../mock/quotes';
import contractsByUser from '../mock/contractsByUser.json';

// CONFIG
const contentful = require('contentful')
const client = contentful.createClient({
  accessToken: process.env.REACT_APP_GATSBY_CONTENTFUL_ACCESS_TOKEN,
  space: process.env.REACT_APP_GATSBY_CONTENTFUL_SPACE_ID,
});

const customHeader = {
  'x-api-key': config.apiKey
};

function* postContracts(action) {
  try {
    const contracts = yield API.post(config.apiGateway.NAME, '/contracts', {
      headers: customHeader,
      body: action.payload
    });
    yield put(postContractsSuccess(contracts));
  } catch (error) {
    console.log(error);
    yield put(postContractsFailure());
  }
}

function* getContracts(action) {
  try {
    const contracts = yield API.get(config.apiGateway.NAME, '/contracts', {
      headers: customHeader,
      body: action.payload
    });
    yield put(getContractsSuccess(contracts));
  } catch (error) {
    console.log(error);
    yield put(getContractsFailure());
  }
}

function* updateContracts(action) {
  const { contractId, startDate, endDate, paymentDone } = action.payload;
  try {
    const contracts = yield API.put(config.apiGateway.NAME, `/contracts/${contractId}`, {
      headers: customHeader,
      body: {
        startDate,
        endDate,
        paymentDone
      }
    });
    yield put(updateContractsSuccess(contracts));
    yield put(openPopup({ open: true, message: 'Le contrat a été mis à jour avec succès.', error: false, popupId: "update contract error" }))
    yield put(getAllContractsByUserLaunched())
  } catch (error) {
    console.log(error);
    yield put(openPopup({ open: true, message: 'Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement', error: true, popupId: "post quote error" }))
    yield put(updateContractsFailure());
  }
}

function* getInvoices(action) {
  const { invoiceId, userId } = action.payload;
  const endpoint = userId ? `/invoices?userId=${userId}` : `/invoices/${invoiceId}`;
  try {
    const invoices = yield API.get(config.apiGateway.NAME, endpoint, {
      headers: customHeader
    });
    yield put(getInvoicesSuccess(invoices));
  } catch (error) {
    console.log(error);
    yield put(getInvoicesFailure());
  }
}

function* postQuotes(action) {
  try {
    const result = yield API.post(config.apiGateway.NAME, `/quotes`, {
      headers: customHeader,
      body: action.payload
    });
    yield put(postQuotesSuccess(result));
    yield put(push(`cotation-synthese/${result?.id}`))
  } catch (error) {
    console.log(error);
    yield put(postQuotesFailure());
    yield put(openPopup({ open: true, message: 'Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement', error: true, popupId: "post quote error" }))
  }
}

function* getQuotes(action) {
  const { quoteId } = action.payload;
  let count = 0, results;
  const getQuotesCount = share.getQuotesCount || 10;

  while (count < getQuotesCount && !results) {
    try {
      const cotation = yield API.get(config.apiGateway.NAME, `/quotes/${quoteId}`, {
        headers: customHeader
      });
      if (cotation?.link) {
        results = cotation
      }
    } catch (error) {
      console.log(error);
      count = 10;
      yield put(getQuotesFailure());
      yield put(openPopup({ open: true, message: 'Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement', error: true, popupId: "get quote by id" }))
    }
    count++;
    yield delay(500);
  }

  if ((count > (getQuotesCount - 1)) && !results) {
    yield put(getQuotesFailure());
    yield put(openPopup({ open: true, message: 'Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement', error: true, popupId: "get quote by id" }))
  } else {
    yield put(getQuotesSuccess(results));
  }
}

function* getMedicActs(action) {
  try {
    const cotation = yield API.get(config.apiGateway.NAME, `/medicActs`, {
      headers: customHeader
    });
    yield put(getMedicActsSuccess(cotation));
  } catch (error) {
    console.log(error);
    yield put(getMedicActsFailure());
  }
}

function* getAllContents(action) {
  const { contentType, date, section } = action.payload;
  let queryParams;
  if (section) {
    queryParams = `?contentType=${contentType}&section=${section}`
  } else {
    queryParams = `?contentType=${contentType}`;
  }
  try {
    // COMMENT TO USE MOCK
    let contents = yield API.get(config.apiGateway.NAME, `/contents${queryParams}`, {
      headers: customHeader
    });
    // UNCOMMENT TO USE MOCK
    // yield delay(2000);
    // let contents;
    let sortedPrevSessions, sortedFutureSessions;
    if (contentType === FORMATION) {
      const formattedContentWithTimestampField = addTimestampToObjectFromNestedField(contents)
      // const formattedContentWithTimestampField = addTimestampToObjectFromNestedField(formations)
      const prevSessions = formattedContentWithTimestampField.filter(content => content.fields.date < date);
      const futureSessions = formattedContentWithTimestampField.filter(content => content.fields.date >= date);
      sortedPrevSessions = sortArrayOfObjectsOnFieldName(prevSessions, 'timestamp');
      sortedFutureSessions = sortArrayOfObjectsOnFieldName(futureSessions, 'timestamp').splice(0, 6).reverse();
    }
    switch (contentType) {
      // case 'PREMIUM_ARTICLE':
      //   contents = news;
      //   break;
      // case 'TRACABILITY':
      //   contents = traceabilities;
      //   break;
      // case 'LEGAL':
      //   if (action.payload.section === "CONTENTIEUX") {
      //     contents = litigation;
      //   } else if (action.payload.section === "CADRE_JURIDIQUE") {
      //     contents = legalFrame;
      //   }
      //   break;
      case FORMATION:
        contents = {
          previousSessions: sortedPrevSessions,
          futureSessions: sortedFutureSessions
        }
        break;
      default:
        break;
    }
    yield put(getAllContentsSuccess(contents));
  } catch (error) {
    console.log(error);
    yield put(getAllContentsFailure());
  }
}

function* getContent(action) {
  const { contentId } = action.payload;
  try {
    // COMMENT TO USE MOCK
    const contents = yield API.get(config.apiGateway.NAME, `/contents/${contentId}`, {
      headers: customHeader
    });
    // UNCOMMENT TO USE MOCK
    // yield delay(2000);
    // const contents = article
    // const contents = formations[0];

    yield put(getContentSuccess(contents));
  } catch (error) {
    console.log(error);
    yield put(getContentFailure());
  }
}

function* contact(action) {
  const { theme, message, type, infos, ...props } = action.payload;
  let body = {
    type
  };
  if (type === "FORMATION_MESSAGE") {
    body = {
      ...body,
      ...infos
    }
  } else {
    body = action.payload
  }
  try {
    const contents = yield API.post(config.apiGateway.NAME, `/messages`, {
      headers: customHeader,
      body
    });
    yield put(contactSuccess(contents));
    yield put(openPopup({ open: true, message: 'Votre demande nous a bien été transmise. \nNous vous recontacterons le plus rapidement possible.', error: false, popupId: "contact" }))
  } catch (error) {
    console.log(error);
    yield put(contactFailure());
    yield put(openPopup({ open: true, message: 'Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement', error: true, popupId: "contact" }))
  }
}

function* getAllQuotes({ payload }) {
  try {
    // Uncomment to use mocks
    // yield delay(2000);
    // let quotesData = quotes;
    const ENDPOINT = payload ? `/quotes?userId=${payload}` : `/quotes`
    const quotesData = yield API.get(config.apiGateway.NAME, ENDPOINT, {
      headers: customHeader
    });
    yield put(getAllQuotesSuccess(quotesData));
  } catch (error) {
    console.log(error);
    yield put(getAllQuotesFailure());
    yield put(openPopup({ open: true, message: 'Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement', error: true, popupId: "get all quotes" }))
  }
}

function* getAllContractsByUser(action) {
  try {
    // Uncomment to use mocks
    // yield delay(2000);
    // let contractsData = contractsByUser;
    const contractsData = yield API.get(config.apiGateway.NAME, `/contractsByUser`, {
      headers: customHeader
    });
    yield put(getAllContractsByUserSuccess(contractsData));
  } catch (error) {
    console.log(error);
    yield put(getAllContractsByUserFailure());
    yield put(openPopup({ open: true, message: 'Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement', error: true, popupId: "get all contracts by user" }))
  }
}

// SEARCH
function* doGetSearchResults(action) {
  const { value, skip } = action.payload;
  try {
    /**
     * @see https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/full-text-search
     */
    const res = yield client.getEntries({
      query: value,
      limit: SEARCH_LIMIT,
      skip,
      include: 3
    })
    if (res.items) {
      //const contentTypesToFilter = ['sponsors', 'seo', 'Legal', 'pub', 'video', 'categories', 'subCategory'] // To filter more content types, add the value of item.sys.contentType.sys.id
      const contentTypesToKeep = ['articles', 'installation', 'formation', 'document'];
      const filteredItems = res.items.filter(item => contentTypesToKeep.includes(item.sys.contentType.sys.id));
      yield put(getSearchSuccess({ items: filteredItems, res }))
    }
    //yield put(push('/resultats-recherche'))
  } catch (error) {
    yield put(getSearchFailure())
    yield put(openPopup({ open: true, message: "Impossible de récupérer les résultats de recherche, merci de réessayer dans quelques instants", error: true, popupId: "search-error" }))
  }
}

// GET CONTENT TYPE ENTRIES
/**
 * @see https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/order/query-entries/console/js
 */
 function* doGetContentTypeEntries(action) {
  const { id, date, limit } = action.payload;
  try {
    let params = {
      content_type: id,
    }
    if (date) {
      params = {
        ...params,
        'fields.date[gte]': date,
        order: 'fields.date'
      }
    }
    if (limit) {
      params = {
        ...params,
        limit,
      }
    }
    const results = yield client.getEntries(params)
    yield put(getContentTypeEntriesSuccess(results.items))
  } catch (error) {
    yield put(getContentTypeEntriesFailure())
    yield put(openPopup({ open: true, message: "Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement", error: true, popupId: "get content type entries" }))
  }
}

export default function* saga() {
  yield all([
    takeLatest('store/postContractsLaunched', postContracts),
    takeLatest('store/getContractsLaunched', getContracts),
    takeLatest('store/updateContractsLaunched', updateContracts),
    takeLatest('store/getInvoicesLaunched', getInvoices),
    takeLatest('store/postQuotesLaunched', postQuotes),
    takeLatest('store/getQuotesLaunched', getQuotes),
    takeLatest('store/getMedicActsLaunched', getMedicActs),
    takeLatest('store/getAllContentsLaunched', getAllContents),
    takeLatest('store/getContentLaunched', getContent),
    takeLatest('store/contactLaunched', contact),
    takeLatest('store/getAllQuotesLaunched', getAllQuotes),
    takeLatest('store/getAllContractsByUserLaunched', getAllContractsByUser),
    takeLatest('store/getSearchLaunched', doGetSearchResults),
    takeLatest('store/getContentTypeEntriesLaunched', doGetContentTypeEntries),
  ]);
}
