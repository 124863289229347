import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles';

export const CustomNavLink = ({ to, text, asButton }) => {
  const classes = styles({ asButton });
  return (
    <NavLink className={classes.navLink} to={to}>{text}</NavLink>
  );
};

export default CustomNavLink;
