import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    '& table > *': {
      borderBottom: 'unset',
    },
    width: '100%',

  },
  bold: {
    fontWeight: 'bold',
  },
  noBorder: {
    border: 'none'
  },
  date: {
    marginLeft: 5,
    marginRight: 5,
    height: 80,
    width: 180
  },
  colWidth: {
    width: 200
  },
  tableHead: {
    width: '100%'
  },
  smallColumn: {
    width: '10%'
  },
  noPadding: {
    padding: 0
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: -10,
      maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 34,
    },
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff'
  },
  redError: {
    color: theme.palette.error.main
  },
  icon: {
    marginRight: 5
  },
  action: {
    width: "70%",
  },
  field: {
    width: "15%"
  },
  button: {
    marginLeft: 5,
    marginRight: 5,
    width: 230
  },
  contractButton: {
    marginLeft: 5,
    marginRight: 5,
    width: 180
  },
  hasNotValidContract: {
    backgroundColor: '#dbdada'
  }
}));