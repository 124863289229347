import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styles from './style';
import { TitleBanner } from '../../components/TitleBanner';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { CustomTextField } from '../../components/Inputs/CustomTextField';
import { CustomButton } from '../../components/Button';
import { contactLaunched } from '../../store/reducer';
import CustomSelect from '../../components/Inputs/CustomSelect';
import vidalLogo from '../../assets/image/logo-vidal.svg';

export const ContactPage = (props) => {
  const dispatch = useDispatch();
  const classes = styles();

  const { contactLoading, user } = useSelector((state) => ({
    contactLoading: state.getIn(['store', 'contactLoading']),
    user: state.getIn(['app', 'userDynamo', 'user']),
  }));

  const initialValues = {
    theme: '',
    message: ''
  };

  const selectValues = [{ key: "Contentieux assurance maladie", value: "Contentieux assurance maladie" }, { key: "Contentieux ordinal", value: "Contentieux ordinal" }, { key: "Contrat", value: "Contrat" }, { key: "Autres", value: "Autres" }];

  // Form Submitting Function
  const handleSubmit = (params) => {
    let body = { userId: user.id, type: "LEGAL_MESSAGE", message: params.message, category: params.theme }
    dispatch(contactLaunched(body));
  };

  // Form Validation Schema
  const ValidationSchema = Yup.object().shape({
    theme: Yup
      .string()
      .required('Le thème est requis'),
    message: Yup
      .string()
      .required('Le message est requis')
  });

  return (
    <Grid item container direction="column" alignItems="center" className={classes.container}>
      <Grid item container className={classes.titleContainer}
      >
        <img src={vidalLogo} className={classes.logo}/>
        <Typography component="h1" variant="h3" className={classes.title}>CONTACTEZ L' AIDE JURIDIQUE</Typography>
      </Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {(props) =>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            alignContent="center"
            className={classes.formContainer}
          >
            <CustomSelect
              name="theme"
              label={"Thème*"}
              value={props.values.theme.value}
              defaultValue=""
              options={selectValues}
              onChange={props.handleChange}
              error={!!props.touched.theme && !!props.errors.theme}
              helperText={props.touched.theme && props.errors.theme ? props.errors.theme : ''}
            />
            <CustomTextField
              multiline
              fullWidth
              redError
              name="message"
              label={"Message*"}
              value={props.values.message}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              error={!!props.touched.message && !!props.errors.message}
              helperText={props.touched.message && props.errors.message ? props.errors.message : ''}
            />
            <Typography className={classes.mandatory}>* champs obligatoires</Typography>
            <Grid item container justify="flex-end" className={classes.buttonContainer}>
              <CustomButton
                type="submit"
                variant="contained"
                color="primary"
                loading={contactLoading}
                handleClick={() => props.handleSubmit()}
                title={"Envoyer"}
              />
            </Grid>
          </Grid>
        }
      </Formik>
    </Grid>
  );
};

export default ContactPage;
