import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';

export const CustomButton = ({
  loading, title, type, handleClick, variant, color, withoutPadding, disabled
}) => {
  const classes = styles({ withoutPadding });
  return (
    <Button
      variant={variant || 'outlined'}
      color={color || 'primary'}
      type={type}
      onClick={handleClick}
      className={classes.button}
      disabled={disabled}
    >
      {loading ? <CircularProgress color="primary" size={28} /> : title}
    </Button>
  );
};

export default CustomButton;
