import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  container: {
    padding: '1em',
    borderRadius: 5,
    backgroundColor: '#323232',
    fontSize: 17,
    color: 'white'
  },
  error: {
    backgroundColor: '#f44336',
  },
  infos: {
    backgroundColor: '#2196f3',
  },
  success: {
    backgroundColor: '#4caf50',
  },
  closeIcon: {
    right: -5,
    marginLeft: 10,
    color: 'white'
  },
  icon: {
    marginRight: 10
  }
}));
