import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSyringe,
  faNotesMedical,
  faExclamationCircle,
  faBriefcaseMedical,
  faDumbbell,
  faUsers,
  faLink,
  faClinicMedical,
  faUserNurse,
  faUserGraduate
} from '@fortawesome/free-solid-svg-icons'
import {
  faFileAlt,
  faFolderOpen,
  faBuilding
} from '@fortawesome/free-regular-svg-icons'
import { JURIDIQUE, TRACABILITE } from '../../utils/constants';

export const DocumentCategoryIcon = ({ section, index }) => {
  const classes = styles();

  if (section === TRACABILITE) {
    switch (index) {
      case 1:
        return <FontAwesomeIcon icon={faFolderOpen} className={classes.icon} />;
      case 2:
      case 5:
        return <FontAwesomeIcon icon={faBuilding} className={classes.icon} />;
      case 3:
        return <FontAwesomeIcon icon={faSyringe} className={classes.icon} />;
      case 4:
        return <FontAwesomeIcon icon={faFileAlt} className={classes.icon} />;
      case 6:
        return <FontAwesomeIcon icon={faNotesMedical} className={classes.icon} />;
      default:
        return <FontAwesomeIcon icon={faExclamationCircle} className={classes.icon} />;
    }
  } else if (section === JURIDIQUE) {
    switch (index) {
      case 1:
        return <FontAwesomeIcon icon={faBriefcaseMedical} className={classes.icon} />;
      case 2:
        return <FontAwesomeIcon icon={faDumbbell} className={classes.icon} />;
      case 3:
        return <FontAwesomeIcon icon={faUsers} className={classes.icon} />;
      case 4:
        return <FontAwesomeIcon icon={faLink} className={classes.icon} />;
      case 5:
        return <FontAwesomeIcon icon={faClinicMedical} className={classes.icon} />;
      case 6:
        return <FontAwesomeIcon icon={faUserNurse} className={classes.icon} />;
      case 7:
        return <FontAwesomeIcon icon={faUserGraduate} className={classes.icon} />;
      default:
        return <FontAwesomeIcon icon={faExclamationCircle} className={classes.icon} />;
    }
  } else {
    return <FontAwesomeIcon icon={faExclamationCircle} className={classes.icon} />;
  }
}

export default DocumentCategoryIcon;

DocumentCategoryIcon.propTypes = {
  section: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};