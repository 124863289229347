import {
  all, put, takeLatest, call, delay
} from 'redux-saga/effects';
import { API, Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import {
  getCurrentSessionSuccess,
  getCurrentSessionFailure,
  getCurrentSessionLaunched,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  signupSuccess,
  signupFailure,
  requestPasswordCodeFailure,
  requestPasswordCodeSuccess,
  submitNewPasswordSuccess,
  submitNewPasswordFaliure,
  updateUserFailure,
  updateUserSuccess, closeSnackBar, clearSnackBar,
  getUserSuccess,
  getUserFailure,
  getAllUsersSuccess,
  getAllUsersFailure, doPaymentSuccess,
  changePasswordSuccess,
  changePasswordFailure,
  openPopup,
  doPaymentFailure, resetPopupStore,
} from './reducer';
import {
  translateSignInError,
  translateSignUpError,
  translateConfirmForgotPassword,
  translateForgotPassword
} from '../../utils/cognito';
import { config } from '../../conf/amplify';
import { CardNumberElement, IbanElement } from '@stripe/react-stripe-js';

function* getCurrentSession(action) {
  const { fromPath } = action.payload;
  try {
    yield Auth.currentSession();
    const userInfo = yield Auth.currentUserInfo();
    const userDynamo = yield API.post(config.apiGateway.NAME, '/sessions', {
      headers: {
        'x-api-key': config.apiKey
      },
      body: {
        email: userInfo.attributes.email
      }
    });
    yield put(getCurrentSessionSuccess({ userInfo, userDynamo }));
    if (!userDynamo.user.isAdmin) {
      if (userDynamo.contract.paymentDone === undefined || (userDynamo.contract.paymentDone === false && userDynamo.contract.paymentStatus === 'paymentFailed')) {
        yield put(push('/payment'));
      } else if (userDynamo.contract.paymentDone === true) {
        if (fromPath === '/payment' || fromPath === undefined) {
          yield put(push('/home'));
        } else {
          yield put(push(fromPath));
        }
      } else if (userDynamo.contract.paymentDone === false) {
        yield put(push('/profil'));
      }
    } else {
      yield put(push(fromPath))
    }
  } catch (error) {
    console.log(error);
    yield put(getCurrentSessionFailure());
  }
}

function* doSignIn(action) {
  const { email, password, from, redirect } = action.payload;

  try {
    yield Auth.signIn(email, password);
    yield put(loginSuccess());
  } catch (err) {
    yield put(loginFailure(translateSignInError(err.code)));
    yield put(openPopup({ open: true, message: translateSignInError(err.code), error: true, popupId: "login" }))
  }
  if (redirect) {
    yield put(getCurrentSessionLaunched({ fromPath: from || '/home' }));
  }
}

function* doSignOut() {
  try {
    yield Auth.signOut();
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutFailure());
  }
  yield put(getCurrentSessionLaunched('/home'));
}

function* doSignUp(action) {
  const { firstName, lastName, phone, email, password } = action.payload;
  try {
    yield Auth.signUp({ username: email, password });
    // On signup, we don't want to redirect before the /users, but only after (getCurrentSessionLaunche on l.106)
    yield call(doSignIn, { payload: { email, password, redirect: false } });
    const result = yield API.post(config.apiGateway.NAME, `/users`, {
      headers: {
        'x-api-key': config.apiKey
      },
      body: {
        firstName,
        lastName,
        phone,
        email
      }
    })
    yield put(getCurrentSessionLaunched({ fromPath: '/profil' }));
    yield put(signupSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(signupFailure(translateSignUpError(error.code)));
    yield put(openPopup({ open: true, message: translateSignUpError(error.code), error: true, popupId: "signupFailure" }))

  }
}

function* doRequestPasswordCode(action) {
  const { email } = action.payload;

  try {
    yield Auth.forgotPassword(email);
    yield put(requestPasswordCodeSuccess());
  } catch (error) {
    yield put(requestPasswordCodeFailure(translateForgotPassword(error.code)));
    yield put(openPopup({ open: true, message: translateConfirmForgotPassword(error.code), error: true, popupId: "request code" }))
  }
}

function* doSubmitNewPassword(action) {
  const {
    username, code, password
  } = action.payload;
  try {
    yield Auth.forgotPasswordSubmit(username, code, password);
    yield put(submitNewPasswordSuccess());
    yield put(openPopup({ open: true, message: "Votre mot de passe est modifié", error: false, popupId: "forgot password" }))
  } catch (error) {
    yield put(submitNewPasswordFaliure(translateConfirmForgotPassword(error.code)));
    yield put(openPopup({ open: true, message: translateConfirmForgotPassword(error.code), error: true, popupId: "forgot password" }))
  }
}

function* doGetUser(action) {
  const { userId } = action.payload;
  try {
    const user = yield API.get(config.apiGateway.NAME, `/users/${userId}`, {
      headers: {
        'x-api-key': config.apiKey
      }
    });
    yield put(getUserSuccess(user));
  } catch (error) {
    yield put(getUserFailure(error));
  }
}

function* doGetAllUsers(action) {
  try {
    const users = yield API.get(config.apiGateway.NAME, `/users`, {
      headers: {
        'x-api-key': config.apiKey
      }
    });
    yield put(getAllUsersSuccess(users));
  } catch (error) {
    yield put(getAllUsersFailure(error));
  }
}

function* doUpdateUser(action) {
  const { payload, userId } = action.payload;
  payload.userId = userId;

  if (typeof payload.zipCode === "string") {
    delete payload.zipCode;
  }
  if (payload.city === "") {
    delete payload.city;
  }
  const userInfo = yield Auth.currentAuthenticatedUser();
  const previousEmail = userInfo.attributes.email;

  try {
    const result = yield API.put(config.apiGateway.NAME, `/users/${userId}`, {
      headers: {
        'x-api-key': config.apiKey
      },
      body: payload
    });

    if (payload.email !== previousEmail) {
      try {
        yield Auth.updateUserAttributes(userInfo, { email: payload.email })

      } catch (error) {

        const cleanPayload = { userId, previousEmail }
        yield API.put(config.apiGateway.NAME, `/users/${userId}`, {
          headers: {
            'x-api-key': config.apiKey
          },
          body: cleanPayload
        });

        yield put(updateUserFailure(error));
        yield put(openPopup({ open: true, message: 'Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement', error: true, popupId: "update cognito email" }))
      }
    }
    yield put(updateUserSuccess({ userId }));
    // yield put(getCurrentSessionLaunched('/home'));
    yield put(openPopup({ open: true, message: "Vos modifications ont été prises en compte", error: false, popupId: "update user & cognito" }))
  } catch (error) {
    yield put(updateUserFailure(error));
    yield put(openPopup({ open: true, message: 'Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement', error: true, popupId: "update user" }))
  }
}

function* doPayment(action) {
  const { paymentMethod, billing_details, elements, stripe } = action.payload;
  try {
    let body = {
      paymentMethod: paymentMethod,
      paymentCurrency: "eur",
      type: "STANDARD_YEARLY"
    };
    if (paymentMethod === "CB") {
      // eslint-disable-next-line no-undef
      body.items = [{ id: process.env.REACT_APP_STRIPE_PRODUCT_ID }]
    }
    const { contractId } = yield API.post(config.apiGateway.NAME, `/contracts`, {
      headers: {
        'x-api-key': config.apiKey,
      },
      body
    });
    if (paymentMethod === "CB" || paymentMethod === "SEPA_DIRECT_DEBIT") {
      const contract = yield API.get(config.apiGateway.NAME, `/contracts/${contractId}`, {
        headers: {
          'x-api-key': config.apiKey,
        }
      });
      const clientSecret = contract.paymentSecret
      if (paymentMethod === "CB") {
        const paymentStatus = yield stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)
          }
        });
        if (paymentStatus.error) {
          throw Error(paymentStatus.error.type)
        } else {
          yield put(doPaymentSuccess(paymentStatus));
          yield put(openPopup({ open: true, message: "Paiement réussi. Vous allez être redirigé automatiquement dans un instant...", error: false, popupId: "paymentSuccess" }))
          yield delay(4000);
          yield put(resetPopupStore())
          yield put(getCurrentSessionLaunched('/home'));
        }
      } else if (paymentMethod === "SEPA_DIRECT_DEBIT") {
        const paymentStatus = yield stripe.confirmSepaDebitSetup(clientSecret, {
          payment_method: {
            sepa_debit: elements.getElement(IbanElement),
            billing_details: billing_details,
          }
        });
        if (paymentStatus.error) {
          throw Error(paymentStatus.error.type)
        } else {
          yield put(doPaymentSuccess(paymentStatus));
          yield put(openPopup({ open: true, message: "Vous allez être redirigé vers une interface limité en attendant le validation de votre paiement.", error: false, popupId: "paymentSuccess" }))
          yield delay(4000);
          yield put(resetPopupStore())
          yield put(getCurrentSessionLaunched('/home'));
        }
      }
    } else {
      yield put(doPaymentSuccess());
      yield put(openPopup({ open: true, message: "Vous allez être redirigé vers une interface limité en attendant votre paiement.", error: false, popupId: "paymentSuccess" }))
      yield delay(4000);
      yield put(resetPopupStore())
      yield put(getCurrentSessionLaunched('/profil'));
    }
  } catch (error) {
    console.log(error);
    yield put(openPopup({ open: true, message: "Une erreur s'est produite. Votre paiement a été annulé.", error: true, popupId: "paymentFailure" }))
    yield delay(4000);
    yield put(resetPopupStore())
    yield put(doPaymentFailure(error));

  }
}

function* setSnackBar() {
  yield delay(5000);
  yield put(closeSnackBar());
  yield delay(200);
  yield put(clearSnackBar());
}

function* doChangePassword(action) {
  const { oldPassword, newPassword } = action.payload;
  try {
    const currentUser = yield Auth.currentAuthenticatedUser();
    yield Auth.changePassword(currentUser, oldPassword, newPassword);
    yield put(changePasswordSuccess());
    yield put(openPopup({ open: true, message: 'Le mot de passe a été changé avec succès', error: false, popupId: "change password" }))
  } catch (error) {
    console.log(error);
    yield put(changePasswordFailure());
    yield put(openPopup({ open: true, message: "Une erreur est survenue lors du changement de mot de passe", error: true, popupId: "change password" }))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest('app/getCurrentSessionLaunched', getCurrentSession),
    takeLatest('app/loginLaunched', doSignIn),
    takeLatest('app/logoutLaunched', doSignOut),
    takeLatest('app/signupLaunched', doSignUp),
    takeLatest('app/requestPasswordCodeLaunched', doRequestPasswordCode),
    takeLatest('app/submitNewPasswordLaunched', doSubmitNewPassword),
    takeLatest('app/updateUserLaunched', doUpdateUser),
    takeLatest('app/getUserLaunched', doGetUser),
    takeLatest('app/updateUserSuccess', doGetUser),
    takeLatest('app/getAllUsersLaunched', doGetAllUsers),
    takeLatest('app/doPaymentLaunched', doPayment),
    takeLatest('app/changePasswordLaunched', doChangePassword)
  ]);
}
