import { darken, fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    minHeight: "80vh"
  },
  container: {
    backgroundColor: "#fff",
  },
  articleContainer: {
    width: '80%',
    marginTop: 40,
  },
  img: {
    width: '100%',
    paddingBottom: 40,

    [theme.breakpoints.up("sm")]: {
      width: '80%',
      marginLeft: '10%',
    },
    [theme.breakpoints.up("md")]: {
      width: '50%',
      paddingRight: 70,
      float: 'left',
      marginLeft: 0
    }
  },
  subTitleContainer: {
    width: '100%',
    marginBottom: 30,
    [theme.breakpoints.up("sm")]: {
      width: '50%'
    }
  },
  article: {
    marginLeft: 50,
    marginRight: 50,
    marginBottom: 20
  },
  link: {
    color: "black"
  },
  buttonsWrapper: {
    flexDirection: 'column',
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "flex-end"
    }
  },
  button: {
    display: "flex",
    justifyContent: "center",
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1),
    }
  },
  buttonLink: {
    background: theme.palette.primary.main,
    borderRadius: 4,
    color: "#fff",
    textDecoration: "none",
    fontWeight: "bold",
    margin: `${theme.spacing(2)}px auto`,
    padding: '12px 40px',
    fontSize: 18,
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    transition: '.2s',
    '&:hover': {
      background: darken(theme.palette.primary.main, .25)
    }
  },
  underline: {
    textDecoration: 'underline'
  }
}));
