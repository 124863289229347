import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 200
  },
  loaderColor: {
    color: ({loaderColor}) => loaderColor === "white" ? '#fff' : theme.palette.primary.main
  }
}));
