import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import litigationPic from '../../assets/image/litigation.jpg';
import styles from './style';
import { getAllContentsLaunched } from '../../store/reducer';
import { TitleBanner } from '../../components/TitleBanner';
import Loader from '../../components/Loader';
import DownloadCard from '../../components/DownloadCard';
import Subtitle from '../../components/Subtitle';
import DocumentCategoryIcon from '../../components/DocumentCategoryIcon';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQueryParams(qName) {
  return (new URLSearchParams(useLocation().search)).get(qName);
}

export const LitigationPage = (props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { contentsList, getAllContentsLoading } = useSelector((state) => ({
    contentsList: state.getIn(['store', 'contentsList']),
    getAllContentsLoading: state.getIn(['store', 'getAllContentsLoading']),
  }));

  useEffect(() => {
    dispatch(getAllContentsLaunched({ contentType: 'LEGAL', section: 'CONTENTIEUX' }));
  }, []);

  const id = useQueryParams("id");

  return (
    <Grid item container direction="column" className={classes.container}>
      <TitleBanner title="CONTENTIEUX" img={litigationPic} color="secondary" />
      <Grid item container alignContent="center" justify="center">
        <Subtitle text={"Les IDEL sont de plus en plus en situation de litiges ou de différends avec l’Assurance maladie ou avec l’Ordre suite à une plainte de patient voire au sein de leur cabinet. Il ne faut rien méconnaitre pour bien se défendre et avoir recours aux bons professionnels du droit. Le SAIIL vous propose des fiches conseils et un accès direct par e-mail avec leur cabinet d’avocats spécialisés partenaire."} />
      </Grid>
      <Grid item container direction="row" alignContent="center" justify="center">
              <Button
                  variant="contained"
                  classes={{ root: classes.button }}
                  onClick={() => history.push('/contact')}
              >
                  <Typography variant="h1" className={classes.titleButton}>Contactez l'aide juridique</Typography>
              </Button>
              {
          !getAllContentsLoading && contentsList && Array.isArray(contentsList)
            ? contentsList.map((content) => {

              return (
                <DownloadCard
                  key={content.title}
                  color="secondary"
                  title={content.title}
                  items={content.subCategory}
                  icon={<DocumentCategoryIcon section={content.section} index={content.priority} />}
                  searchedId={id}
                />
              )
            }
            )
            : <Loader />
        }
      </Grid>
    </Grid>
  );
};

export default LitigationPage;
