import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import GobackButton from '../../GoBackButton';

export const PastSessionContent = ({ content }) => {
  const classes = styles();

  return (
    <>
      {content.video
        ? <Grid className={classes.videoContainer}>
          <video controls className={classes.video} width="80%">
            <source src={content.video.fields.file.url} type="video/mp4" />
            <track default kind="captions" srcLang="fr" src={content.video.fields.title} />
          </video>
        </Grid>
        : (
          content.pdf && (
            <Box my={5}>
              <Grid container alignItems="center">
                <Box mr={3}>
                  <Typography className={classes.contentTitle}>Retrouvez le contenu de la formation en pdf</Typography>
                </Box>
                <a href={content.pdf.fields.file.url} target="_blank" rel="noreferrer" download>
                  <FontAwesomeIcon icon={faFilePdf} size="3x" className={classes.icon} />
                </a>
              </Grid>
            </Box>
          )
        )}
            <Grid container className={classes.buttonsWrapper}>
              <Box className={classes.button}>
                <GobackButton />
              </Box>
            </Grid>
    </>
  );
};

export default PastSessionContent;

PastSessionContent.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    video: PropTypes.string,
    pdf: PropTypes.string,
  }).isRequired
};