import { makeStyles, fade } from '@material-ui/core/styles';
import { QUOTE_DRAWER_WIDTH } from '../../utils/constants';

const drawerWidth = QUOTE_DRAWER_WIDTH;
const smallDrawer = 55;

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  drawer: {
    width: smallDrawer,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    height: 100,
  },
  drawerPaper: {
    width: smallDrawer,
    borderRight: '0px solid black',
    overflow: 'hidden',
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 3)
  },
  // SEARCHBAR
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  list: {
    backgroundColor: theme.palette.primary.main,
    maxHeight: '77vh',
    overflow: 'auto',
    '-ms-overflow-style': 'none',
  },
  homeButton: {
    fontSize: 25,
    padding: 5,
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 40
    }
  },
  contentWrapper: {
    maxHeight: '80vh',
    scroll: 'auto'
  },
  checkIcon: {
    color: 'green',
    fontSize: 70
  },
  cancelIcon: {
    fontSize: 70
  },
  buttonRoot: {
    padding: 0
  },
  popupMessage: {
    color: 'black',
    fontWeight: 'bold',
    textTransform: 'initial',
    textAlign: 'center'
  },
  rootDialog: {
    background: fade("#fff", .9),
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    borderRadius: ({ squareModale }) => squareModale ? 0 : 183,
    width: '90%',
    padding: ({ squareModale }) => squareModale ? 0 : theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      padding: ({ squareModale }) => squareModale ? 0 : `${theme.spacing(5)}px ${theme.spacing(10)}px`
    },
  },
}));
