import React, { useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styles from './styles';
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Formik } from 'formik';
import * as Yup from "yup";
import DatePicker from "../DatePicker";
import clsx from "clsx";
import { formatDateToFrenchFormat } from "../../../utils/services/formats";
import { useHistory } from "react-router-dom";
import { getAllContractsByUserLaunched, updateContractsLaunched } from "../../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

const Row = ({ row, changesAllowed }) => {
  const [open, setOpen] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { updateContractsLoading, getAllContractsByUserLoading } = useSelector((state) => ({
    updateContractsLoading: state.getIn(['store', 'updateContractsLoading']),
    getAllContractsByUserLoading: state.getIn(['store', 'getAllContractsByUserLoading']),
  }));

  useEffect(() => {
    if (updateContractsLoading === false && getAllContractsByUserLoading === false) {
      setUpdateId(null)
    }
  }, [getAllContractsByUserLoading]);

  const today = new Date();
  const nextYear = () => {
    let response = today
    let year = today.getFullYear()
    response.setFullYear(year + 1)
    return response.toISOString()
  }

  const contractInitialValues = {
    contract: {
      startDate: today.toISOString(),
      endDate: nextYear()
    }
  };

  const contractValidationSchema = Yup.object().shape({
    contract: Yup.object().shape({
      startDate: Yup.string().nullable().required("Date obligatoire"),
      endDate: Yup.string().nullable().required("Date obligatoire"),
    }),
  });

  // Verifie si tous les contrats ont leur date de fin dépassée
  const hasValidContract = row?.contracts?.some(({ endDate }) =>
    endDate !== undefined && new Date(endDate).getTime() > new Date().getTime()
  )

  const updateContract = (id, updatedValues) => {
    const start = updatedValues?.contract?.startDate;
    const end = updatedValues?.contract?.endDate;
    dispatch(updateContractsLaunched({ contractId: id, startDate: start, endDate: end, paymentDone: true }))
  }

  const concatAddress = (...args) => {
    let address = "";
    args.forEach((arg) => {
      if (arg !== undefined && arg !== null) {
        address += arg + " ";
      }
    });
    return address.trim();
  }

  return (
    <>
      <TableRow className={clsx(classes.root, { [classes.hasNotValidContract]: !hasValidContract && !changesAllowed })}>
        <TableCell component="th" scope="row">
          {row.email}
        </TableCell>
        <TableCell align="center">{row.firstName}</TableCell>
        <TableCell align="center">{row.lastName}</TableCell>
        <TableCell align="center">{row.adherentNumber}</TableCell>
        <TableCell align="center">{row.phone}</TableCell>
        <TableCell align="center">{concatAddress(row.address, row.zipCode, row.city)}</TableCell>
        <TableCell align="right" className={classes.tableHead}>
          <Button variant="outlined" color="secondary" onClick={() => history.push(`/listQuotation/${row?.id}`)} className={classes.button}>
            Afficher les cotations
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setOpen(!open)} className={classes.button}
            endIcon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}>
            {open ? "Cacher les contrats" : "Afficher les contrats"}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.noPadding} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell classes={{ head: classes.bold, root: classes.field }}>Méthode de paiement</TableCell>
                    <TableCell classes={{ head: classes.bold, root: classes.field }}>Début</TableCell>
                    <TableCell classes={{ head: classes.bold, root: classes.field }}>Fin</TableCell>
                    {
                      changesAllowed ?
                        <TableCell classes={{ head: classes.bold, root: classes.action }}>Action</TableCell>
                        :
                        <TableCell />
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.contracts?.map((act, key) => (
                    <React.Fragment key={key}>
                      <TableRow>
                        <TableCell
                          className={classes.smallColumn}>{act.paymentMethod}</TableCell>
                        {
                          changesAllowed && !act.startDate ?
                            <Formik
                              initialValues={contractInitialValues}
                              validationSchema={contractValidationSchema}
                              enableReinitialize
                              onSubmit={(values) => {
                                setUpdateId(act.id)
                                updateContract(act.id, values)
                              }}
                            >
                              {
                                (props) =>
                                  <>
                                    <TableCell>
                                      <DatePicker
                                        name="contract.startDate"
                                        values={props?.values}
                                        handleChange={props.handleChange}
                                        errors={props.errors?.startDate}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <DatePicker
                                        name="contract.endDate"
                                        values={props?.values}
                                        handleChange={props.handleChange}
                                        errors={props.errors?.endDate}
                                      />
                                    </TableCell>
                                    <TableCell className={{ width: '70%' }}>
                                      <Grid container direction="row" >
                                        <Button variant="outlined" className={classes.contractButton}
                                          color="secondary"
                                          type="submit"
                                          onClick={props.handleSubmit}
                                        >
                                          {(updateContractsLoading || getAllContractsByUserLoading) && updateId === act.id ? <CircularProgress color="primary" size={28} /> : "Activer le contrat"}
                                        </Button>
                                      </Grid>
                                    </TableCell>
                                  </>
                              }
                            </Formik>
                            :
                            <>
                              <TableCell className={classes.smallColumn}>{formatDateToFrenchFormat(act.startDate?.substring(0, 10))}</TableCell>
                              <TableCell className={classes.smallColumn}>{formatDateToFrenchFormat(act.endDate?.substring(0, 10))}</TableCell>
                              <TableCell />
                            </>
                        }
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Row;