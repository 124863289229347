import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core';

export default makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    marginBottom: 30,
    color: theme.palette.primary.main
  },
  formContainer: {
    [theme.breakpoints.up('md')]: {
      minWidth: 550,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0,
      margin: 0
    }
  },
  sameContainer: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: 40,
      marginBottom: 40,
      minHeight: 500
    },
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    }
  },
  frameContainer: {
    minHeight: 500,
    padding: 30,
    marginTop: 40,
    marginBottom: 40,
    border: '1px solid black'
  },
  cityInput: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 10
    }
  },
  mainContainer: {
    height: 90,
    width: '100%'
  },
  cardContainer: {
    paddingTop: 23,
    paddingLeft: 13,
    height: 57.38,
    border: ({ focus }) => focus ? `1px solid ${theme.palette.secondary.main}` : '1px solid #e2e2e1',
    boxShadow: ({ focus }) => focus ? `${fade(theme.palette.secondary.main, 0.25)} 0 0 0 2px` : 'none',
    overflow: 'hidden',
    borderRadius: 5,
    color: 'red',
    backgroundColor: ({ focus }) => focus ? '#fff' : '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      border: '1px solid #0E5C45',
    },
  },
  typoIban: {
    position: 'absolute',
    color: ({ focus, error }) => focus || error ? theme.palette.secondary.main : '#707070',
    fontSize: 14,
    marginTop: -19,
    transition: theme.transitions.create(['font-size', 'margin-top'])
  },
  typo: {
    position: 'absolute',
    color: ({ focus, error }) => focus || error ? theme.palette.secondary.main : '#707070',
    fontSize: ({ focus, empty }) => focus || !empty ? 14 : 18,
    marginTop: ({ focus, empty }) => focus || !empty ? -19 : -10,
    transition: theme.transitions.create(['font-size', 'margin-top'])
  },
  rootError: {
    '&$error': {
      color: theme.palette.secondary.main
    }
  },
  error: {
  },
  icon: {
    marginRight: 5
  },
  typoPrice: {
    fontWeight: 'bold',
    fontSize: 45,
    fontFamily: 'Roboto'
  },
  buttonContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      width: 450,
      justifyContent: 'space-between'
    },
    display: 'flex',
    width: 300,
    justifyContent: 'center'
  },
  helperText: {
    display: 'flex',
    alignItems: 'center'
  }
}));
