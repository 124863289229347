import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TitleBanner from '../../components/TitleBanner';
import CustomCarousel from '../../components/Carousel';
import homePicture from '../../assets/image/homePage.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faBriefcase, faFileAlt, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { Divider, Grid, Typography } from '@material-ui/core';
import styles from './style';
import clsx from 'clsx';
import { getAllContentsLaunched } from "../../store/reducer";

export const HomePage = (props) => {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, contentsList, getAllContentsLoading } = useSelector((state) => ({
    user: state.getIn(['app', 'userDynamo', 'user']),
    contentsList: state.getIn(['store', 'contentsList']),
    getAllContentsLoading: state.getIn(['store', 'getAllContentsLoading']),
  }));

  useEffect(() => {
    dispatch(getAllContentsLaunched({ contentType: 'PREMIUM_ARTICLE' }));
  }, []);

  const renderFirstRow = () =>
    <>
      <Grid item container direction="row" alignContent="center" justify="center" alignItems="center"
        className={clsx(classes.container, classes.topLeft)} onClick={() => history.push('/cotation')}>
        <Grid item container direction="column" justify="center" alignItems="flex-end" className={classes.iconContainer}>
          <FontAwesomeIcon icon={faCalculator} className={classes.icon} />
        </Grid>
        <Grid item container direction="column" className={classes.titleContainer}>
          <Typography variant="h2">
            Outil de cotation
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" className={classes.typo}>
            Un encodage facile des actes selon les règles de la NGAP, qui optimise la rémunération des prestations infirmières et qui sécurise l’IDEL en termes d’obligations administratives, et de qualité et sécurité des soins. Une disponibilité d’experts en NGAP via la boite mail contact pour les questions spécifiques de nomenclature.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" alignContent="center" justify="center" alignItems="center"
        className={clsx(classes.container, classes.topRight)} onClick={() => history.push('/formations')}>
        <Grid item container direction="column" justify="center" alignItems="flex-end" className={classes.iconContainer}>
          <FontAwesomeIcon icon={faBriefcase} className={classes.icon} />
        </Grid>
        <Grid item container direction="column" className={classes.titleContainerSessions}>
          <Typography variant="h2">
            Formations
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" className={classes.typo}>
            Des modules courts de formation sur des sujets variés dans les champs du métier et de l’exercice libéral sans se déplacer en direct par visioconférence pour profiter de partage de pratiques et poser vos questions en direct avec l’intervenant ou en replay à toute heure.
          </Typography>
        </Grid>
      </Grid>
    </>;

  const renderSecondRow = () =>
    <>
      <Grid item container direction="row" alignContent="center" justify="center" alignItems="center"
        className={clsx(classes.container, classes.bottomLeft)} onClick={() => history.push('/traceability')}>
        <Grid item container direction="column" justify="center" alignItems="flex-end" className={classes.iconContainer}>
          <FontAwesomeIcon icon={faFileAlt} className={classes.icon} />
        </Grid>
        <Grid item container direction="column" className={classes.titleContainerTraceability}>
          <Typography variant="h2">
            Qualité et sécurité des soins
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" className={classes.typoTraceability}>
            Une banque de données relatives aux domaines de la pratique infirmière offerte sous forme de fiches – outils prêtes à l’emploi ainsi que des documents de références et des résumés sur des thématiques métiers.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" alignContent="center" justify="center" alignItems="center"
        className={classes.container} onClick={() => history.push('/legal')}>
        <Grid item container direction="column" justify="center" alignItems="flex-end" className={classes.balanceContainer}>
          <FontAwesomeIcon icon={faBalanceScale} className={classes.icon} />
        </Grid>
        <Grid item container direction="column" className={classes.titleContainerLegal}>
          <Typography variant="h2">
            Juridique
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" className={classes.typo}>
            Une banque de données relatives au cadre juridique de l’exercice libéral de la profession offerte sous forme de fiches-mémo, d’exemples d’affiches et de fiches explicatives pour comprendre et faire face à des procédures contentieuses. Un accès direct par e-mail avec un cabinet d’avocats spécialisés pour les questions juridiques.          </Typography>
        </Grid>
      </Grid>
    </>;

  return (
    <Grid container alignContent="center" justify="center" alignItems="center">
      <TitleBanner title={`Bonjour ${user.firstName}`} color="secondary" />
      {
        (!getAllContentsLoading && contentsList && Array.isArray(contentsList)) &&
        <CustomCarousel articles={contentsList?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }).slice(0, 3).map((article) => {
          return {
            image: article?.fields?.image?.fields?.file?.url,
            title: article?.fields?.titre,
            description: article?.fields?.description,
            id: article?.id
          }
        })
        }
        />
      }
      {/*</Box>*/}
      <Grid item container direction="row" justify="center" alignItems="center" className={classes.mainContainer}>
        <img src={homePicture} alt="imageBackground" className={classes.imageBackground} />
        <Grid item container direction="column" justify="center" className={classes.servicesContainer} >
          <Typography variant="h2">
            Nos services
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" className={classes.typo}>
            Une offre de services innovants et digitalisés qui transforment la manière d’accompagner les IDEL à optimiser leur exercice.
          </Typography>
        </Grid>
        <Grid item container direction="column" className={classes.blockContainer}>
          <Grid item container direction="row" className={classes.rowContainer}>
            {renderFirstRow()}
          </Grid>
          <Grid item container direction="row" className={classes.rowContainer}>
            {renderSecondRow()}
          </Grid>
          {/*Mobile*/}
          <Grid item container direction="column" className={classes.mobileContainer}>
            {renderFirstRow()}
            {renderSecondRow()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomePage;
