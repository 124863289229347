import { fade, makeStyles } from '@material-ui/core/styles';
import { QUOTE_DRAWER_WIDTH } from '../../../utils/constants';

export default makeStyles((theme) => ({
  container: {
    width: 60,
    backgroundColor: ({hourSelected}) => hourSelected ? theme.palette.primary.main : theme.palette.neutral.main,
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: QUOTE_DRAWER_WIDTH,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  titleContainer: {
    height: 90
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 30
  },
  list: {
    maxHeight: '77vh',
    overflowY: 'scroll',
    paddingRight: -15,
    scrollbarColor: `${fade("#000", .3)} transparent`, // Firefox scrollbar
    scrollbarWidth: 'thin', // Firefox scrollbar
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      background: `transparent transparent`,
    },
    '&::-webkit-scrollbar-thumb': {
      background: fade("#000", .3),
      borderRadius: 16,
    },
    overflowX: 'hidden'
  },
  itemText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'clamp(.8rem, 1.5vw, 1.2rem)'
  },
  itemIcon: {
    color: 'white',
    justifyContent: 'center'
  },
  itemList: {
    padding: theme.spacing(2, .5),
    width: QUOTE_DRAWER_WIDTH,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, .5),
    }
  },
  backButtonContainer: {
    width: 60,
    [theme.breakpoints.up("md")]: {
      width: '100%'
    }
  },
  backButton: {
    fontSize: 40,
    color: 'white',
    [theme.breakpoints.up("md")]: {
      fontSize: 61
    }
  },
  chapter: {
    fontStyle: 'italic',
    color: fade('#000', .6)
  },
  titleHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));
