import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: 50,
    marginTop: 35
  },
  divider: {
    width: '80%',
    height: 1,
    backgroundColor: 'black',
  },
  typo: {
    color: theme.palette.neutral.light,
    margin: `${theme.spacing(3)}px 5vw`,
    textAlign: 'center'
  },
  navLink: {
    fontSize: 15,
    color: '#909090'
  }
}));
