import React from 'react';
import styles from './styles';
import PropTypes from 'prop-types';
import { Box, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';

import CustomNavLink from '../../NavLink';
import RichTextRenderer from '../../RichTextRenderer';
import { KNOW_MORE } from '../../../utils/constants';

export const SessionCard = ({ title, image, date, author, description, id }) => {
  const classes = styles();

  return (
    <Grid item container sm={10}>
      <Box m={3} className={classes.wrapper}>
        <Card className={classes.root} elevation={0}>
          <Grid container>
            <Grid item container md={6} lg={4}>
              <div className={classes.cardContainer}>
                <CardContent className={classes.cardTitle}>
                  {title}
                </CardContent>
                <Box my={2}>
                  <CardMedia
                    className={classes.media}
                    image={image}
                  />
                </Box>
              </div>
            </Grid>

            <Grid item container md={6} lg={8} className={classes.rightSection} direction="column" justify="space-between">
              <Box className={classes.rightContent}>
                <CardContent className={classes.generalInfos} >
                  <Typography variant="body1">
                    Formation le <span className={classes.bold}>{date}</span>
                  </Typography>
                  <Typography variant="body1">
                    Réalisée par <span className={classes.bold}>{author}</span>
                  </Typography>
                </CardContent>
                <CardContent>
                  <RichTextRenderer details={description} />
                </CardContent>
              </Box>

              <Box mb={1}>
                <CardActions className={classes.buttonPosition}>
                  <CustomNavLink
                    to={`/formations/${id}`}
                    text={KNOW_MORE}
                    asButton
                  />
                </CardActions>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Grid>
  );
}

export default SessionCard

SessionCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
};