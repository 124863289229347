import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles';

export const Title = ({ title, color, isSecondaryTitle }) => {
  const classes = styles({ isSecondaryTitle });

  return (
    <Grid
      item
      container
      alignContent="center"
      justify={isSecondaryTitle ? "flex-start" : "center"}
      className={clsx(classes.titleContainer, classes[color || "primary"])}
    >
      <Typography variant="h3" className={classes.title}>{title}</Typography>
    </Grid>
  );
};

export default Title;

Title.propTypes = {
  title: PropTypes.string.isRequired,
  /**
   * Définit la couleur de background
   */
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  isSecondaryTitle: PropTypes.bool
};


Title.defaultProps = {
  isSecondaryTitle: false
};