import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { CustomTextField } from '../../Inputs/CustomTextField';
import styles from '../styles';
import CustomNumberField from '../../Inputs/CustomNumberField';
import { Box } from '@material-ui/core';

const BillingForm = ({ props }) => {
  const classes = styles();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
  } = props;

  const { name, address, city, zipCode } = values;

  return (
    <Grid
      item
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.formContainer}
      xs={12}
      sm={6}
    >
      <Typography variant="h3" className={classes.title}>ADRESSE DE FACTURATION</Typography>
      <Box>
        <CustomTextField
          name="name"
          label="Nom"
          value={name}
          onBlur={handleBlur}
          onChange={handleChange}
          error={!!touched.name && !!errors.name}
          helperText={touched.name && errors.name ? errors.name : ''}
          fullWidth
        />
        <CustomTextField
          name="address"
          label="Adresse"
          value={address}
          onBlur={handleBlur}
          onChange={handleChange}
          error={!!touched.address && !!errors.address}
          helperText={touched.address && errors.address ? errors.address : ''}
          fullWidth
        />
        <Grid
          item
          container
        >
          <Grid item xs="12" md="6" className={classes.cityInput}>
            <CustomTextField
              name="city"
              label="Ville"
              value={city}
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!touched.city && !!errors.city}
              helperText={touched.city && errors.city ? errors.city : ''}
              fullWidth
            />
          </Grid>
          <Grid item xs="12" md="6">
            <CustomNumberField
              name="zipCode"
              label="Code postal"
              value={zipCode}
              onBlur={handleBlur}
              error={!!touched.zipCode && !!errors.zipCode}
              helperText={touched.zipCode && errors.zipCode ? errors.zipCode : ''}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <CustomTextField
          name="amount"
          label="Montant de l'adhésion"
          value="140€"
          disabled
        />
      </Box>
    </Grid>
  );
};

export default BillingForm;
