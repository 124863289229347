import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { HomePage } from '../../pages/HomePage';
import PrivateRoute from '../PrivateRoute';
import PublicRoute from '../PublicRoute';
import { closeSnackBar, getCurrentSessionLaunched } from './reducer';
import SignInPage from '../../pages/SignIn';
import SignUpPage from '../../pages/SignUp';
import ForgotPassword from '../../pages/ForgotPassword';
import { ContactPage } from '../../pages/ContactPage';
import { NewsPage } from '../../pages/NewsPage';
import { NewPage } from '../../pages/NewPage';
import { LegalPage } from '../../pages/LegalPage';
import { LegalFramePage } from '../../pages/LegalFramePage';
import { TraceabilitiesPage } from '../../pages/TraceabilitiesPage';
import { LitigationPage } from '../../pages/LitigationPage';
import { FormationsPage } from '../../pages/FormationsPage';
import CotationPage from '../../pages/CotationPage';
import CotationSummary from '../../pages/CotationSummary';
import MyAccount from '../../pages/MyAccount';
import { SearchResultsPage } from '../../pages/SearchResultsPage';
import { CustomAppBar } from '../AppBar';
// import { CustomSnackBar } from '../SnackBar';
import Footer from '../Footer';
import PaymentPage from '../../pages/PaymentPage';
import { loadStripe } from '@stripe/stripe-js';
import CustomAlert from '../Modale';
import Loader from '../Loader';
import styles from './styles';
import AdminPage from '../../pages/Admin'
import ListQuotationPage from "../../pages/ListQuotationPage";
import { LegalMentionsPage } from "../../pages/LegalMentionsPage";
import ScrollToTop from "../ScrollToTop";

// eslint-disable-next-line no-undef
const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  const classes = styles();
  const dispatch = useDispatch();
  const {
    isAuthenticating,
    isAuthenticated,
    popupOpen,
    popupMessage,
    popupError,
    popupId,
    userDynamo
    // snackBarOpen,
    // snackBarMessage,
    // snackBarType
  } = useSelector((state) => (
    {
      isAuthenticating: state.getIn(['app', 'isAuthenticating']),
      isAuthenticated: state.getIn(['app', 'isAuthenticated']),
      popupOpen: state.getIn(['app', 'popupOpen']),
      popupMessage: state.getIn(['app', 'popupMessage']),
      popupError: state.getIn(['app', 'popupError']),
      popupId: state.getIn(['app', 'popupId']),
      userDynamo: state.getIn(['app', 'userDynamo']),
      // snackBarOpen: state.getIn(['app', 'snackBarOpen']),
      // snackBarMessage: state.getIn(['app', 'snackBarMessage']),
      // snackBarType: state.getIn(['app', 'snackBarType']),
    }
  ));
  const { pathname, search } = useLocation()
  const contractEndDate = new Date(userDynamo?.contract?.endDate).getTime();
  const userContractEnded = contractEndDate < new Date().getTime() ? true : false;

  useEffect(() => {
    dispatch(getCurrentSessionLaunched({ fromPath: pathname + search || '/' }));
  }, [dispatch]);

  const appSwitch = (
    <ScrollToTop>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <PublicRoute exact path="/login" fixed component={SignInPage} />
        <PublicRoute exact path="/signup" fixed component={SignUpPage} />
        <PrivateRoute exact path="/cotation" component={CotationPage} />
        <PrivateRoute exact path="/cotation-synthese/:id" component={CotationSummary} />
        <PrivateRoute exact path="/payment" fixed component={PaymentPage} />
        <PrivateRoute exact path="/traceability" fixed component={TraceabilitiesPage} />
        <PrivateRoute exact path="/contact" fixed component={ContactPage} />
        <PrivateRoute exact path="/legal" fixed component={LegalPage} />
        <PrivateRoute exact path="/legalFrame" fixed component={LegalFramePage} />
        <PrivateRoute exact path="/legalMentions" fixed component={LegalMentionsPage} />
        <PrivateRoute exact path="/litigation" fixed component={LitigationPage} />
        <PrivateRoute exact path="/news" fixed component={NewsPage} />
        <PrivateRoute exact path="/news/:id" fixed component={NewPage} />
        <PrivateRoute exact path="/formations" fixed component={FormationsPage} />
        <PrivateRoute exact path="/formations/:id" fixed component={NewPage} />
        <PublicRoute exact path="/password" fixed component={ForgotPassword} />
        {/*<PrivateRoute exact path="/firstlogin" fixed component={FirstLoginPage} />*/}
        <PrivateRoute exact path="/home" fixed component={HomePage} />
        <PrivateRoute exact path="/profil" fixed component={MyAccount} />
        <PrivateRoute exact path="/admin" fixed component={AdminPage} />
        <PrivateRoute exact path="/listQuotation/:id" fixed component={ListQuotationPage} />
        <PrivateRoute exact path="/resultats-recherche" component={SearchResultsPage} />
        <Route path="*" component={() => <Redirect to="/home" />} />
      </Switch>
    </ScrollToTop>
  );

  return (
    <div className={classes.root}>
      {
        (!pathname.includes('cotation') || (pathname.includes('cotation') && userContractEnded)) &&
        <CustomAppBar isAuthenticated={isAuthenticated} />
      }
      {
        isAuthenticating ? (
          <Grid container alignItems="center" className={classes.appWrapper}>
            <Loader />
          </Grid>
        ) : appSwitch
      }
      {
        !pathname.includes("/cotation") &&
        <Footer />
      }
      {popupOpen && <CustomAlert withError={popupError} id={popupId} message={popupMessage} />}
      {/* <CustomSnackBar
        message={snackBarMessage}
        open={snackBarOpen}
        type={snackBarType}
        close={() => dispatch(closeSnackBar())}
      /> */}
    </div>
  );
}

export default App;
