import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';

import ChangePasswordForm from '../../Forms/ChangePasswordForm';
import Title from '../../../components/Title';
import { changePasswordLaunched } from '../../App/reducer';

const ChangePasswordModale = () => {
  const dispatch = useDispatch();
  // Form data
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  };

  const PASSWORD_LENGTH_MESSAGE = "8 caractères minimum";
  const MANDATORY_FIELD = "Ce champs est obligatoire";
  // Form Validation Schema
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, PASSWORD_LENGTH_MESSAGE).required(MANDATORY_FIELD),
    newPassword: Yup.string()
      .min(8, PASSWORD_LENGTH_MESSAGE).required(MANDATORY_FIELD),
    confirmNewPassword: Yup.string().test('newPassword-match', "Les mots de passe ne correspondent pas", function (confNewPass) {
      return confNewPass === this.parent.newPassword;
    }).required(MANDATORY_FIELD)
  });

  const passwordSubmit = (credentials, { resetForm }) => {
    dispatch(changePasswordLaunched(credentials));
    resetForm();
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
    >
      <Title title="Changement de mot de passe" color="secondary" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={passwordSubmit}
      >
        {(props) => <ChangePasswordForm {...props} />}
      </Formik>
    </Grid>
  );
};
export default ChangePasswordModale;
