import React from 'react'
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './styles';

const Icon = ({ icon, variant, size, link, download, disabled }) => {
  const classes = styles({ disabled });

  return link
    ? <a href={link} target="_blank" rel="noreferrer" download={download}>
      <FontAwesomeIcon icon={icon} size={size} className={classes[variant]} />
    </a>
    : <FontAwesomeIcon icon={icon} size={size} className={classes[variant]} />
}

export default Icon

Icon.propTypes = {
  icon: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['main', 'secondary', 'black', 'white']),
  size: PropTypes.string,
  link: PropTypes.string,
  download: PropTypes.bool,
  disabled: PropTypes.bool
};

Icon.defaultProps = {
  variant: 'main',
  size: '3x',
  disabled: false
};
