import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: 100,
  },
  loaderWrapper: {
    minHeight: '80vh',
  },
  actsList: {
    '@media (min-width:700px)': {
      width: "210%"
    }
  },
  actLabel: {
    fontWeight: "600"
  },
  instructionsBox: {
    paddingLeft: "10px",
  }
}));