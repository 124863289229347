import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  icon: {
    color: "white",
    fontSize: 30,
    [theme.breakpoints.up("sm")]: {
      fontSize: 40,
    }
  }
}));