import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { Box, Divider, Hidden } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/image/logo.png';
import styles from './styles';
import { CustomSwipeableDrawer } from '../SwipeableDrawer';
import { logoutLaunched } from '../App/reducer';
import Searchbar from '../Searchbar';
import { addProtocol } from '../../utils/format';

export const CustomAppBar = ({ isAuthenticated }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, contract, email } = useSelector((state) => ({
    user: state.getIn(['app', 'userDynamo', 'user']),
    email: state.getIn(['app', 'userData', 'email']),
    contract: state.getIn(['app', 'userDynamo', 'contract']),
  }));
  const classes = styles({ pathname: location.pathname, paymentDone: contract !== undefined ? contract.paymentDone : true });

  const [anchorElBurger, setAnchorElBurger] = React.useState(null);
  const isMenuOpen = Boolean(anchorElBurger);

  const handleProfileMenuOpen = (event) => {
    if (history.location.pathname !== "/payment") {
      setAnchorElBurger(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorElBurger(null);
  };

  const handleRedirection = (page) => {
    history.push(page);
    handleMenuClose();
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorElBurger}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuItem onClick={() => handleRedirection('/profil')}>Mon profil</MenuItem>
      {user?.isAdmin && <MenuItem onClick={() => handleRedirection('/admin')}>Admin</MenuItem>}
    </Menu>
  );

  const renderProfileMenu = () => {
    let profileMenu = [
      {
        title: 'Actualités',
        to: '/news'
      },
      {
        title: 'Outil de cotation',
        to: '/cotation'
      },
      {
        title: 'Qualité et sécurité des soins',
        to: '/traceability'
      },
      {
        title: 'Juridique',
        to: '/legal'
      },
      {
        title: 'Formations',
        to: '/formations'
      },
      {
        title: 'Mon profil',
        to: '/profil'
      }
    ]
    if (user?.isAdmin) {
      profileMenu.push({
        title: 'Admin',
        to: '/admin'
      })
    }

    if (location.pathname === '/payment' || ((location.pathname === '/profil') && (contract?.paymentDone === false))) {
      profileMenu = [
        {
          title: 'Mon profil',
          to: '/profil'
        }
      ];
    }
    return profileMenu;
  }

  let mobileMenu = [];
  if (isAuthenticated) {
    mobileMenu = [
      {
        title: 'Site Public',
        to: `${addProtocol(process.env.REACT_APP_URL_PUBLIC_SITE)}`,
        target: 'target="_blank"'
      },
    ];
  }
  if (isAuthenticated && (user?.isAdmin || (contract?.paymentDone !== false && contract?.paymentDone !== undefined))) {
    mobileMenu = [
      {
        title: 'Site Public',
        to: `${addProtocol(process.env.REACT_APP_URL_PUBLIC_SITE)}`,
        target: 'target="_blank"'
      },
      {
        title: 'Actualités',
        to: '/news'
      },
      {
        title: 'Outil de cotation',
        to: '/cotation'
      },
      {
        title: 'Qualité et sécurité des soins',
        to: '/traceability'
      },
      {
        title: 'Juridique',
        to: '/legal'
      },
      {
        title: 'Formations',
        to: '/formation'
      },
    ];
  }
  if ((location.pathname) !== '/payment') {
    mobileMenu = [
      {
        title: 'Site Public',
        to: `${addProtocol(process.env.REACT_APP_URL_PUBLIC_SITE)}`,
        target: 'target="_blank"'
      },
    ];
  }

  return (
    <div className={classes.grow}>
      <AppBar position="static" classes={{ root: clsx(classes.root, { [classes.notAuthenticated]: !isAuthenticated }) }}>
        <Hidden smDown>
          <Grid container justify="space-between" className={classes.headerWrapper}>
            <Grid className={classes.flexContainer}>
              <Box onClick={() => history.push('/home')} className={classes.logoWrapper}>
                <img alt="logo" src={logo} className={classes.img} />
              </Box>
              <Divider orientation="vertical" classes={{ root: classes.divider }} />
              <Typography className={classes.title}>
                SAIIL, le syndicat autonome des IDEL
              </Typography>
            </Grid>
            {
              isAuthenticated && (
                <Grid className={classes.flexContainer}>
                  <Grid item container direction="column">
                    <NavLink to={{ pathname: process.env.REACT_APP_URL_PUBLIC_SITE }} target="_blank" className={clsx(classes.navLink, classes.green)}>Retour sur le site public</NavLink>
                    <button onClick={() => dispatch(logoutLaunched())} className={clsx(classes.navLink, classes.orange)}>Déconnexion</button>
                    <Typography variant="subtitle1" className={classes.mailDown}>{email || user?.email}</Typography>
                    <Typography variant="subtitle1" className={classes.mailUp}>
                      {email || user?.email}
                    </Typography>
                  </Grid>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="secondary"
                    className={classes.iconButton}
                  >
                    <AccountCircle className={classes.icon} />
                  </IconButton>
                </Grid>
              )
            }
          </Grid>
        </Hidden>
        <Grid item container direction="row" className={classes.firstContainerMobile} justify="center">
          <div onClick={() => history.push('/')} className={classes.logoWrapper}>
            <img alt="logo" src={logo} className={classes.img} />
          </div>
          <div className={classes.searchBarMobile}>
            {
              (location.pathname !== "/payment" &&
                location.pathname !== "/login" &&
                location.pathname !== "/password" &&
                location.pathname !== "/signup") && (
                <Searchbar />
              )
            }
          </div>
          <div>
            {
              isAuthenticated && (
                <CustomSwipeableDrawer
                  menu={mobileMenu}
                  profile={renderProfileMenu()}
                />
              )
            }
          </div>
        </Grid>
        {
          isAuthenticated && (user?.isAdmin || (contract?.paymentDone !== false && contract?.paymentDone !== undefined)) && (
            <Grid item container direction="row" alignItems="center" justify="center" className={classes.secondContainer}>
              <HomeIcon fontSize="large" color="secondary" onClick={() => history.push('/')} className={classes.homeIcon} />
              <Grid item className={classes.menu}>
                <Divider orientation="vertical" />
                <Button className={classes.button} classes={{ label: classes.buttonText }} onClick={() => history.push("/news")}>Actualités</Button>
                <Divider orientation="vertical" />
                <Button className={classes.button} classes={{ label: classes.buttonText }} onClick={() => history.push("/cotation")}>Outil de cotation</Button>
                <Divider orientation="vertical" />
                <Button className={classes.button} classes={{ label: classes.buttonText }} onClick={() => history.push("/traceability")}>Qualité et sécurité des soins</Button>
                <Divider orientation="vertical" />
                <Button className={classes.button} classes={{ label: classes.buttonText }} onClick={() => history.push("/legal")}>Juridique</Button>
                <Divider orientation="vertical" />
                <Button className={classes.button} classes={{ label: classes.buttonText }} onClick={() => history.push("/formations")}>Formations</Button>
                <Divider orientation="vertical" />
                <Box className={classes.search}><Searchbar /></Box>
              </Grid>
            </Grid>
          )
        }
      </AppBar>
      {renderMenu}
    </div >
  );
};
export default CustomAppBar;