import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

import styles from './styles';

export const InvoiceDownload = ({ title, file }) => {
  const classes = styles();
  return (
    <Grid>
      <a href={file} download className={classes.link}>
        <Grid container className={classes.container}>
          <FontAwesomeIcon icon={faFileDownload} size="2x" className={classes.icon} color="secondary" />
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
      </a>
    </Grid>
  )
};

export default InvoiceDownload;

InvoiceDownload.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired
};