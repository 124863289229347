import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import CotationDrawerContent from '../../components/Quotes/CotationDrawerContent';
import ContextForm from '../../components/Forms/QuotesForms/ContextForm';
import QuotesForm from '../../components/Forms/QuotesForms/QuotesForm';
import QuotesHeader from '../../components/Quotes/QuotesHeader';
import { getMedicActsLaunched, postQuotesLaunched, setQuotePage, getQuotesLaunched } from '../../store/reducer';
import styles from './style';
import { filterMedicActsData, switchHourInStringType } from '../../utils/services/formats';
import Typography from "@material-ui/core/Typography";
import { Box, Dialog, IconButton, Slide } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CotationPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const startId = searchParams.get('startId');

  const [mobileOpen, setMobileOpen] = useState(false);
  const [patient, setPatient] = useState(null);
  const [listOpen, setListOpen] = useState(false);
  const [actsByCategory, setActsByCategory] = useState(null);
  const [filteredActs, setFilteredActs] = useState([]);
  const [validationOpen, setValidationOpen] = useState(false);

  useEffect(() => {
    if (startId) {
      dispatch(setQuotePage(1));
      dispatch(getQuotesLaunched({ quoteId: startId }));
    }
  }, [startId]);

  const { user, medicActsData, quotePage, currentQuoteData, selectedHourIndex, cotationId, cotationData } = useSelector((state) => ({
    user: state.getIn(['app', 'userDynamo', 'user']),
    medicActsData: state.getIn(['store', 'medicActsData']),
    quotePage: state.getIn(['store', 'quotePage']),
    currentQuoteData: state.getIn(['store', 'currentQuoteData']),
    selectedHourIndex: state.getIn(['store', 'selectedHourIndex']),
    cotationId: state.getIn(['store', 'cotationId']),
    openPopup: state.getIn(['app', 'openPopup']),
    cotationData: state.getIn(['store', 'cotationData']),
  }));

  const classes = styles({ quotePage });
  useEffect(() => {
    if (!startId && (id || cotationId)) {
      history.push(`/cotation-synthese/${id || cotationId}`)
    }
  }, [id, cotationId])

  const contextInitialValues = {
    context: {
      // date: patient?.context?.date || new Date(Date.now()),
      day: 'WEEKDAY', //  patient?.context?.day || 'WEEKDAY' // Always send the option without majoration per client wish of 2021/06/24
      age: patient?.context?.age || cotationData?.context?.age || '',
      location: patient?.context?.location || cotationData?.context?.location || '',
      sex: patient?.context?.sex || cotationData?.context?.sex || '',
      patientType: patient?.context?.patientType || cotationData?.context?.patientType || [],
    }
  };

  const contextValidationSchema = Yup.object().shape({
    context: Yup.object().shape({
      // date: Yup.date("Merci de renseigner une date valide").required("La date est obligatoire"),
      day: Yup.string(),
      age: Yup.number().typeError("L'âge du patient est obligatoire")
        .min(0, "L'âge du patient doit être supérieur à 0")
        .max(140, 'Merci de renseigner un âge valide').required("L'âge du patient est obligatoire"),
      location: Yup.string().required("Le lieu est obligatoire"),
      sex: Yup.string().required("Le genre du patient est obligatoire"),
      patientType: Yup.array("Merci de cocher au moins 1 pathologie").of(Yup.string("Merci de cocher au moins 1 pathologie")).min(1, "Merci de cocher au moins 1 pathologie").required("Merci de cocher au moins 1 pathologie"),
    }),
  });

  const quotesInitialValues = {
    medicActs: currentQuoteData?.medicActs || cotationData?.medicActs
  };

  const quotesValidationSchema = Yup.object().shape({
    medicActs: Yup.array().of(Yup.object().shape({
      hourInString: Yup.string().required("Merci de renseigner un horaire"),
      acts: Yup.array().of(Yup.string())
        .test("acts", "Au moins 1 acte par horaire", function (value) {
          let f2 = this.resolve(Yup.ref("packageSession"));
          if (value?.length > 0) {
            return true
          } else {
            if (!f2 || f2?.length < 1) {
              return false
            }
            return true
          }
        })
        .test("acts", "Merci de sélectionner l'acte réalisé au cours de cette téléconsultation", function (value) {
          let f3 = this.resolve(Yup.ref("packageSession"));
          if (value?.length === 1 && value.includes("TLS") && f3?.length === 0) {
            return false
          } else if (value?.length === 1 && !value.includes("TLS")) {
            return true
          } else {
            return true
          }
        }),
      packageSession: Yup.array().of(Yup.string())
        .test("packageSession", "Au moins 1 package par horaire", function (value) {
          let f1 = this.resolve(Yup.ref("acts"));
          if (value?.length > 0) {
            return true
          } else {
            if (!f1 || f1?.length < 1) {
              return false
            }
            return true;
          }
        }),
      hours: Yup.string(),
    })).min(1, "Merci de sélectionner un horaire")
      .test("medicActs", "Merci de saisir des horaires différents", function (value) {
        let f3 = this.resolve(Yup.ref("medicActs"));
        let selectedTimeslots = f3?.map(act => act.hourInString)
        if (f3?.length > 1) {
          if (selectedTimeslots.length === Array.from(new Set(selectedTimeslots)).length) {
            return true
          } else {
            return false
          }
        }
        return true
      })
      .test("medicActs", "Merci de sélectionner un pansement lourd et complexe à la même heure ou une heure postérieure à la pose de l'analgésie topique", function (value) {
        let f4 = this.resolve(Yup.ref("medicActs"));
        let selectedActs = f4 ? f4.map(act => act.acts ? act.acts : []) : [];
        for (let acts of selectedActs) {
          if (acts?.includes("c02a05_06") || acts.includes("c01a03_12")) {
            const pansements = ["c01a03_01", "c01a03_02", "c01a03_03", "c01a03_04", "c01a03_05", "c01a03_06", "c01a03_07", "c01a03_08", "c01a03_09", "c01a03_10", "c01a03_11", "c02a05_05", "c02a05_08"];
            let valueIndex
            for (let selectedIndex in selectedActs) {
              if (acts.length === selectedActs[selectedIndex].length && acts.every((val, index) => val === selectedActs[selectedIndex][index])) {
                valueIndex = selectedIndex
                break
              }
            }
            if (selectedActs?.slice(valueIndex).reduce((a, b) => a.concat(b)).some(act => pansements.indexOf(act) !== -1)) {
              return true
            } else {
              return false
            }
          }
        }
        return true
      })
      .test("medicActs", "Merci d'encoder 4 séances maximum en cas d'actes effectués dans le cadre des soins liés à la dépendance sur un patient éligible au BSI", function (value) {
        let f5 = this.resolve(Yup.ref("medicActs"));
        let selectedPackage = !f5 ? [] : f5[0] ? f5.map(packages => packages.packageSession ? packages.packageSession : []).reduce((a, b) => a.concat(b)) : [];
        if (['BSA', 'BSB', 'BSC'].some(bsiPackage => selectedPackage.indexOf(bsiPackage) !== -1)) {
          if (f5?.length >= 5) {
            return false
          } else {
            return true
          }
        }
        return true
      })
  });

  useEffect(() => {
    dispatch(getMedicActsLaunched());
  }, []);

  const contextSubmit = (credentials) => {
    setPatient(credentials);
    dispatch(setQuotePage(2));
  };

  const openCategories = (list) => {
    setActsByCategory(list);
    setListOpen(true);
  }

  useEffect(() => {
    if (selectedHourIndex < 0) {
      setListOpen(false)
    }
  }, [selectedHourIndex]);

  useEffect(() => {
    setFilteredActs(filterMedicActsData({ medicActsData, patientContext: patient?.context }))
  }, [patient, medicActsData])

  const quoteSubmit = (acts) => {
    setValidationOpen(!validationOpen)
    const data = acts?.medicActs?.map(act => {
      let formattedAct = {
        hours: switchHourInStringType(act.hourInString),
        hourInString: act.hourInString.trim(),
      }
      if (act.acts?.length > 0) {
        formattedAct.acts = act.acts;
      }
      if (act.packageSession?.length > 0) {
        formattedAct.package = act.packageSession
      }
      return formattedAct;
    })

    const sortedData = data?.sort((a, b) => Number(a.hourInString.replace(':', '.')) - Number(b.hourInString.replace(':', '.')))
    const formattedData = {
      userId: user?.id,
      medicActs: sortedData,
      context: patient?.context,
    }
    dispatch(postQuotesLaunched(formattedData));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <QuotesHeader title={`BIENVENUE SUR L'OUTIL DE COTATION, ${user && user.firstName.toUpperCase()} !`} />

      {quotePage === 1 ?
        <Grid item container justify="center" alignItems="center" alignContent="center">
          <Formik
            initialValues={contextInitialValues}
            validationSchema={contextValidationSchema}
            enableReinitialize={true}
            onSubmit={contextSubmit}
          >
            {(props) => <ContextForm  {...props} />}
          </Formik>
        </Grid>
        :
        <>
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
              elevation={1}
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <CotationDrawerContent
                list={filteredActs}
                hourSelected={selectedHourIndex >= 0}
                openCategoryList={(list) => openCategories(list)}
                setListOpen={setListOpen}
              />
            </Drawer>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                {/* <SearchIcon /> */}
              </div>
            </div>

            <Dialog
              open={validationOpen}
              TransitionComponent={Transition}
              keepMounted
              aria-labelledby={id}
              aria-describedby={id}
              classes={{ paper: classes.rootDialog }}
              disableBackdropClick={true}
            >
              <Grid container justify="center">
                <Box m={4}>
                  <Typography variant="h5" className={classes.popupMessage}>Êtes-vous sûr de vouloir valider votre journée de cotations pour ce patient ?</Typography>
                </Box>
                <Grid container justify="space-evenly" alignContent="space-around">
                  <IconButton onClick={() => setValidationOpen(!validationOpen)} classes={{ root: classes.buttonRoot }}>
                    <CancelIcon color="error" className={classes.cancelIcon} />
                  </IconButton>
                  <IconButton onClick={() => quoteSubmit(quotesInitialValues)} classes={{ root: classes.buttonRoot }}>
                    <CheckCircleIcon className={classes.checkIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Dialog>
            <Formik
              initialValues={quotesInitialValues}
              validationSchema={quotesValidationSchema}
              enableReinitialize={true}
              onSubmit={() => {
                setValidationOpen(!validationOpen)
              }}
            >
              {(props) => <QuotesForm
                listOpen={listOpen}
                actsByCategory={actsByCategory}
                editable
                filteredActs={filteredActs}
                {...props}
              />}
            </Formik>
          </main>
        </>
      }
    </div>
  );
};

export default CotationPage;
