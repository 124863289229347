import React, { useState } from "react";
import styles from '../../styles';
import { CardNumberElement } from "@stripe/react-stripe-js";
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import ErrorIcon from '@material-ui/icons/Error';

const CardNumberField = (props) => {
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState(null);
  const [empty, setEmpty] = useState(true);
  const classes = styles({ focus, empty, error });

  const cardStyle = {
    placeholder: '',
    style: {
      base: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'Roboto, Open Sans',
        fontSize: "18px",
        "::placeholder": {
            color: focus ? "#707070" : "#fcfcfb"
        },
        ":hover": {
          "::placeholder": {
            color: "#fff"
          },
        }
      },
      complete: {
        color: "black",
        iconColor: "black"
      },
      invalid: {
        color: "black",
        iconColor: "black"
      },

    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.cardContainer}>
        <Typography className={classes.typo}>Numéro de carte</Typography>
         <CardNumberElement
           onBlur={() => {
             if (empty) {
               setError("Votre numéro de carte est incomplet.")
               setFocus(false)
               props.setDisabled(true)
             } else {
               setFocus(false)
               props.setDisabled(false)
             }
           }}
           onFocus={() => setFocus(true)}
           onChange={(event) => {
             if (event.empty) {
               setEmpty(true)
               setError("Votre numéro de carte est incomplet.")
               props.setDisabled(true)
             } else if (event.error) {
               setError(event.error.message)
               props.setDisabled(true)
             } else {
               setError(null)
               setEmpty(false)
               props.setDisabled(false)
             }
           }}
           options={cardStyle}
         />
      </div>
      <FormHelperText error={error} classes={{ root: classes.rootError, error: classes.error }} htmlFor="filled-adornment-textfield">
        <span className={classes.helperText}>
          {error
            && <ErrorIcon className={classes.icon} />}
          {error}
        </span>
      </FormHelperText>
    </div>
  );
};

export default CardNumberField;
