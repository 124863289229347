import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    marginTop: 40
  },
  parapgraphContainer: {
    width: '80%'
  },
  titleContainer: {
    width: '90%',
    backgroundColor: theme.palette.secondary.main,
    padding: 24
  },
  secondTitle: {
    fontSize: 28,
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  }
}));
