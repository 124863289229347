import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    background: fade("#fff", .9),
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    borderRadius: ({ squareModale }) => squareModale ? 0 : 183,
    width: '90%',
    padding: ({ squareModale }) => squareModale ? 0 : theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      padding: ({ squareModale }) => squareModale ? 0 : `${theme.spacing(5)}px ${theme.spacing(10)}px`
    },
  },
  buttonRoot: {
    padding: 0
  },
  popupMessage: {
    color: 'black',
    fontWeight: 'bold',
    textTransform: 'initial',
    textAlign: 'center'
  },
  cancelIcon: {
    fontSize: 70
  },
  checkIcon: {
    color: 'green',
    fontSize: 70
  }
}));