import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { push } from 'connected-react-router';
import styles from './styles';

const Searchbar = () => {
  const classes = styles();
  const dispatch = useDispatch()

  const { searchValue } = useSelector(state => ({
    searchValue: state.getIn(['root', 'searchValue', 'value'])
  }));
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(searchValue)
  }, [searchValue])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value) {
      dispatch(push('/resultats-recherche?q=' + value));
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Recherche"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(event) => setValue(event.target.value)}
        value={value}
        onClick={handleSubmit}
      />
      <button type="submit" className={classes.invisible} onClick={handleSubmit}>Search</button>
    </form>
  );
}

export default Searchbar;
