import { countOccurrences } from './formats';

export const selectActOnClick = ({ act, setFieldValue, values, selectedHourIndex }) => {
  const fieldValueByActType = act?.isPackage === "TRUE" ? `medicActs.${selectedHourIndex}.packageSession` : `medicActs.${selectedHourIndex}.acts`;
  const valueListByActType = act?.isPackage === "TRUE" ? values.medicActs[selectedHourIndex].packageSession : values.medicActs[selectedHourIndex].acts
  if (act !== null && act !== undefined) {
    setFieldValue(fieldValueByActType, [...valueListByActType, act?.id]);
  }
};


export const disableAct = (act, values, selectedHourIndex) => {
  const isTLL = act.id === "TLL";
  const isTLD = act.id === "TLD";
  const isTLLorTLD = act.id === "TLL" || act.id === "TLD";
  // actIds = acts of the current time slot *only*
  const actIds = values.medicActs[selectedHourIndex]?.acts?.concat(values.medicActs[selectedHourIndex]?.packageSession) || [];
  const TLLorTLDChecked = actIds?.includes("TLL") || actIds?.includes("TLD");

  if (isTLLorTLD) {
    if (actIds?.length > 0 && !TLLorTLDChecked) { // if 1 act is checked, no TLL nor TLD
      return true
    } else {
      if (isTLD && actIds?.includes("TLL") || isTLL && actIds?.includes("TLD")) { // One of TLL or TLD
        return true
      }
      return false
    }
  } else {
    if (TLLorTLDChecked && !isTLLorTLD) { // if TLL or TLD, no other act can be ckecked
      return true
    }

    const AstreinteList = ["c01a06_10", "c01a12_04", "AIS13", "AIS16", "c02a03_05", "c02a04_09", "c02a06_04", "TLL", "TLD"]
    const allTypes = values?.medicActs?.map(x => x.acts).concat(values?.medicActs?.map(y => y.packageSession)).flat(1);

    // Rule n°1 : if daily limit reached, exclude the act
    if (act.dailyLimit && act.dailyLimit !== "") {
      let isLimitReached = false
      if (countOccurrences(allTypes, act.id) >= Number(act.dailyLimit)) {
        isLimitReached = true
      }
      if (isLimitReached) {
        return true
      }
    }

    // Rule n°2 : if hourly limit reached, excluded the act
    if(act.hourlyLimit && act.hourlyLimit !== ""){
      let isLimitReached = false
      if(countOccurrences(actIds, act.id) >= Number(act.hourlyLimit)){
        isLimitReached = true
      }
      if(isLimitReached){
        return true
      }
    }

    // Rule n°3 : if exclusion field filed, exclude the item in case of act already selected
    if (act.exclusion && act.exclusion !== "") {
      let isExludedActsInValues = false;
      act.exclusion.split(";").map(excludedAct => {
        if (actIds?.includes(excludedAct)) {
          isExludedActsInValues = true;
        }
      });
      if (isExludedActsInValues) {
        return true;
      }
    }

    // Rule n°4 : if inclusion field filed, exclude the item in case of act not selected
    if (act.inclusion && act.inclusion !== "") {
      let isInludedActsInValues = false;
      act.inclusion.split(";").map(includedAct => {
        if (actIds?.includes(includedAct)) {
          isInludedActsInValues = true;
        }
      });
      if (!isInludedActsInValues) {
        return true;
      }
    }

    // Rule n°5 : if an act isAstreinte = true, only this act can constitute a time slot, and no other act can be added to it except for c02a04_09, c02a04_10, c02a03_05, c02a03_06 
    if (actIds?.some(x => AstreinteList.includes(x))) {
      return true
    }

    if (actIds?.length >= 1 && (act.isAstreinte === "TRUE" || act.id === "c01a06_10")) {
      return true
    }
  }

  // Rule n° 6 : if global exclusion field filed, exclude the item in case of act already selected
  if (act.globalExclusion && act.globalExclusion !== "") {
    let isExludedActsInValues = false;
    act.globalExclusion.split(";").map(excludedAct => {
      if (values?.medicActs?.find(x => x.acts?.includes(excludedAct) || x.packageSession?.includes(excludedAct))) {
        isExludedActsInValues = true;
      }
    });
    if (isExludedActsInValues) {
      return true;
    }
  }

  // Rule n°7 : if global inclusion field filed, exclude the item in case of act not selected
  if (act.globalInclusion && act.globalInclusion !== "") {
    let isInludedActsInValues = false;
    act.globalInclusion.split(";").map(includedAct => {
      if (values?.medicActs?.find(x => x.acts?.includes(includedAct) || x.packageSession?.includes(includedAct))) {
        isInludedActsInValues = true;
      }
    });
    if (!isInludedActsInValues) {
      return true;
    }
  }

  return false
}

export const renderActCode = (act) => {
  if (act?.chapter) {
    const code = act?.isAMX === "TRUE" ? `${act?.key} ou AMX` : `${act?.key}`;
    return ["BSA", "BSB", "BSC"].includes(act.key) ? `- ${code}` : `- ${code} ${act?.coefficient}`
  } else {
    return ''
  }
};
