import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  loaderWrapper: {
    height: '59vh'
  },
  wrapper: {
    background: fade(theme.palette.neutral.light, .1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  tableRoot: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  title: {
    margin: theme.spacing(3, 0)
  }
}));
