import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyringe, faClinicMedical,faUserNurse,
  faBookMedical, faHeadset, faBandAid,
  faHeadSideCough, faAllergies, faVial, faBlind
} from '@fortawesome/free-solid-svg-icons';
import styles from './styles';

const QuoteCategoriesIcon = ({ title }) => {
  const classes = styles();

    switch (title) {
      case "PRÉLÈVEMENTS ET INJECTIONS":
        return <FontAwesomeIcon icon={faVial} className={classes.icon}/>
      case "PERFUSIONS":
        return <img src="https://img.icons8.com/material/24/ffffff/infusion-pumps.png" className={classes.icon}/>
      case "PANSEMENTS COURANTS":
      case "PANSEMENTS LOURDS ET COMPLEXES":
        return <FontAwesomeIcon icon={faBandAid} className={classes.icon}/>
      case "SOINS APPAREIL DIGESTIF":
        return <img src="https://img.icons8.com/ios-glyphs/30/ffffff/stomach.png" className={classes.icon}/>
      case "SOINS APPAREIL RESPIRATOIRE":
        return <FontAwesomeIcon icon={faHeadSideCough} className={classes.icon}/>
      case "SOINS PORTANT SUR L'APPAREIL DIGESTIF ET URINAIRE":
        return <img src="https://img.icons8.com/fluency-systems-filled/30/ffffff/uterus.png"/>
      case "SOINS LIÉS A LA DÉPENDANCE":
        return <FontAwesomeIcon icon={faBlind} className={classes.icon}/>
      case "SOINS ENVELOPPE CUTANÉE":
        return <FontAwesomeIcon icon={faAllergies} className={classes.icon}/>
      case "PRISE EN CHARGE À DOMICILE D'UN PATIENT INSULINO-TRAITÉ":
        return <FontAwesomeIcon icon={faUserNurse} className={classes.icon}/>
      case "PRISE EN CHARGE SPÉCIALISÉE":
        return <FontAwesomeIcon icon={faUserNurse} className={classes.icon}/>
      case "SURVEILLANCE ET OBSERVATION D'UN PATIENT À DOMICILE":
      case "SOINS INFIRMIERS À DOMICILE LIÉS À LA DÉPENDANCE":
        return <FontAwesomeIcon icon={faClinicMedical} className={classes.icon}/>
      case "ACTES D'ACCOMPAGNEMENT À LA TÉLÉCONSULTATION":
        return <FontAwesomeIcon icon={faHeadset} className={classes.icon} />
      case "SOINS POSTOPÉRATOIRES À DOMICILE SELON PROTOCOLE":
        return <FontAwesomeIcon icon={faBookMedical} className={classes.icon}/>
      case "SOINS APPAREIL GÉNITO-URINAIRE":
        return <img src="https://img.icons8.com/fluency-systems-filled/30/ffffff/uterus.png"/>
      case "SOINS D'ENTRETIEN DES CATHÉTERS":
        return <img src="https://img.icons8.com/external-those-icons-fill-those-icons/24/ffffff/external-transfusion-medical-healthcare-those-icons-fill-those-icons.png"/>
      case "GARDE À DOMICILE":
        return <img src="https://img.icons8.com/metro/26/ffffff/doctors-bag.png"/>
      default:
        return <FontAwesomeIcon icon={faSyringe} className={classes.icon}/>
    }
}

export default QuoteCategoriesIcon;

QuoteCategoriesIcon.propTypes = {
  title: PropTypes.string.isRequired,
};