import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { CustomTextField } from '../Inputs/CustomTextField';
import { CustomButton } from '../Button';
import { CustomPasswordField } from '../Inputs/CustomPasswordField';
import styles from '../../utils/styles';

const SubmitForm = (props) => {
  const { t } = useTranslation();
  const classes = styles();

  const { submitPasswordLoading } = useSelector((state) => ({
    submitPasswordLoading: state.getIn(['app', 'submitPasswordLoading'])
  }));
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit
  } = props;
  const { code, password, confirmPassword } = values;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item className={classes.fieldWrapper}>
        <CustomTextField
          id={'code'}
          name={'code'}
          value={code}
          handleBlur={handleBlur}
          handleChange={handleChange}
          label={t('code')}
          error={!!touched.code && !!errors.code}
          helperText={touched.code && errors.code ? t(errors.code) : ''}
          fullWidth
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <CustomPasswordField
          id={'password'}
          name={'password'}
          value={password}
          handleBlur={handleBlur}
          handleChange={handleChange}
          label={t('password')}
          error={!!touched.password && !!errors.password}
          helperText={touched.password && errors.password ? t(errors.password) : ''}
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <CustomPasswordField
          id={'confirmPassword'}
          name={'confirmPassword'}
          value={confirmPassword}
          handleBlur={handleBlur}
          handleChange={handleChange}
          label={t('confirmPassword')}
          error={!!touched.confirmPassword && !!errors.confirmPassword}
          helperText={touched.confirmPassword && errors.confirmPassword ? t(errors.confirmPassword) : ''}
        />
      </Grid>
      <CustomButton
        type="submit"
        handleClick={() => { handleSubmit({ code, password, confirmPassword }); }}
        loading={submitPasswordLoading}
        title={t('submitNewPasswordButton')}
      />
    </Grid>
  );
};

export default SubmitForm;
