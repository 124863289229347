import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    marginTop: 40
  },
  inputWrapper: {
    position: 'relative',
    margin: theme.spacing(3, 0),
    width: '100%'
  },
  searchIcon: {
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 8,
    left: 8
  },
  inputRoot: {
    color: 'inherit',
    border: `1px solid ${theme.palette.neutral.main}`,
    borderRadius: 4,
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width')
  },
  invisible: {
    display: 'none'
  },
  clearButton: {
    position: 'absolute',
    top: 5,
    right: 3
  }
}));