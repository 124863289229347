import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { doPaymentLaunched } from '../../components/App/reducer';
import styles from './styles';
import { TitleBanner } from '../../components/TitleBanner';
import { Tab, Tabs, Typography } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { faMoneyCheck, faExchangeAlt, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CBForm from '../../components/PaymentForm/CB';
import {
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import CheckForm from '../../components/PaymentForm/Check';
import TransferForm from '../../components/PaymentForm/Transfer';
import DirectDebitForm from '../../components/PaymentForm/DirectDebit';
import sepa from '../../assets/image/icons8-sepa-100.png';
import { loadStripe } from '@stripe/stripe-js';

// eslint-disable-next-line no-undef
const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentContent = () => {
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const classes = styles();
  const [paymentMethod, setPaymentMethod] = React.useState(0);

  const { user } = useSelector((state) => ({
    user: state.getIn(['app', 'userDynamo', 'user'])
  }));

  // Form data
  const initialValues = {
    name: '',
    address: '',
    city: '',
    zipCode: '',
  };

  // Form Submitting Function
  const pay = (payload) => {
    let billing_details = {};
    let paymentMethodValue;
    if (paymentMethod === 0) {
      paymentMethodValue = 'CB';
    } else if (paymentMethod === 1) {
      paymentMethodValue = 'CHECK';
    } else if (paymentMethod === 2) {
      paymentMethodValue = 'SEPA_TRANSFER';
    } else if (paymentMethod === 3) {
      paymentMethodValue = 'SEPA_DIRECT_DEBIT';
      billing_details = {
        name: payload.name,
        email: user?.email,
      };
    }

    dispatch(doPaymentLaunched({
      paymentMethod: paymentMethodValue, // CHECK / TRANSFER / SEPA_DIRECT_DEBIT
      elements: elements,
      stripe: stripe,
      billing_details
    }))
  };

  const ZIPCODE_ERROR_MESSAGE = "Merci de renseigner un code postal valide";
  // Form Validation Schema
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Merci de renseigner votre nom complet"),
    address: Yup.string().trim().required("L'adresse du cabinet est obligatoire"),
    city: Yup.string().trim().required("La ville est obligatoire"),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, ZIPCODE_ERROR_MESSAGE)
      .min(5, ZIPCODE_ERROR_MESSAGE)
      .max(5, ZIPCODE_ERROR_MESSAGE)
      .required("Le code est obligatoire"),
  });

  const handleChange = (event, newValue) => {
    setPaymentMethod(newValue);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <TitleBanner title="Espace paiement" color="primary" />
      <Grid item container direction="column" alignItems="center" justify="center">
        <Tabs
          value={paymentMethod}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          classes={{ root: classes.tabsContainerRoot }}
        >
          <Tab
            label={<Typography variant="caption" >CARTE BANCAIRE</Typography>}
            id="CB"
            classes={{ root: classes.rootTab }}
            aria-controls="simple-tabpanel-0"
            icon={<FontAwesomeIcon icon={faCreditCard} className={classes.icon} />}
          />
          <Tab
            label={<Typography variant="caption" >CHÈQUE</Typography>}
            id="CHECK"
            classes={{ root: classes.rootTab }}
            aria-controls="simple-tabpanel-1"
            icon={<FontAwesomeIcon icon={faMoneyCheck} className={classes.icon} />}
          />
          <Tab
            label={<Typography variant="caption" >VIREMENT</Typography>}
            id="TRANSFER"
            classes={{ root: classes.rootTab }}
            aria-controls="simple-tabpanel-2"
            icon={<FontAwesomeIcon icon={faExchangeAlt} className={classes.icon} />}
          />
          <Tab
            label={<Typography variant="caption" >PRÉLÈVEMENT</Typography>}
            id="SEPA_DIRECT_DEBIT"
            classes={{ root: classes.rootTab }}
            aria-controls="simple-tabpanel-3"
            icon={<img src={sepa} className={classes.sepa} />}
          />
        </Tabs>
        <TabPanel value={paymentMethod} index={0} className={classes.tabPanel}>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={pay}
          >
            {(props) => <CBForm {...props} />}
          </Formik>
        </TabPanel>
        <TabPanel value={paymentMethod} index={1} className={classes.tabPanel}>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={pay}
          >
            {(props) => <CheckForm {...props} />}
          </Formik>
        </TabPanel>
        <TabPanel value={paymentMethod} index={2} className={classes.tabPanel}>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={pay}
          >
            {(props) => <TransferForm {...props} />}
          </Formik>
        </TabPanel>
        <TabPanel value={paymentMethod} index={3} className={classes.tabPanel}>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={pay}
          >
            {(props) => <DirectDebitForm {...props} />}
          </Formik>
        </TabPanel>
      </Grid>
    </Grid >
  );
};

const PaymentPage = () => (
  <Elements stripe={promise}>
    <PaymentContent />
  </Elements>
)


export default PaymentPage;
