import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';

import CustomButton from '../../Button';
import CustomPasswordField from '../../Inputs/CustomPasswordField';
import { switchPasswordLabel } from '../../../utils/services/formats';

export const ChangePasswordForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleSubmit
}) => {

  const fields = Object.keys(values);
  const { changePasswordLoading } = useSelector((state) => ({
    changePasswordLoading: state.getIn(['app', 'changePasswordLoading']),
  }));

  return (
    <form onSubmit={handleSubmit}>
      <Box p={3}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          {fields.map((field, key) => {
            return (
              <Grid item key={key} container direction="column" alignItems="center">
                <Box my={1}>
                  <CustomPasswordField
                    label={switchPasswordLabel(field)}
                    error={!!touched[field] && !!errors[field]}
                    helperText={touched[field] && errors[field]}
                    id={field}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isModalePasswordField
                  />
                </Box>
              </Grid>
            )
          })}
          <Box mt={3}>
            <CustomButton
              title="Changer mon mot de passe"
              loading={changePasswordLoading}
              color="primary"
              type="submit"
              variant="contained"
              withoutPadding
            />
          </Box>
        </Grid>
      </Box>
    </form>
  );
};

export default ChangePasswordForm;