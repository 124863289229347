import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import Title from '../../components/Title';
import Loader from '../../components/Loader';
import { getUserLaunched } from '../../components/App/reducer';
import { getAllContractsByUserLaunched } from '../../store/reducer';
import styles from './styles';
import AdminSearchbar from '../../components/AdminSearchbar';
import ContractsTable from "../../components/ContractsTable/Table";

const AdminPage = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user,
    getAllContractsByUserLoading,
    allContractsByUser
  } = useSelector((state) => ({
    user: state.getIn(['app', 'userDynamo', 'user']),
    allQuotes: state.getIn(['store', 'allQuotes']),
    getAllQuotesLoading: state.getIn(['store', 'getAllQuotesLoading']),
    searchValue: state.getIn(['store', 'searchValue']),
    getAllContractsByUserLoading: state.getIn(['store', 'getAllContractsByUserLoading']),
    allContractsByUser: state.getIn(['store', 'allContractsByUser']),
  }));

  const [value, setValue] = useState('');

  useEffect(() => {
    if (user.id) {
      dispatch(getUserLaunched({ userId: user.id }))
    }
  }, []);

  useEffect(() => {
    if (user.isAdmin) {
      dispatch(getAllContractsByUserLaunched())
    }
  }, []);

  // Filtre si un des contrats n'a pas de date de début
  const allUserWithValidation = allContractsByUser?.filter(({ contracts }) => (
    contracts.some(({ startDate }) => startDate === undefined)
  ))

  // Filtre si tous les contrats ont une date de début et si l'email, le nom ou le prénom contiennent la valeur de la barre de recherche si elle n'est pas vide
  const valueToLowerCase = value.toLowerCase().trim();
  const allUserWithoutValidation = allContractsByUser?.filter(({ email, contracts, firstName, lastName }) => (
    contracts.every(({ startDate }) => startDate !== undefined) && (
      value !== '' ?
        (email.toLowerCase().includes(valueToLowerCase) || firstName.toLowerCase().includes(valueToLowerCase) || lastName.toLowerCase().includes(valueToLowerCase))
        : true)
  ))

  return (
    user?.isAdmin && (
      <>
        <Title title={`Administration - ${user.firstName}`} color={"secondary"} />
        <Grid item container direction="column" alignContent="center" alignItems="center" className={classes.root}>
          {
            getAllContractsByUserLoading && allContractsByUser === null ?
              <Loader />
              :
              <>
                <Typography variant='h5' className={classes.title}>Utilisateurs en attente de validation</Typography>
                <ContractsTable changesAllowed={true} contracts={allUserWithValidation} />
                <AdminSearchbar setValue={setValue} value={value} />
                <ContractsTable changesAllowed={false} contracts={allUserWithoutValidation} />
              </>
          }
        </Grid>
      </>
    )
  );
}
  ;
export default AdminPage;
