import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, InputBase, IconButton, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';

import { setSearchValue } from '../../store/reducer';
import styles from './styles';

const Searchbar = ({ setValue, value }) => {
  const classes = styles();
  // const dispatch = useDispatch()

  // const { getAllQuotesLoading, searchValue } = useSelector((state) => ({
  //   getAllQuotesLoading: state.getIn(['store', 'getAllQuotesLoading']),
  //   searchValue: state.getIn(['store', 'searchValue']),
  // }));

  // const [value, setValue] = useState(searchValue);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (value) {
  //     dispatch(setSearchValue(value))
  //   }
  // }

  const handleClearSearchValue = () => {
    // if (searchValue) {
    //   dispatch(setSearchValue(''));
    // }
    setValue("")
  }

  return (
    // <form onSubmit={handleSubmit} >
    <Grid container direction="column" className={classes.container}>
      <Typography variant='h5'>Trouvez facilement un utilisateur par son adresse email, son nom ou son prénom</Typography>
      <Grid item xs={12} sm={8} md={6} lg={4} className={classes.inputWrapper}>
        <SearchIcon className={classes.searchIcon} />
        <InputBase
          placeholder="Recherche"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(event) => setValue(event.target.value)}
          value={value || ''}
        // disabled={getAllQuotesLoading}
        />
        <button type="submit" className={classes.invisible}>Search</button>
        <IconButton disableFocusRipple disableRipple size="small" color="primary" type="button" onClick={() => handleClearSearchValue()}
          className={classes.clearButton}>
          <Close />
        </IconButton>
      </Grid>
    </Grid>
    // </form>
  );
}

export default Searchbar;