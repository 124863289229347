import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    height: '45vh',
    width: '100%',
  },
  view: {
    height: '40vh'
  },
  arrowButtonLeft: {
    position: 'absolute',
    color: 'rgb(255, 255, 255, 0.8)',
    zIndex: 2,
    top: 'calc(50% - 30px)',
    cursor: 'pointer',
    left: '5%'
  },
  arrowButtonRight: {
    position: 'absolute',
    color: 'rgb(255, 255, 255, 0.8)',
    zIndex: 2,
    top: 'calc(50% - 30px)',
    cursor: 'pointer',
    right: '5%'
  },
  arrowIcon: {
    color: '#fff',
    fontSize: 60
  },
  mainContainer: {
    width: "80%",
    height: "100%",
    position: 'absolute',
    top: 0,
    [theme.breakpoints.down('md')]: {
      paddingBottom: '10%'
    },
  },
  titleContainer: {
    justify: "center",
    width: "100%",
    backgroundColor: 'rgb(255, 255, 255, 0.8)',
    borderRadius: 5,
    padding: "1rem 0",
    boxShadow: "1px 1px 3px black",
  },
  title: {
    marginBottom: 20
  },
  link: {
    textDecoration: 'none',
    color: '#000',
    width: '70%'
  }
}));
