import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../utils/configureMaterialTheme';

export default makeStyles(() => ({
  videoContainer: {
    paddingTop: '5%',
    paddingBottom: '5%'
  },
  video: {
    paddingLeft: '20%',
    '@media (max-width:570px)': {
      padding: 0,
      width: '100%'
    },
  },
  icon: {
    color: theme.palette.primary.main
  },
  contentTitle: {
    fontWeight: "bold"
  },
  buttonsWrapper: {
    flexDirection: 'column',
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "flex-end"
    }
  },
  button: {
    display: "flex",
    justifyContent: "center",
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1),
    }
  }
}));
