import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '80%'
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  adherentNumberWrapper: {
    margin: `clamp(2rem, 6vw, 6rem) auto clamp(2rem, 5vw, 6rem) ${theme.spacing(2)}px`,
    '@media (min-width: 600px) and (max-width: 800px)': {
      margin: `clamp(4rem, 3vw, 6rem) auto clamp(4rem, 3vw, 6rem) ${theme.spacing(8)}px`,
    }
  },
  invoice: {
    margin: 10
  }
}));
