/**
 * @param {number} date - Timestamp with milliseconds
 * @returns the date formatted as "2021-05-05"
 */
export const addProtocol = (url, protocol) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = `${protocol}://${url}`;
  }
  return url;
}

export const formatTimestampToDateWithDashes = (date) => {
  return new Date(date).toISOString().split('T')[0];
}

export const formatHour = (date) => {
  const hour = new Date(date).getHours()
  const minute = new Date(date).getMinutes();
  const hourMinute = minute === 0 ? (hour + 'h' + '00') : (hour + 'h' + minute);
  return hourMinute
}

/**
 * @param {string} date - date as "2021-05-13"
 * @returns Date as "13-05-201"
 */
export const formatDateToFrenchFormat = (date) => {
  const splittedEnglishDate = date?.split("-");
  const calendarDate = new Date(date).getDate();
  const day = calendarDate < 10 ? ('0' + calendarDate) : calendarDate;
  if (splittedEnglishDate) {
    return `${day}-${splittedEnglishDate[1]}-${splittedEnglishDate[0]}`
  }
}


/**
 *
 * @param {number} date - Timestamp
 * @param {number} numberOfDays
 * @returns - date + numberOfDays as timestamp
 */
export const addDaysToTimestamp = (date, numberOfDays) => {
  return date + 24 * 60 * 60 * 1000 * numberOfDays;
}


/**
 *
 * @param {array} arr - The elements to sort
 * @param {string} field - The field to compare
 * @returns - The array of elements - ASC order
 */
export const sortArrayOfObjectsOnFieldName = (arr, field) => {
  return arr?.sort((a, b) => b[field] - a[field])
}


export const addTimestampToObjectFromNestedField = (objects) => {
  return objects.map(x => {
    let splittedDate = x.fields.date.split('-');
    let timestamp = new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2]).getTime();
    return {
      ...x,
      timestamp
    }
  });
}

/**
 * Format the profile form's labels from field id
 * @param {string} field - the field value to format
 * @returns the formatted label
 */
export const switchLabel = (field) => {
  switch (field) {
    case 'lastName':
      return 'Nom';
    case 'firstName':
      return 'Prénom';
    case 'address':
      return 'Adresse du cabinet';
    case 'city':
      return 'Ville';
    case 'zipCode':
      return 'Code postal';
    case 'phone':
      return 'Numéro de téléphone';
    case 'email':
      return 'Email';
    default:
      return field
  }
}

/**
 * Transforms an object into an array of objects (used to compare 2 objects and return the differences between them)
 * @param {object} obj - The object to be splitted into an array of objects - ex: {id: 1254, name: "Jane Smith"}
 * @returns [{id: 1254}, {name: "Jane Smith"}]
 */
export const objectToArrayOfKeyPairs = (obj) => {
  return Object.keys(obj).map((x, key) => {
    const val = Object.values(obj)[key];
    return { [x]: val }
  })
}

/**
 * @param {array} arr1 - An array containing 1 object - ex: [{city: "Lille", zipCode: 59000}]
 * @param {array} arr2 - An array containing 1 object - ex: [{city: "", zipCode: 59000}]
 * @returns An array containing the objects that are different between arr1 and arr2
 */
export const compareArraysOfObjects = (arr1, arr2) => {
  let diff = [];
  for (let i = 0; i < arr1.length; i++) {
    // stringify the objects to best check if they are equal
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      diff.push(arr2[i])
    }
  }
  return diff;
}

/**
 * @param {array} array - The array of object - ex: [{id: 1}, {name: "John Doe"}]
 * @returns the array's elements as an object of pairs key-value - ex: {id: 1, name: "John Doe"}
 */
export const arrayToObject = (array) => {
  if (Array.isArray(array)) {
    let obj = {};
    array.forEach(item => {
      const key = Object.keys(item)[0];
      const val = Object.values(item)[0];
      obj[key] = val;
    })
    return obj;
  }
}

/**
 * Check if the object has at least 1 empty value
 * @param {object} object - The object to check for empty values
 * @returns true if one of the object keys is empty
 */
export const hasNoEmptyValue = (object) => {
  return Object.values(object).every(x => !!x)
};


export const switchPasswordLabel = (field) => {
  switch (field) {
    case "oldPassword":
      return "Mot de passe actuel"
    case "newPassword":
      return "Nouveau mot de passe"
    case "confirmNewPassword":
      return "Confirmer le nouveau mot de passe"
  }
}

export const switchAdminData = (data) => {
  switch (data) {
    case "IMMUNODEPRIME":
      return "Immuno-déprimé"
    case "CANCEREUX":
      return "Cancéreux"
    case "MUCOVISCIDOSE":
      return "Mucoviscidose"
    case "DIABETIQUE":
      return "Diabétique insulinno-traité"
    case "PATIENT_STANDARD":
      return "Sans critère spécifique NGAP"
    case "PATIENT_PALLIATIF":
      return "Palliatif"
    case "DEPENDANCE":
      return "Soins liés à la dépendance"
    case "PSYCHO_DISORDER":
      return "Troubles psychiatriques ou cognitifs"
    case "DOMICILE":
      return "Domicile"
    case "CABINET":
      return "Cabinet"
    case "EHPAD":
      return "Ehpad"
    case "DAY":
      return "Entre 8h et 20h"
    case "NIGHT":
      return "Entre 20h et 23h ou entre 5h et 8h"
    case "MIDNIGHT":
      return "Entre 23h et 5h"
    case "WEEKDAY":
      return "Semaine et samedi"
    case "SUNDAY":
      return "Dimanche et jours fériés"
    case "CALL":
      return "Appel d'urgence passé samedi après 8h"
    case "MALE":
      return "Homme"
    case "FEMALE":
      return "Femme"
    default:
      break;
  }
}

/**
 * @param {string} text - The text to truncate
 * @param {number} maxLength - The text's max length
 * @returns the text truncated at maxLength, with ellipsis ("...")
 */
export const truncateText = (text, maxLength) => {
  const LENGTH = maxLength || 40;
  if (text?.length > LENGTH) {
    return `${text?.slice(0, LENGTH)}...`
  }
  return text;
}

/**
 * @param {array} arrOfObjects - ex: [{title: 'One', value: 1}, {title: 'Two', value: 2}, {title: 'One': value: 1.5}]
 * @param {string} field - the property field to group the array by, ex: 'title'
 * @returns - {One: [{title: 'One', value: 1}, {title: 'One': value: 1.5}], Two: [{title: 'Two', value: 2}]}
 */
export const groupObjectsByPropertyValue = (arrOfObjects, field) => {
  return arrOfObjects.reduce(function (r, a) {
    r[a[field]] = r[a[field]] || [];
    r[a[field]].push(a);
    return r;
  }, {})
}


export const arrayFromObjectProperties = (object) => {
  return Object.keys(object).map(key => {
    return object[key]
  })
}

/**
 *
 * @param {object} time - the date object
 * @returns ex: "12:44"
 */
export const formatTimePickerValueToString = (time) => {
  if (time) {
    return `${time?.getHours() < 10 ? '0' : ''}${time?.getHours()}:${time?.getMinutes() < 10 ? '0' : ''}${time?.getMinutes()}`;
  }
};

export const switchPatientTypes = (type) => {
  switch (type) {
    case "CANCEREUX":
      return "Cancéreux"
    case "DIABETIQUE":
      return "Insulino-traité"
    case "DEPENDANCE":
      return "Soins liés à la dépendance"
    case "MUCOVISCIDOSE":
      return "Mucoviscidose"
    case "PSYCHO_DISORDER":
      return "Troubles psychiatriques ou cognitifs"
    case "IMMUNODEPRIME":
      return "Immuno-déprimé"
    case "PATIENT_PALLIATIF":
      return "Palliatif"
    case "PATIENT_STANDARD":
      return "Sans critère spécifique NGAP"
    default:
      break;
  }
}

export const switchHourInStringType = (hourInString) => {
  let splittedHour = +hourInString.split(':')[0];
  if (splittedHour >= 8 && splittedHour < 20) {
    return "DAY"
  } else if ((splittedHour >= 20 && splittedHour < 23) || (splittedHour > 5 && splittedHour < 8)) {
    return "NIGHT"
  } else {
    return "MIDNIGHT"
  }
}

export const switchPatientValues = (value) => {
  switch (value) {
    case "DOMICILE":
      return "isHome"
    case "DIABETIQUE":
      return "isDiabetic"
    case "FEMALE":
      return "isFemaleCare"
    case "MALE":
      return "isMaleCare"
    case "PSYCHO_DISORDER":
      return "isPsychoDisorder"
    case "CANCEREUX":
    case "IMMUNODEPRIME":
      return "isImmunocompromisedOrCancerous"
    case "MUCOVISCIDOSE":
      return "isCysticFibrosis"
    case "DEPENDANCE":
      return "isDependant"
    default:
      return value
  }
}

export const filterMedicActsData = ({ medicActsData, patientContext }) => {
  let acts = [], actsWithLocation = [], actsWithAge = [], actsWithGenre = [], actsWithAllChecks = [];

  let patientContextSwitchedValues = {
    location: switchPatientValues(patientContext?.location),
    sex: switchPatientValues(patientContext?.sex),
    patientType: patientContext?.patientType?.map(item => switchPatientValues(item)) || [],
  }

  if (!patientContextSwitchedValues.patientType.includes("isDependant")) {
    patientContextSwitchedValues.patientType.push("isNonDependant");
  }

  if (!patientContextSwitchedValues.patientType.includes("isImmunocompromisedOrCancerous")) {
    patientContextSwitchedValues.patientType.push("isNonImmunoCompromisedOrCancerous");
  }

  // Filters based on location
  if (patientContextSwitchedValues.location === "isHome") {
    actsWithLocation = medicActsData;
  } else {
    actsWithLocation = medicActsData?.filter(act => act.isHome !== "TRUE")
  }

  // Filters based on age
  if (patientContext?.age >= 90) {
    actsWithAge = patientContextSwitchedValues?.patientType.includes("isPsychoDisorder") ?
      actsWithLocation?.filter(act => !act.article.includes("Article 11")) :
      actsWithLocation?.filter(act => !act.article.includes("Article 11")).filter(act => !act.article.includes("Article 10"))
  } else if (patientContext?.age < 75) {
    actsWithAge = actsWithLocation?.filter(act => act.id !== "c02a05_04")
    if (patientContext?.age > 5) {
      actsWithAge = actsWithAge?.filter(act => act.id !== "c01a01_06")
    }
  } else {
    actsWithAge = actsWithLocation;
  }

  // Filters based on genre
  if (patientContextSwitchedValues.sex === "isFemaleCare") {
    actsWithGenre = actsWithAge?.filter(act => act.isFemaleCare === "TRUE")
  } else {
    actsWithGenre = actsWithAge?.filter(act => act.isMaleCare === "TRUE")
  }
  //  console.log("actsWithGenre", actsWithGenre);
  // Filters based on patient types
  const patientTypes = patientContextSwitchedValues?.patientType;
  // The patientType "palliatif" doesn't affect the acts list, only algo calculations

  const types = [
    "isDiabetic",
    "isPsychoDisorder",
    "isImmunocompromisedOrCancerous",
    "isNonImmunoCompromisedOrCancerous",
    "isDependant",
    "isCysticFibrosis",
    "isNonDependant"
  ];
  const uncheckedTypes = types?.filter(x => !patientTypes?.includes(x));


  for (let i = 0; i < actsWithGenre?.length; i++) {
    for (let j = 0; j < uncheckedTypes?.length; j++) {
      if (actsWithGenre[i][uncheckedTypes[j]] === "TRUE") {
        // Create an array with all acts that correspond to unchecked types
        acts.push(actsWithGenre[i])
      }
    }
  }
  // Compare both arrays to filter out acts that are not checked
  const actsWithTypes = actsWithGenre?.filter(x => !acts?.includes(x));

  // Checking exclusion
  //  console.log("actsWithTypes", actsWithTypes);


  if (patientContextSwitchedValues.patientType?.includes("isImmunocompromisedOrCancerous")) {
    actsWithAllChecks = actsWithTypes?.filter(act => !(act.chapter.includes("Chapitre 2") && act.article.includes("Article 3")))
    if (actsWithAllChecks.filter(act => act.id === 'c02a04_02')[0]) {
      actsWithAllChecks = actsWithAllChecks.filter(act => !['c01a01_07', 'c01a01_10'].includes(act.id))
    }
  } else {
    actsWithAllChecks = actsWithTypes;
  }
  // No AIS4 if age >= 75 && patientType = isDiabetic
  if (actsWithAllChecks?.some(x => x.id === "c02a05_04")) {
    actsWithAllChecks = actsWithAllChecks.filter(act => act.id !== "AIS4")
  }
  return actsWithAllChecks;
}

export const renderCategoryChapterTitle = (chapterKey) => {
  switch (chapterKey) {
    case 0:
      return `Pratique courante`
    case 1:
      return `Soins spécialisés`
    case 2:
      return "Téléconsultation"
  }
}

export const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

export const getFullFrenchDate = (date, locale) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(locale, options);
}

export const roundUp = (decimal) => {
  return (Math.round(decimal * 100) / 100).toFixed(2)
}
