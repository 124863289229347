import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'fr',
    debug: true,
    lng: 'fr',
    resources: {
      en: {
        translation: {
          // APP
          signUpForm: {
            email: 'Email',
            password: 'Password',
            confirmPassword: 'Confirm password',
            age: 'Age',
            genre: 'Genre',
            newsletter: 'Newsletter',
            emailRequired: 'Email is required field',
            emailType: 'Enter a valid email',
            passwordRequired: 'Password is required field',
            confirmPasswordRequired: 'Comfirm password is required field',
            matchPassword: 'Passwords must match',
            ageRequired: 'Age is required field',
            genreRequired: 'Genre is required field',
          },
          loginPageTitle: 'SignIn',
          email: 'Email',
          password: 'Password',
          confirmPassword: 'Confirm password',
          emailRequired: 'Please enter your email',
          passwordRequired: 'Please enter your password',
          loginSubmit: 'Login',
          signUpSubmit: 'Sign Up',
          signUpPageTitle: 'Sign Up',
          confirmPasswordRequired: 'Please confirm your password',
          passwordMismatch: 'Passwords do not match',
          loginButton: 'Login',
          signUpButton: 'New ? Create an account !',
          logoutButton: 'Logout',
          homePageTitle: 'Home Page',
          requestCodeButton: 'Send',
          emailNotValid: 'Email not valid',
          code: 'Code',
          submitNewPasswordButton: 'Confirm',
          codeRequired: 'Please enter your code',
          forgotPasswordPageTitle: 'Reset your Password',
          forgotPasswordButton: 'Password forgotten ?',
          firstLoginPageTitle: 'Almost there ! We need more informations to complete your subsribtion',
          updateUserError: 'An error occured.',
          firstName: 'Firstname',
          lastName: 'Lastname',
          updateUserSubmit: 'Done',
          firstNameRequired: 'Please enter your Firsname',
          lastNameRequired: 'Please enter your Lastname',
          search: 'Search…',
          refresh: 'Refresh'
        }
      },
      fr: {
        // APP
        translation: {
          signUpForm: {
            email: 'Email',
            password: 'Mot de Passe',
            confirmPassword: 'Confirmation mot de passe',
            age: 'Âge',
            genre: 'Genre',
            newsletter: 'Newsletter',
            emailRequired: 'Veuillez saisir votre email',
            emailType: 'Entrez un email valide',
            passwordRequired: 'Veuillez saisir votre mot de passe',
            confirmPasswordRequired: 'Veuillez confirmer votre mot de passe',
            matchPassword: 'Les mots de passe doivent être identique',
            ageRequired: 'Veuillez saisir votre âge',
            genreRequired: 'Veuillez saisir votre sexe',
          },
          loginPageTitle: 'Connexion',
          email: 'Email',
          password: 'Mot de Passe',
          confirmPassword: 'Confirmation mot de passe',
          emailRequired: 'Veuillez saisir votre email',
          passwordRequired: 'Veuillez saisir votre mot de passe',
          loginSubmit: 'Se connecter',
          signUpSubmit: 'S\'inscrire',
          signUpPageTitle: 'Inscription',
          confirmPasswordRequired: 'Veuillez confirmer votre mot de passe',
          passwordMismatch: 'Les mot de passes ne correspondent pas',
          loginButton: 'Se Connecter',
          signUpButton: 'J\'adhère',
          logoutButton: 'Se Deconnecter',
          homePageTitle: 'Page d\'acceuil',
          requestCodeButton: 'Envoyer',
          emailNotValid: 'Email non valide',
          code: 'Code',
          submitNewPasswordButton: 'Confirmer',
          codeRequired: 'Veuillez renseigner votre code',
          forgotPasswordPageTitle: 'Reinitialisez votre mot de passe',
          forgotPasswordButton: 'Mot de passe oublié ?',
          firstLoginPageTitle: 'Vous y êtes presque ! Renseignez ces informations pour completer votre inscription',
          updateUserError: 'Une erreur est survenue',
          firstName: 'Prénom',
          lastName: 'Nom',
          updateUserSubmit: 'Terminer',
          firstNameRequired: 'Veuillez entrer votre prénom',
          lastNameRequired: 'Veuillez entrer votre nom',
          search: 'Rechercher…',
          refresh: 'Rafraîchir',
          forgotPassword: {
            title: "Mot de passe oublié ?",
            text: "Pas de panique ! \nComplétez le champ ci-dessous et nous vous enverrons par email les informations pour réinitialiser votre mot de passe.",
            submitSubtitle: "Renseignez le code reçu à l'adresse suivante :",
            submitSubtitle2: "et choisissez votre nouveau mot de passe."
          }
        }
      }
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
