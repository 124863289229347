import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  button: {
    height: 50,
    textTransform: 'none',
    fontSize: 'clamp(14px, 1.5vw, 18px)',
    fontWeight: 'bold',
    margin: '1rem 0',
    minWidth: ({ withoutPadding }) => withoutPadding ? 200 : 194,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: ({ withoutPadding }) => withoutPadding ? 50 : 'auto',
      paddingRight: ({ withoutPadding }) => withoutPadding ? 50 : 'auto',
      minWidth: ({ withoutPadding }) => withoutPadding ? 251 : 194,
    }
  }
}));
