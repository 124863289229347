/**
 * @param {string} url - l'url à checker
 * @param {*} protocol - le protocole à ajouter
 * @returns l'url précédée du protocole choisi
 */
export const addProtocol = (url, protocol) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = `${protocol}://${url}`;
  }
  return url;
}


/**
 * @param {number} date - Timestamp with milliseconds
 * @returns the date formatted as "2021-05-05"
 */
export const formatTimestampToDateWithDashes = (date) => {
  return new Date(date).toISOString().split('T')[0];
}

/**
 * @param {number} date - Timestamp with milliseconds
 * @returns the time formatted as "08:00"
 */
export const formatTimestampToHours = (timestamp) => {
  let h = new Date(timestamp / 1000).getHours();
  h = (h < 10) ? '0' + h : h;
  let m = new Date(timestamp / 1000).getMinutes();
  m = (m < 10) ? '0' + m : m;
  return h + 'h' + m;
}

/**
 * @param {string} date - date as "2021-05-13"
 * @returns Date as "13-05-201"
 */
export const formatDateToFrenchFormat = (date) => {
  const splittedEnglishDate = date?.split("-");
  if (splittedEnglishDate) {
    return `${splittedEnglishDate[2]}-${splittedEnglishDate[1]}-${splittedEnglishDate[0]}`
  }
}


/**
 *
 * @param {number} date - Timestamp
 * @param {number} numberOfDays
 * @returns - date + numberOfDays as timestamp
 */
export const addDaysToTimestamp = (date, numberOfDays) => {
  return date + 24 * 60 * 60 * 1000 * numberOfDays;
}
