import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: ({ little }) => little ? 190 : '100%',
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 5,
    backgroundColor: '#fcfcfb',
    color: theme.palette.secondary.main,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.secondary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.secondary.main,
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.secondary.main
    },
    "& .MuiTextField-root": {
      color: theme.palette.secondary.main
    },
  },
  error: {
    "& .MuiFormLabel-root": {
      color: theme.palette.secondary.main
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.secondary.main
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: ({ redError }) => redError ? theme.palette.error.main : theme.palette.secondary.main,
    }
  },
  focused: {},
  helperText: {
    display: 'flex',
    alignItems: 'center'
  },
  redRootError: {
    '&$error': {
      color: "#fff"
    },
    margin: '3px 14px'
  },
  icon: {
    marginRight: 5
  },
  input: {
    color: fade("#000", .87),
    "& .MuiFormLabel-root": {
      color: "rgba(0, 0, 0, 0.54)"
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "rgba(0, 0, 0, 0.54)"
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "rgba(0, 0, 0, 0.54)"
    },
    display: 'flex',
    width: '100%'
  },
  rootError: {
    '&$error': {
      color: theme.palette.secondary.main
    }
  },
  numberFieldWrapper: {
    width: '100%'
  }
}));
