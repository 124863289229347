import Immutable from 'immutable';
import { createSlice } from '@reduxjs/toolkit';

const initialState = Immutable.Map({
  isAuthenticated: false,
  isAuthenticating: false,
  userDynamo: null,
  loginLoading: false,
  loginErrorMessage: null,
  logoutLoading: false,
  signupLoading: false,
  signupErrorMessage: null,
  requestCodeLoading: false,
  requestCodeErrorMessage: null,
  submitPasswordLoading: false,
  submitPasswordErrorMessage: null,
  forgotPasswordStep: 1,
  getUserLoading: false,
  getUserErrorMessage: null,
  userData: null,
  getAllUsersLoading: false,
  getAllUsersErrorMessage: null,
  usersData: null,
  updateUserLoading: false,
  updateUserErrorMessage: null,
  doPaymentLoading: false,
  doPaymentError: null,
  snackBarOpen: null,
  snackBarMessage: null,
  snackBarType: null,
  popupOpen: false,
  popupMessage: null,
  popupError: false,
  popupId: null,
  shouldResetForm: null,
  changePasswordLoading: false,
});

const { actions, reducer } = createSlice({
  slice: 'app',
  name: 'app',
  initialState,
  reducers: {
    // GETCURRENT
    getCurrentSessionLaunched: (state, action) => state
      .set('isAuthenticating', true)
      .set('userInfo', null)
      .set('userDynamo', null),
    getCurrentSessionSuccess: (state, action) => state
      .set('isAuthenticated', true)
      .set('isAuthenticating', false)
      .set('userInfo', action.payload.userInfo)
      .set('userDynamo', action.payload.userDynamo),
    getCurrentSessionFailure: (state, action) => state
      .set('isAuthenticating', false)
      .set('isAuthenticated', false)
      .set('userInfo', null)
      .set('userDynamo', null),
    // LOGIN
    loginLaunched: (state, action) => state
      .set('loginLoading', true)
      .set('loginErrorMessage', null),
    loginSuccess: (state, action) => state
      .set('loginLoading', false)
      .set('loginErrorMessage', null),
    loginFailure: (state, action) => state
      .set('loginLoading', false)
      .set('loginErrorMessage', action.payload),
    // LOGOUT
    logoutLaunched: (state, action) => state
      .set('logoutLoading', true),
    logoutSuccess: (state, action) => initialState,
    logoutFailure: (state, action) => state
      .set('logoutLoading', false),
    // SIGNUP
    signupLaunched: (state, action) => state
      .set('signupLoading', true)
      .set('signupErrorMessage', null),
    signupSuccess: (state, action) => state
      .set('signupLoading', false)
      .set('signupErrorMessage', null),
    signupFailure: (state, action) => state
      .set('signupLoading', false)
      .set('signupErrorMessage', action.payload),
    // REQUEST PASSWORD CODE
    requestPasswordCodeLaunched: (state, action) => state
      .set('requestCodeLoading', true)
      .set('requestCodeErrorMessage', null)
      .set('forgotPasswordStep', 1),
    requestPasswordCodeSuccess: (state, action) => state
      .set('requestCodeLoading', false)
      .set('requestCodeErrorMessage', null)
      .set('forgotPasswordStep', 2),
    requestPasswordCodeFailure: (state, action) => state
      .set('requestCodeLoading', false)
      .set('requestCodeErrorMessage', action.payload)
      .set('forgotPasswordStep', 1),
    // SUBMIT NEW PASSWORD
    submitNewPasswordLaunched: (state, action) => state
      .set('submitPasswordLoading', true)
      .set('submitPasswordErrorMessage', null),
    submitNewPasswordSuccess: (state, action) => state
      .set('submitPasswordLoading', false)
      .set('submitPasswordErrorMessage', null),
    submitNewPasswordFaliure: (state, action) => state
      .set('submitPasswordLoading', false)
      .set('submitPasswordErrorMessage', action.payload),
    // GET USER
    getUserLaunched: (state, action) => state
      .set('getUserLoading', true)
      .set('getUserErrorMessage', null),
    getUserSuccess: (state, action) => state
      .set('getUserLoading', false)
      .set('getUserErrorMessage', null)
      .set('userData', action.payload),
    getUserFailure: (state, action) => state
      .set('getUserLoading', false)
      .set('userData', null)
      .set('getUserErrorMessage', action.payload),
    // GET USER
    getAllUsersLaunched: (state, action) => state
      .set('getAllUsersLoading', true)
      .set('getAllUsersErrorMessage', null),
    getAllUsersSuccess: (state, action) => state
      .set('getAllUsersLoading', false)
      .set('usersData', action.payload)
      .set('getAllUsersErrorMessage', null),
    getAllUsersFailure: (state, action) => state
      .set('getAllUsersLoading', false)
      .set('usersData', null)
      .set('getAllUsersErrorMessage', action.payload),
    // UPDATE USER
    updateUserLaunched: (state, action) => state
      .set('updateUserLoading', true)
      .set('updateUserErrorMessage', null),
    updateUserSuccess: (state, action) => state
      .set('updateUserLoading', false)
      .set('updateUserErrorMessage', null),
    updateUserFailure: (state, action) => state
      .set('updateUserLoading', false)
      .set('updateUserErrorMessage', action.payload),
    // DO PAYMENT
    doPaymentLaunched: (state, action) => state
      .set('doPaymentLoading', true)
      .set('doPaymentError', null),
    doPaymentSuccess: (state, action) => state
      .set('doPaymentLoading', false),
    doPaymentFailure: (state, action) => state
      .set('doPaymentLoading', false)
      .set('doPaymentError', action.payload),
    // SnackBar
    openSnackBar: (state, action) => state
      .set('snackBarOpen', true)
      .set('snackBarMessage', action.payload.message)
      .set('snackBarType', action.payload.type),
    closeSnackBar: (state, action) => state
      .set('snackBarOpen', false),
    clearSnackBar: (state, action) => state
      .set('snackBarMessage', null)
      .set('snackBarType', null),

    // Popup
    openPopup: (state, action) => state
      .set('popupOpen', action.payload.open)
      .set('popupMessage', action.payload.message)
      .set('popupError', action.payload.error)
      .set('popupId', action.payload.popupId),
    // ResetPopup
    resetPopupStore: (state, action) => state
      .set('popupOpen', false)
      .set('popupMessage', null)
      .set('popupError', false)
      .set('popupId', null)
      .set('shouldResetForm', true),

    // CHANGE password
    changePasswordLaunched: (state, action) => state
      .set('changePasswordLoading', true)
      .set('changePasswordErrorMessage', null),
    changePasswordSuccess: (state, action) => state
      .set('changePasswordLoading', false)
      .set('changePasswordErrorMessage', null),
    changePasswordFailure: (state, action) => state
      .set('changePasswordLoading', false)
      .set('changePasswordErrorMessage', action.payload)
  }
});

export const {
  getCurrentSessionLaunched,
  getCurrentSessionSuccess,
  getCurrentSessionFailure,
  loginLaunched,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  logoutLaunched,
  signupLaunched,
  signupSuccess,
  signupFailure,
  requestPasswordCodeLaunched,
  requestPasswordCodeSuccess,
  requestPasswordCodeFailure,
  submitNewPasswordLaunched,
  submitNewPasswordSuccess,
  submitNewPasswordFaliure,
  getUserLaunched,
  getUserSuccess,
  getUserFailure,
  getAllUsersLaunched,
  getAllUsersSuccess,
  getAllUsersFailure,
  updateUserLaunched,
  updateUserSuccess,
  updateUserFailure,
  doPaymentLaunched,
  doPaymentSuccess,
  doPaymentFailure,
  openSnackBar,
  closeSnackBar,
  clearSnackBar,
  openPopup,
  resetPopupStore,
  changePasswordLaunched,
  changePasswordSuccess,
  changePasswordFailure
} = actions;

export default reducer;
