import Immutable from 'immutable';
import { createSlice } from '@reduxjs/toolkit';
import { SEARCH_SKIP } from '../utils/constants';

const initialState = Immutable.Map({
  postContractsLoading: false,
  postContractsError: null,
  updateContractsLoading: false,
  updateContractsError: null,
  contractId: null,
  getContractsLoading: false,
  getContractsError: null,
  contractsList: null,
  postQuotesLoading: false,
  postQuotesError: null,
  cotationId: '',
  getQuotesLoading: false,
  getQuotesError: null,
  cotationData: null,
  getMedicActsLoading: false,
  getMedicActsError: null,
  medicActsData: null,
  getAllContentsLoading: false,
  getAllContentsError: null,
  contentsList: [],
  getContentLoading: false,
  getContentError: null,
  contentData: null,
  contactLoading: false,
  contactError: null,
  getAllQuotesLoading: false,
  allQuotes: null,
  quotePage: 1,
  selectedHourIndex: -1,
  currentQuoteData: null,
  getInvoicesLoading: false,
  invoicesData: null,
  getInvoicesError: null,
  postQuotesSuccess: false,
  getAllContractsByUserLoading: false,
  allContractsByUser: null,
  getAllContractsByUserError: null,
  searchValue: null,
  searchSubCategory: null,
  isLoading: false,
  searchResults: [],
  searchErrorMessage: null,
  searchSkip: SEARCH_SKIP,
  contentTypeEntries: []
});

const { actions, reducer } = createSlice({
  slice: 'store',
  name: 'store',
  initialState,
  reducers: {
    // POSTCONTRACTS
    postContractsLaunched: (state, action) => state
      .set('postContractsLoading', true)
      .set('contractId', null),
    postContractsSuccess: (state, action) => state
      .set('postContractsLoading', false)
      .set('contractId', action.payload.contractId),
    postContractsFailure: (state, action) => state
      .set('postContractsLoading', false)
      .set('contractId', null)
      .set('postContractsError', action.payload),
    // GETCONTRACTS
    getContractsLaunched: (state, action) => state
      .set('getContractsLoading', true)
      .set('contractsList', null),
    getContractsSuccess: (state, action) => state
      .set('getContractsLoading', false)
      .set('contractsList', action.payload),
    getContractsFailure: (state, action) => state
      .set('getContractsLoading', false)
      .set('contractsList', null)
      .set('getContractsError', action.payload),
    // UPDATECONTRACTS
    updateContractsLaunched: (state, action) => state
      .set('updateContractsLoading', true),
    updateContractsSuccess: (state, action) => state
      .set('updateContractsLoading', false),
    updateContractsFailure: (state, action) => state
      .set('updateContractsLoading', false)
      .set('updateContractsError', action.payload),
    // GETINVOICES
    getInvoicesLaunched: (state, action) => state
      .set('getInvoicesLoading', true)
      .set('invoicesData', null),
    getInvoicesSuccess: (state, action) => state
      .set('getInvoicesLoading', false)
      .set('invoicesData', action.payload),
    getInvoicesFailure: (state, action) => state
      .set('getInvoicesLoading', false)
      .set('invoicesData', null)
      .set('getInvoicesError', action.payload),
    // POSTQUOTES
    postQuotesLaunched: (state, action) => state
      .set('postQuotesLoading', true)
      .set('cotationId', null)
      .set('postQuotesSuccess', false),
    postQuotesSuccess: (state, action) => state
      .set('postQuotesLoading', false)
      .set('postQuotesSuccess', true)
      .set('cotationId', action.payload.id),
    postQuotesFailure: (state, action) => state
      .set('postQuotesLoading', false)
      .set('cotationId', null)
      .set('postQuotesError', action.payload),
    // GETQUOTES
    getQuotesLaunched: (state, action) => state
      .set('getQuotesLoading', true)
      .set('cotationData', null),
    getQuotesSuccess: (state, action) => state
      .set('getQuotesLoading', false)
      .set('cotationData', action.payload),
    getQuotesFailure: (state, action) => state
      .set('getQuotesLoading', false)
      .set('cotationData', null)
      .set('getQuotesError', action.payload),
    setQuotesDefault: (state, action) => state
      .set('cotationData', action.payload),
    // GETMEDICACTS
    getMedicActsLaunched: (state, action) => state
      .set('getMedicActsLoading', true)
      .set('medicActsData', null),
    getMedicActsSuccess: (state, action) => state
      .set('getMedicActsLoading', false)
      .set('medicActsData', action.payload),
    getMedicActsFailure: (state, action) => state
      .set('getMedicActsLoading', false)
      .set('medicActsData', null)
      .set('getMedicActsError', action.payload),
    // GETALLCONTENTS
    getAllContentsLaunched: (state, action) => state
      .set('getAllContentsLoading', true)
      .set('contentsList', []),
    getAllContentsSuccess: (state, action) => state
      .set('getAllContentsLoading', false)
      .set('contentsList', action.payload),
    getAllContentsFailure: (state, action) => state
      .set('getAllContentsLoading', false)
      .set('contentsList', [])
      .set('getAllContentsError', action.payload),
    // GETCONTENT
    getContentLaunched: (state, action) => state
      .set('getContentLoading', true)
      .set('contentData', null),
    getContentSuccess: (state, action) => state
      .set('getContentLoading', false)
      .set('contentData', action.payload),
    getContentFailure: (state, action) => state
      .set('getContentLoading', false)
      .set('contentData', null)
      .set('getContentError', action.payload),
    // CONTACT
    contactLaunched: (state, action) => state
      .set('contactLoading', true),
    contactSuccess: (state, action) => state
      .set('contactLoading', false),
    contactFailure: (state, action) => state
      .set('contactLoading', false)
      .set('contactError', action.payload),
    // GET QUOTES
    getAllQuotesLaunched: (state, action) => state
      .set('getAllQuotesLoading', true)
      .set('allQuotes', null),
    getAllQuotesSuccess: (state, action) => state
      .set('getAllQuotesLoading', false)
      .set('allQuotes', action.payload),
    getAllQuotesFailure: (state, action) => state
      .set('getAllQuotesLoading', false)
      .set('allQuotes', null),
    // GET ALL CONTRACTS BY USER
    getAllContractsByUserLaunched: (state, action) => state
      .set('getAllContractsByUserLoading', true)
      .set('getAllContractsByUserError', null),
    getAllContractsByUserSuccess: (state, action) => state
      .set('getAllContractsByUserLoading', false)
      .set('allContractsByUser', action.payload),
    getAllContractsByUserFailure: (state, action) => state
      .set('getAllContractsByUserLoading', false)
      .set('allContractsByUser', null)
      .set('getAllContractsByUserError', action.payload),
    // SEARCH QUOTES (ADMIN PAGE)
    setSearchValue: (state, action) => state
      .set('searchValue', action.payload),
    // QUOTE TOOL STEP
    setQuotePage: (state, action) => state
      .set('quotePage', action.payload),
    // QUOTES - ENABLE CATEGORY SELECTION ON QUOTE STEP 2
    setSelectedHourIndex: (state, action) => state
      .set('selectedHourIndex', action.payload),
    // save current quote data in redux to send between pages
    setCurrentQuoteData: (state, action) => state
      .set('currentQuoteData', action.payload),
    setCotationId: (state, action) => state
      .set('cotationId', action.payload),
    // Search results
    getSearchLaunched: (state, action) => state
      .set('searchValue', action.payload)
      .set('isLoading', true),
    searchSubCategory: (state, action) => state
      .set('searchSubCategory', action.payload),
    getSearchSuccess: (state, action) => state
      .set('isLoading', false)
      .set('searchResults', action.payload),
    getSearchFailure: (state, action) => state
      .set('isLoading', false)
      .set('searchErrorMessage', action.payload),
    // Search results pagination
    setSearchSkip: (state, action) => state
      .set('searchSkip', action.payload),
    // Get A ContentType's entries
    getContentTypeEntriesLaunched: (state, action) => state
      .set('isLoading', true),
    getContentTypeEntriesSuccess: (state, action) => state
      .set('isLoading', false)
      .set('contentTypeEntries', action.payload),
    getContentTypeEntriesFailure: (state, action) => state
      .set('isLoading', false)
      .set('contentTypeEntries', []),
  }
});

export const {
  postContractsLaunched,
  postContractsSuccess,
  postContractsFailure,
  getContractsLaunched,
  getContractsSuccess,
  getContractsFailure,
  updateContractsLaunched,
  updateContractsSuccess,
  updateContractsFailure,
  getInvoicesLaunched,
  getInvoicesSuccess,
  getInvoicesFailure,
  postQuotesLaunched,
  postQuotesSuccess,
  postQuotesFailure,
  getQuotesLaunched,
  getQuotesSuccess,
  getQuotesFailure,
  setQuotesDefault,
  getMedicActsLaunched,
  getMedicActsSuccess,
  getMedicActsFailure,
  getAllContentsLaunched,
  getAllContentsSuccess,
  getAllContentsFailure,
  getContentLaunched,
  getContentSuccess,
  getContentFailure,
  contactLaunched,
  contactSuccess,
  contactFailure,
  getAllQuotesLaunched,
  getAllQuotesSuccess,
  getAllQuotesFailure,
  setSearchValue,
  setQuotePage,
  getAllContractsByUserLaunched,
  getAllContractsByUserSuccess,
  getAllContractsByUserFailure,
  setSelectedHourIndex,
  setCurrentQuoteData,
  setCotationId,
  getSearchLaunched,
  searchSubCategory,
  getSearchSuccess,
  getSearchFailure,
  setSearchSkip,
  getContentTypeEntriesLaunched,
  getContentTypeEntriesSuccess,
  getContentTypeEntriesFailure
} = actions;

export default reducer;
