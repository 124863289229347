import React, { useState, forwardRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, Dialog, Slide, Typography, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import styles from './styles'
import {resetPopupStore} from "../App/reducer";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomAlert = ({ withError, id, message, squareModale, children, setOpenModale, openFromParent, disableBackdrop }) => {
  const classes = styles({ squareModale });
  const dispatch = useDispatch();

  const { popupOpen } = useSelector((state) => ({
    popupOpen: state.getIn(['app', 'popupOpen']),
  }));

  const [open, setOpen] = useState(openFromParent || popupOpen);

  const handleClose = () => {
    setOpen(false);
    dispatch(resetPopupStore())
    if (openFromParent) {
      setOpenModale(false);
      dispatch(resetPopupStore())
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby={id}
        aria-describedby={id}
        classes={{ paper: classes.root }}
        disableBackdropClick={disableBackdrop}
      >
        {children ?
          <Grid container direction="column">
            {children}
          </Grid>
          : <Grid container justify="center">
            <Box m={4}>
              <Typography variant="h5" className={classes.popupMessage}>{message}</Typography>
            </Box>

            {withError ?
              <Grid container justify="center">
                <IconButton onClick={handleClose} classes={{ root: classes.buttonRoot }}>
                  <CancelIcon color="error" className={classes.cancelIcon} />
                </IconButton>
              </Grid>
              :
              <Grid container justify="center">
                <IconButton onClick={handleClose} classes={{ root: classes.buttonRoot }}>
                  <CheckCircleIcon className={classes.checkIcon} />
                </IconButton>
              </Grid>
            }
          </Grid>
        }
      </Dialog>
    </div>
  );
}

export default CustomAlert

CustomAlert.propTypes = {
  withError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  message: PropTypes.string,
  squareModale: PropTypes.bool,
  children: PropTypes.node,
  setOpenModale: PropTypes.func,
  openFromParent: PropTypes.bool,
  disableBackdrop: PropTypes.bool
};

CustomAlert.defaultProps = {
  withError: false,
  squareModale: false,
  setOpenModale: () => { },
  openFromParent: false,
  disableBackdrop: false
};
