import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  dateContainer: {
    marginBottom: 0,
    marginTop: 5,
    '& .MuiOutlinedInput-root': {
      height: 30,
      width: 180,
    },
  },
  date: {
    height: 50,
    width: 240,
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: -10,
      maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 34,
    },
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  redError: {
    color: theme.palette.error.main
  },
  icon: {
    marginRight: 5,
    fontSize: 17
  },
}));