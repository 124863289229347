import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { CustomButton } from '../../Button';
import { CustomSnackBar } from '../../SnackBar';
import styles from '../styles';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import BillingForm from '../BillingForm';

const TransferForm = (props) => {
  const classes = styles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const { doPaymentError, doPaymentLoading } = useSelector((state) => ({
    doPaymentError: state.getIn(['app', 'doPaymentError']),
    doPaymentLoading: state.getIn(['app', 'doPaymentLoading'])
  }));
  const {
    handleSubmit
  } = props;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="row"
        alignItems="flex-start"
        className={classes.sameContainer}
      >
        <BillingForm props={props} />
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.frameContainer}
        >
          <Typography variant="h3" paragraph>Montant de votre adhésion annuelle</Typography>
          <Typography className={classes.typoPrice} paragraph>140€</Typography>
          <Typography variant="h3" paragraph>Veuillez réaliser votre virement sur le compte suivant :</Typography>
          <Typography variant="h3" paragraph>Banque Populaire Nord de France</Typography>
          <Typography variant="h3" paragraph>FR76 1350 7000 4630 6446 7212 905</Typography>
          <Typography variant="h3" paragraph>BIC : CCBPFRPPLIL</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        className={classes.buttonContainer}
      >
        <CustomButton
          variant="contained"
          color="secondary"
          handleClick={() => history.push('/')}
          title="Annuler"
        />
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          handleClick={() => handleSubmit()}
          loading={doPaymentLoading}
          title="Adhérer"
        />
      </Grid>
      {/*<CustomSnackBar message={PaymentErrorMessage} open={open} setOpen={setOpen} />*/}
    </Grid>
  );
};

export default TransferForm;
