import React from 'react';
import { Table, TableBody, TableContainer, Paper } from '@material-ui/core';
import Row from '../Row';
import { sortArrayOfObjectsOnFieldName } from '../../../utils/services/formats';

export const QuotesTable = ({ quotes }) => {
  const formatted_quotes = quotes?.map(quote => {
    return {
      ...quote,
      date: new Date(quote.createdDate).getTime()
    }
  })
  const QUOTES_BY_DATE = sortArrayOfObjectsOnFieldName(formatted_quotes, "date");

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="collapsible table">
          <TableBody>
            {QUOTES_BY_DATE?.map((row, key) => <Row key={key} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QuotesTable;