import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'formik';
import { Grid, Typography, Paper, Box, IconButton, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import theme from '../../../../utils/configureMaterialTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import clsx from 'clsx';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import CustomButton from '../../../Button';
import QuotesCategoriesList from '../QuotesCategoriesList';
import TimeFrame from '../../../Quotes/TimeFrame';
import FirstTimeframeInfo from '../../../Quotes/FirstTimeframeInfo';
import Modale from '../../../Modale';
import QuitQuotationModale from '../../../Modale/QuitQuotationModale';
import { setCotationId, setCurrentQuoteData, setQuotePage, setSelectedHourIndex } from '../../../../store/reducer';
// import { FIRST_TIMEFRAME_KEY } from '../../../../utils/constants';
import { disableAct, renderActCode, selectActOnClick } from '../../../../utils/services/quotes';
import styles from './styles';

export const QuotesForm = ({ listOpen, actsByCategory, values, handleChange, handleSubmit, touched, errors, setFieldTouched, editable, setFieldValue, children, results, filteredActs, ...props }) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const startId = searchParams.get('startId');

  const { postQuotesLoading, selectedHourIndex, cotationData, postQuotesSuccess } = useSelector((state) => ({
    selectedHourIndex: state.getIn(['store', 'selectedHourIndex']),
    postQuotesLoading: state.getIn(['store', 'postQuotesLoading']),
    cotationData: state.getIn(['store', 'cotationData']),
    postQuotesSuccess: state.getIn(['store', 'postQuotesSuccess']),
  }));

  const classes = styles({ listOpen, editable });
  const [open, setOpen] = useState(false);
  const [firstTimeFrameOpen, setFirstTimeFrameOpen] = useState(true);
  // const userHasAlreadyPostedAQuote = localStorage.getItem(FIRST_TIMEFRAME_KEY);
  const [error, setError] = useState();
  const [endQuoteOpen, setEndQuoteOpen] = useState(false);

  const handleAddNewTimeframe = (push) => {
    push({ hourInString: '', acts: [], packageSession: [] });
    dispatch(setSelectedHourIndex(selectedHourIndex + 1))
    // setFieldTouched("medicActs", true);
  }

  const handleEndQuote = () => {
    dispatch(setCotationId(''));
    dispatch(setCurrentQuoteData(null));
    dispatch(setQuotePage(1));
    history.push('/cotation')
  }

  useEffect(() => {
    dispatch(setCurrentQuoteData(values));
  }, [values])

  useEffect(() => {
    if (Array.isArray(errors?.medicActs)) {
      if (errors?.medicActs?.find(item => item?.acts || item?.packageSession || item?.hourInString)) {
        setError(errors?.medicActs.find(item => item?.hourInString)?.hourInString || errors?.medicActs?.find(item => item?.acts)?.acts || errors?.medicActs?.find(item => item?.packageSession)?.packageSession)
      }
    } else {
      setError(errors?.medicActs);
    }
  }, [errors])

  const handleTimeframeRemove = (remove) => {
    remove(selectedHourIndex);
    dispatch(setSelectedHourIndex(selectedHourIndex - 1));
    setOpen(false);
  }

  const quotes = editable ? values?.medicActs : cotationData?.medicActs;

  // reset the form values when coming from the the summary page
  useEffect(() => {
    if (postQuotesSuccess && !startId) {
      values && setFieldValue("medicActs", '')
      dispatch(setSelectedHourIndex(-1))
    } else {
      values && setFieldValue("medicActs", values?.medicActs || '')
    }
  }, [postQuotesSuccess, setFieldValue]);

  const editQuote = () => {
    dispatch(setQuotePage(1))
    history.push(`/cotation?startId=${id}`)
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item container xs={11} sm={editable ? 5 : 3} md={editable ? 5 : 3} className={classes.actsSelectionWrapper}>
            {editable && <Autocomplete
              id="acts-searchbar"
              options={filteredActs}
              groupBy={(filteredActs) => `${filteredActs?.type} - ${filteredActs?.chapter.split(':')[0]}`}
              getOptionLabel={(filteredActs) => `${filteredActs?.medicalAct} ${renderActCode(filteredActs)}`}
              renderInput={(params) => <TextField {...params} label="Rechercher un acte infirmier NGAP" variant="outlined" />}
              disabled={selectedHourIndex < 0}
              disableCloseOnSelect
              noOptionsText={"Aucun acte ne correspond à votre recherche"}
              getOptionDisabled={option => disableAct(option, values, selectedHourIndex)}
              classes={{ listbox: classes.listbox, paper: classes.searchPaper, root: classes.searchbar, option: classes.option }}

              // Customize the searchbar dropdown's options
              renderOption={(option) => {
                return (
                  option.isMultiple === "TRUE"
                    ? (
                      <button key={option.act}
                        onClick={() => selectActOnClick({ act: option, values, selectedHourIndex, setFieldValue })}
                        className={classes.clickableAct}
                        type="button"
                      >
                        <div className={classes.iconWrapper}>
                          <FontAwesomeIcon icon={faPlusSquare} className={classes.addAct} />
                        </div>
                        <Typography>{option.medicalAct} <p className={classes.bold}>{renderActCode(option)}</p></Typography>
                      </button>
                    ) : (
                      <>
                        <Checkbox
                          className={classes.checkbox}
                          onChange={handleChange}
                          name={option.isPackage === "TRUE" ? `medicActs.${selectedHourIndex}.packageSession` : `medicActs.${selectedHourIndex}.acts`}
                          value={option.id}
                          disableRipple
                          checked={values.medicActs[selectedHourIndex]?.acts?.indexOf(option.id) > -1 || values.medicActs[selectedHourIndex]?.packageSession?.indexOf(option.id) > -1}
                          disabled={disableAct(option, values, selectedHourIndex)}
                        />
                        <p className={option.isMultiple === 'FALSE' && 'checkbox-label'}>{option.medicalAct} <p className={classes.bold}>{renderActCode(option)}</p></p>
                      </>
                    )
                )
              }}
            />}
            {editable && listOpen ? (
              <QuotesCategoriesList
                list={actsByCategory}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                filteredActs={filteredActs}
              />
            ) : children}
          </Grid>
          <Grid item container xs={11} sm={editable ? 7 : 11} md={editable ? 7 : 6} className={classes.actsSummaryWrapper}>
            <Grid item container justify="center" alignItems="center" alignContent="center">
              <Paper className={classes.paper}>
                <Grid item container justify="center" alignContent="center" className={classes.quotesTitleWrapper}>
                  <Typography className={classes.quotesTitle}>SAISIE DES ACTES DE LA JOURNÉE</Typography>
                </Grid>

                <Grid container direction="column" className={classes.quotesFormWrapper}>
                  <Grid container direction="column">

                    {/* name => Formik's name for the array of medical acts */}
                    <FieldArray name="medicActs">
                      {({ insert, remove, push }) => (
                        <>
                          {/* Select new timeframe */}
                          <Grid container alignItems="center" className={classes.addNewTimeFrameWrapper}>
                            <button
                              type="button"
                              className={classes.addTimeButton}
                              onClick={() => editable && handleAddNewTimeframe(push)}
                              title="Ajouter un nouvel horaire"
                            >
                              <Typography className={classes.subtitle}>Heure :</Typography>
                              {editable && (
                                <span className={classes.addButtonIcon}>
                                  <FontAwesomeIcon icon={faPlusCircle} className={classes.addButton} />
                                </span>
                              )}
                            </button>
                            {firstTimeFrameOpen && editable && (
                              <FirstTimeframeInfo setFirstTimeFrameOpen={setFirstTimeFrameOpen} />
                            )}
                          </Grid>

                          {/* Timeframes List */}
                          <div className={classes.timeList}>
                            {quotes?.length > 0 && quotes?.map((_, index) => {
                              return (
                                <div key={index} className={classes.timeframeWrapper}>
                                  {editable && (
                                    <IconButton
                                      className={clsx(classes.addButtonIcon, classes.removeButtonIcon)}
                                      onClick={() => setOpen(true)}
                                      disabled={selectedHourIndex !== index}
                                      type="button"
                                    >
                                      <FontAwesomeIcon icon={faTimesCircle} className={classes.removeIcon} />
                                    </IconButton>
                                  )}
                                  <TimeFrame
                                    index={index}
                                    values={editable ? values : cotationData}
                                    handleChange={handleChange}
                                    editable={editable}
                                    setFieldValue={setFieldValue}
                                    results={results}
                                    {...props}
                                  />
                                  {open && <Modale openFromParent={open} id={`remove timeframe ${index}`} disableBackdrop>
                                    <Box m={3}>
                                      <Typography variant="h5">Êtes-vous sûr de vouloir supprimer cet horaire et son contenu ?</Typography>
                                      <Box mt={3}>
                                        <Grid container justify="space-evenly">
                                          <CustomButton handleClick={() => handleTimeframeRemove(remove)} title="Ok, supprimer" variant="contained" />
                                          <CustomButton handleClick={() => setOpen(false)} title="Non, garder" />
                                        </Grid>
                                      </Box>
                                    </Box>
                                  </Modale>}
                                </div>
                              )
                            })}
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>

                {editable && <Grid container justify="flex-end" alignItems="center">
                  <p className={classes.error}>{error}</p>
                  <Box mx={4}>
                    <CustomButton
                      variant="contained"
                      title="Valider"
                      type="submit"
                      loading={postQuotesLoading}
                    />
                  </Box>
                </Grid>}
              </Paper>
            </Grid>
            {results && (
              <Grid container justify="space-evenly" alignItems="center">
                <CustomButton title="Modifier mes actes saisis" handleClick={editQuote} variant="contained" />
                {cotationData?.link && <a
                  href={cotationData?.link}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  edge="end"
                  aria-label="download"
                  className={classes.downloadButton}>
                  <Box mx={2}>
                    <Typography className={classes.downloadText}>Télécharger le pdf</Typography>
                  </Box>
                  <FontAwesomeIcon icon={faArrowAltCircleDown} color={theme.palette.secondary.main} size="2x" />
                </a>}
                <CustomButton title="Nouvelle saisie" handleClick={() => setEndQuoteOpen(true)} variant="contained" />
              </Grid>
            )}
            {endQuoteOpen && <QuitQuotationModale
              endQuoteOpen={endQuoteOpen} setEndQuoteOpen={setEndQuoteOpen} handleEndQuote={handleEndQuote}
            />}
          </Grid>
        </Grid>
      </form>
    </>
  );
};
export default QuotesForm;