import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    minHeight: '70vh'
  },
  noContent: {
    textAlign: "center"
  },
  description: {
    width: "80%",
    marginTop: 100,
    marginBottom: 100,
    fontSize: "clamp(16px, 3vw, 23px)",
    '@media (max-width: 959px)': {
      marginTop: 10,
      marginBottom: 10
    }  
  }
}));