import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
  },
  headerWrapper: {
    padding: theme.spacing(1)
  },
  notAuthenticated: {
    height: 96
  },
  firstContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    paddingLeft: '5%',
    paddingRight: '1%',
    height: 96
  },
  logoWrapper: {
    cursor: "pointer"
  },
  firstContainerMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    height: 96
  },
  searchBarMobile: {
    width: '50%',
    boxShadow: '0px 3px 6px rgb(0 0 0 / 19% )'
  },
  flexContainer: {
    display: 'flex',
    alignItems: "center"
  },
  rightContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  title: {
    fontSize: 'calc(1rem + .2vw)',
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: '#C45824',
    '@media (max-width:1200px)': {
      fontSize: 20
    },
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    },
  },
  secondContainer: {
    background: '#fff',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    height: 48,
    borderTop: "1px solid grey",
    width: '100%',
  },
  menu: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    height: 47,
    maxWidth: 1500
  },
  divider: {
    backgroundColor: theme.palette.neutral.main,
    width: 2,
    height: 60,
    marginLeft: 20,
    marginRight: 30
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  button: {
    fontSize: 18,
    textTransform: 'none',
    color: '#000',
    borderRadius: 0,
    display: 'block',
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },
    backgroundColor: 'white',
    height: 47,
  },
  buttonText: {
    display: 'inline-block',
    padding: "0 1rem",
  },
  navLink: {
    fontSize: 20,
    color: 'black',
    '@media (max-width: 1630px)': {
      fontSize: 15,
    },
  },
  mailDown: {
    display: 'none',
    color: 'black',
    '@media (max-width: 1630px)': {
      display: 'flex',
      fontSize: 15,

    },
  },
  mailUp: {
    display: 'flex',
    color: 'black',
    '@media (max-width: 1630px)': {
      display: 'none'
    },
  },
  green: {
    color: theme.palette.primary.main
  },
  orange: {
    color: theme.palette.secondary.main,
    border: "none",
    background: "none",
    textAlign: "left",
    textDecoration: "underline",
    padding: ".3rem 0",
    cursor: "pointer"
  },
  icon: {
    fontSize: 55
  },
  iconButton: {
    marginLeft: 5,
    marginRight: 5
  },
  img: {
    height: 80,
    '@media (max-width: 959px)': {
      height: 40
    },
  },
  homeIcon: {
    marginRight: theme.spacing(1),
    cursor: "pointer"
  },
  search: {
    position: 'relative',
    borderRadius: 0,
    color: "#000000",
    boxShadow: `0px 3px 6px ${fade(theme.palette.primary.main, .19)}`,
    backgroundColor: "fade(theme.palette.primary.main, 0)",
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0),
    },
    marginLeft: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
      width: '20vw',
    },
  },
}));
