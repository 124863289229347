import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  main: {
    '& path': {
      color: theme.palette.primary.mainBGColor
    }
  },
  secondary: {
    '& path': {
      color: theme.palette.secondary.mainBGColor
    }
  },
  black: {
    '& path': {
      color: ({ disabled }) => disabled ? fade(theme.palette.primary.main, .6) : theme.palette.primary.main
    }
  },
  white: {
    '& path': {
      color: theme.palette.secondary.main
    }
  }
}));