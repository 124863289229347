import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { CustomButton } from '../../Button';
import styles from '../styles';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import BillingForm from '../BillingForm';

const CheckForm = (props) => {
  const classes = styles();
  const history = useHistory();

  const { doPaymentError, doPaymentLoading } = useSelector((state) => ({
    doPaymentError: state.getIn(['app', 'doPaymentError']),
    doPaymentLoading: state.getIn(['app', 'doPaymentLoading'])
  }));
  const {
    handleSubmit
  } = props;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="row"
        alignItems="flex-start"
        className={classes.sameContainer}
      >
        <BillingForm props={props} />
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.frameContainer}
        >
          <Typography variant="h3" paragraph>Montant de votre adhésion annuelle</Typography>
          <Typography className={classes.typoPrice} paragraph>140€</Typography>
          <Typography variant="h3" paragraph>Veuillez envoyer votre chèque à l'adresse suivante :</Typography>
          <Typography variant="h3" paragraph>SAIIL - Syndicat Autonome des Infirmières et Infirmiers Libéraux</Typography>
          <Typography variant="h3" paragraph>6 rue Delannoy</Typography>
          <Typography variant="h3" paragraph>BAT A - App 48</Typography>
          <Typography variant="h3" paragraph>59160 LOMME</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        className={classes.buttonContainer}
      >
        <CustomButton
          variant="contained"
          color="secondary"
          handleClick={() => history.push('/')}
          title="Annuler"
        />
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          handleClick={() => handleSubmit()}
          loading={doPaymentLoading}
          title="Adhérer"
        />
      </Grid>
    </Grid>
  );
};

export default CheckForm;
