import { fade, makeStyles } from '@material-ui/core/styles/';

export default makeStyles((theme) => ({
  firstTimeFrameZone: {
    borderRadius: 16,
    background: `${fade(theme.palette.secondary.main, .2)}`,
    padding: theme.spacing(1, 1.5),
    position: 'relative'
  },
  arrowLeft: {
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: `10px solid ${fade(theme.palette.secondary.main, .2)}`,
    position: 'absolute',
    top: '35%',
    left: -10
  },
  firstTimeFrameText: {
    fontSize: 14
  },
  firstTimeFrameButton: {
    background: 'none',
    border: '1px solid black',
    borderRadius: 10,
    padding: theme.spacing(.3, 1),
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-1px)'
    }
  }
}));