import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  formContainer: {
    width: '90%',
    margin: 'auto',
    '@media (min-width: 800px)': {
      width: '70%',
    }
  },
  buttonsWrapper: {
    margin: `${theme.spacing(3)}px 0`,
    justifyContent: 'center',
    [theme.breakpoints.up("sm")]: {
      justifyContent: 'space-between'
    }
  },
  buttonRoot: {
    padding: 0
  },
  closeButtonWrapper: {
    margin: `0 auto ${theme.spacing(3)}px`
  },
  icon: {
    fontSize: 50,
    color: theme.palette.primary.main
  },
}));
