import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { CustomButton } from '../Button';
import { CustomTextField } from '../Inputs/CustomTextField';
import { CustomPasswordField } from '../Inputs/CustomPasswordField';
import { CustomNumberField } from '../Inputs/CustomNumberField';
import styles from '../../utils/styles';

const SignUpForm = (props) => {
  const classes = styles();
  const { t } = useTranslation();
  const { signupErrorMessage, signupLoading } = useSelector((state) => ({
    signupErrorMessage: state.getIn(['app', 'signupErrorMessage']),
    signupLoading: state.getIn(['app', 'signupLoading'])
  }));
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue
  } = props;
  const { lastName, firstName, email, phone, password, confirmPassword } = values;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item className={classes.fieldWrapper}>
        <CustomTextField
          name="lastName"
          value={lastName}
          onBlur={handleBlur}
          onChange={handleChange}
          label={'Nom'}
          redError
          error={!!touched.lastName && !!errors.lastName}
          helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <CustomTextField
          name="firstName"
          value={firstName}
          onBlur={handleBlur}
          onChange={handleChange}
          label={'Prénom'}
          redError
          error={!!touched.firstName && !!errors.firstName}
          helperText={touched.firstName && errors.firstName ? errors.firstName : ''}
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <CustomTextField
          name="email"
          type="email"
          value={email}
          onBlur={handleBlur}
          onChange={handleChange}
          label={t('signUpForm.email')}
          redError
          error={!!touched.email && !!errors.email}
          helperText={touched.email && errors.email ? errors.email : ''}
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <CustomNumberField
          name="phone"
          id="phone"
          value={phone}
          onBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          formik
          label={"Numéro de téléphone"}
          redError={!!touched.phone && !!errors.phone}
          error={!!touched.phone && !!errors.phone}
          helperText={touched.phone && errors.phone ? t(errors.phone) : ''}
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <CustomPasswordField
          name="password"
          id="password"
          value={password}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label={t('signUpForm.password')}
          redError
          error={!!touched.password && !!errors.password}
          helperText={touched.password && errors.password ? errors.password : ''}
        />
      </Grid>
      <Grid item className={classes.fieldWrapper}>
        <CustomPasswordField
          name="confirmPassword"
          id="confirmPassword"
          value={confirmPassword}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label={t('signUpForm.confirmPassword')}
          redError
          error={!!touched.confirmPassword && !!errors.confirmPassword}
          helperText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ''}
        />
      </Grid>
      <CustomButton
        type="submit"
        variant="contained"
        color="primary"
        handleClick={() => handleSubmit()}
        loading={signupLoading}
        title="Créer"
      />
    </Grid>
  );
};

export default SignUpForm;
