import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth: 345,
  },
  img: {
    margin: '1.5rem',
    height: 200,
    width:300
  },
  description: {
    fontSize: 18,
    width: "80%",
    height: ({ description }) => (description ? 54 : 144),
    paddingRight: 15,
    paddingLeft: 15,
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: 1.2,
    height: ({ title }) => (title ? 54 : 144)
  },
  buttonContainer: {
    marginTop: 24,
    marginBottom: 24,
    '@media (max-width: 959px)': {
      marginTop: 0
    },
  }
}));
