import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import CustomAlert from '..';
import CustomButton from '../../Button';

const QuitQuotationModale = ({ endQuoteOpen, setEndQuoteOpen, handleEndQuote, fromQuotationNav }) => {

  const { cotationData } = useSelector((state) => ({
    cotationData: state.getIn(['store', 'cotationData']),
  }));

  return (
    <CustomAlert openFromParent={endQuoteOpen} id={`end quote`} disableBackdrop>
      <Box m={4}>
        {fromQuotationNav
          ? <Typography variant="h5">Vous êtes sur le point de quitter l'outil de cotations.</Typography>
          : <Typography variant="h5">Vous êtes sur le point de retourner à l'écran de saisie de contexte de soins.</Typography>
        }

        {cotationData?.link && (<Box my={2}>
          <Typography variant="h5">Pensez à télécharger votre cotation au format pdf.</Typography>
        </Box>)}
        <Box mt={3}>
          <Grid container justify="space-evenly">
            <CustomButton handleClick={() => setEndQuoteOpen(false)} title="Non, rester sur la page" />
            <CustomButton handleClick={() => handleEndQuote()} title={`Ok, retourner ${fromQuotationNav ? "à l'accueil" : "à l'écran de contexte"}`} variant="contained" />
          </Grid>
        </Box>
      </Box>
    </CustomAlert>
  );
};
export default QuitQuotationModale;

QuitQuotationModale.propTypes = {
  endQuoteOpen: PropTypes.bool.isRequired,
  setEndQuoteOpen: PropTypes.func.isRequired,
  handleEndQuote: PropTypes.func.isRequired,
  fromQuotationNav: PropTypes.bool
};

QuitQuotationModale.defaultProps = {
  fromQuotationNav: false
};