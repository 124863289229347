import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    height: '80vh',
    minHeight: 940
  },
  formContainer: {
    marginTop: 50,
    width: '80%',
    '@media(max-width:850px)': {
      marginTop: 10
    }
  },
  mandatory: {
    fontFamily: 'Roboto',
    fontSize: 13,
    color: '#fff',
    width: '92%',
    maxWidth: 1120
  },
  buttonContainer: {
    width: '96%',
    maxWidth: 1120
  },
  title: {
    color: 'white',
    textTransform: "uppercase",
    fontSize: '35px',
    '@media(max-width:600px)': {
      textAlign: 'center',
      fontSize: '1.5rem',
    }
  },
  titleContainer: {
    minHeight: 104,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    height: "10%",
    '@media(max-width:600px)': {
      height: "15%",
    }
  },
  logo: {
    width: 60,
    paddingRight: 10,
    '@media(max-width:472px)': {
      marginBottom: 10,
    }
  }
}));
