import React from 'react';
import Grid from '@material-ui/core/Grid';
import Title from '../Title';

import styles from './styles';

export const TitleBanner = ({ img, title, color, ...props }) => {
  const classes = styles();

  return (
    <Grid item container {...props}>
      {img && <img src={img} alt="bannerPhoto" className={classes.img} />}
      <Title title={title} color={color} />
    </Grid>
  );
};

export default TitleBanner;
