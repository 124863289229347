import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Paper, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'

import styles from './styles';
import { setCurrentQuoteData } from '../../../../store/reducer';
import { disableAct, renderActCode, selectActOnClick } from '../../../../utils/services/quotes';
import { truncateText } from '../../../../utils/services/formats';

const QuotesCategoriesList = ({ list, values, handleChange, setFieldValue }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const LIST_TITLE = list?.find(x => x.type)?.type?.toUpperCase();

  const { selectedHourIndex } = useSelector((state) => ({
    selectedHourIndex: state.getIn(['store', 'selectedHourIndex']),
  }));

  useEffect(() => {
    dispatch(setCurrentQuoteData(values));
  }, [values])

  const handleDisabledAct = (act) => {
    return disableAct(act, values, selectedHourIndex);
  };
  
  return (
    <Paper className={classes.paper}>
      <Grid item container justify="center" alignContent="center" className={classes.titleWrapper}>
        <Typography className={classes.title}>{truncateText(LIST_TITLE, 50)}</Typography>
      </Grid>
      <div className={classes.contentWrapper}>
        <Grid className={classes.content}>
        {/* MedicActs selection sections with icons */}
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {list?.map((act, key) => {
                return (
                  act.isMultiple === "TRUE"
                  ? <button key={key}
                      onClick={() => selectActOnClick({act, values, selectedHourIndex, setFieldValue})}
                      className={classes.clickableAct}
                      disabled={handleDisabledAct(act)}
                      type="button"
                    >
                      <div className={classes.iconWrapper}>
                        <FontAwesomeIcon icon={faPlusSquare} className={classes.addAct} />
                      </div>
                      <Typography>{act.medicalAct} <span className={classes.bold}>{renderActCode(act)}</span></Typography>
                    </button>
                    : (
                      <Box key={key} my={1}>
                        <FormControlLabel
                          control={<Checkbox
                            className={classes.checkbox}
                            name={act.isPackage === "TRUE" ? `medicActs.${selectedHourIndex}.packageSession`: `medicActs.${selectedHourIndex}.acts`}
                            value={act.id}
                            onChange={handleChange}
                            checked={values.medicActs[selectedHourIndex]?.acts?.indexOf(act.id) > -1 || values.medicActs[selectedHourIndex]?.packageSession?.indexOf(act.id) > -1}
                            disabled={handleDisabledAct(act)}
                          />}
                          label={<Typography>{act.medicalAct} <span className={classes.bold}>{renderActCode(act)}</span></Typography>}
                        />
                      </Box>
                    )
                )})}
            </FormGroup>
          </FormControl>

        </Grid>
      </div>
    </Paper>
  )
}

export default QuotesCategoriesList;

QuotesCategoriesList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    medicalAct: PropTypes.string.isRequired
  })).isRequired,
  values: PropTypes.shape({
    acts: PropTypes.arrayOf(PropTypes.string),
    packageSession: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  handleChange: PropTypes.func.isRequired
};