import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  connectionDiv: {
    backgroundImage: 'linear-gradient(to left, #18C8B7, #31CAA8)',
    height: '100vh',
    minHeight: 800
  },
  titleConnection: {
    color: '#ffffff',
    fontStyle: 'italic',
    fontSize: 100,
    marginBottom: 20
  },
  titleFormConnection: {
    fontSize: 30,
    color: '#ffffff',
    margin: 30,
    fontWeight: 'bold'
  },
  navlink: {
    color: 'white',
  },
  mainContainerPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  mainContainerSecondary: {
    backgroundColor: theme.palette.secondary.main
  },
  imgContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      display: 'flex',
      overflow: 'hidden'
    },
  },
  img: {
    height: '75vh',
    minHeight: 600
  },
  formContainer: {
    height: '75vh',
    minHeight: 600,
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  formSignUpContainer: {
    height: '75vh',
    minHeight: 705,
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  fieldWrapper: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    }
  }
}));
