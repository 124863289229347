import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@material-ui/core';
import styles from './styles';

export default function Loader({ loaderColor }) {
  const classes = styles({loaderColor});

  return (
    <Grid item container justify="center" alignItems="center" className={classes.container}>
      <CircularProgress size={80} classes={{ root: classes.loaderColor }}/>
    </Grid>
  );
}


Loader.propTypes = {
  loaderColor: PropTypes.string
};

Loader.defaultProps = {
  loaderColor: "primary"
};