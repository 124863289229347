import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styles from './styles';
import { getMedicActsLaunched } from '../../../store/reducer';
import { formatTimestampToDateWithDashes, switchAdminData, formatDateToFrenchFormat, roundUp } from '../../../utils/services/formats';

const Row = ({ row }) => {
  const { medicActsData } = useSelector((state) => ({
    medicActsData: state.getIn(['store', 'medicActsData'])
  }));

  const [open, setOpen] = useState(false);
  const [actIds, setActsIds] = useState([]);
  const [actsLabels, setActsLabels] = useState([]);
  const dispatch = useDispatch();
  const classes = styles();

  useEffect(() => {
    !medicActsData && dispatch(getMedicActsLaunched())
  }, [medicActsData])

  useEffect(() => {
    let actsArray = [];
    if (row) {
      row?.medicActs?.map((act) => {
        act?.acts?.map((item) => {
          actsArray.push(item)
        })
      })
    }
    setActsIds(actsArray);
  }, [row])

  useEffect(() => {
    let acts = [];
    if (actIds) {
      actIds?.map((actID) => {
        const act = medicActsData?.find(actData => actData.id === actID);
        acts.push({
          label: act?.medicalAct,
        });
      })
    }
    setActsLabels(acts)
  }, [medicActsData, actIds]);

  const DATE_EXTRACTED_FROM_ISO = formatTimestampToDateWithDashes(row?.createdDate);
  const FRENCH_FORMATTED_DATE = formatDateToFrenchFormat(DATE_EXTRACTED_FROM_ISO);
  return (
    <>
      <TableRow className={classes.root} onClick={() => setOpen(!open)}>
        <TableCell className={classes.tableHead}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span className={classes.date}>
            Le <span className={classes.bold}>{FRENCH_FORMATTED_DATE}</span>
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.noPadding} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.bold}>Heure</TableCell>
                    <TableCell className={classes.bold}>Lieu</TableCell>
                    <TableCell className={classes.bold}>Jour</TableCell>
                    <TableCell className={classes.bold}>Horaire</TableCell>
                    <TableCell className={clsx(classes.bold, classes.colWidth)}>Type de patient</TableCell>
                    <TableCell className={classes.bold}>Age</TableCell>
                    <TableCell className={classes.bold}>Genre</TableCell>
                    <TableCell className={clsx(classes.bold, classes.colWidth)}>Actes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.medicActs?.map((act, key) => (
                    <React.Fragment key={key}>
                      <TableRow>
                        <TableCell component="th" scope="row" className={classes.smallColumn}>{act?.hourInString}</TableCell>
                        <TableCell className={classes.smallColumn}>{switchAdminData(row?.context?.location)}</TableCell>
                        <TableCell className={classes.smallColumn}>{switchAdminData(row?.context?.day)}</TableCell>
                        <TableCell className={classes.smallColumn}>{switchAdminData(act?.hours)}</TableCell>
                        <TableCell className={classes.colWidth}>{row?.context?.patientType?.map((type, key) => <span key={key}>{switchAdminData(type)}{key === row?.context?.patientType?.length - 1 ? null : ', '}</span>)}</TableCell>
                        <TableCell className={classes.smallColumn}>{row?.context?.age}</TableCell>
                        <TableCell className={classes.smallColumn}>{switchAdminData(row?.context?.sex)}</TableCell>
                        <TableCell className={classes.colWidth}>{actsLabels?.map((item, key) => <span key={key}>{item.label}{key === act?.acts?.length - 1 ? null : <span className={classes.plus}> + </span>}</span>)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={8} className={classes.noBorder}>
                          <Box mx={1} my={4}>
                            <Typography align="right" className={classes.bold}>{act?.results?.litteral}, soit {roundUp(act?.results?.number)}€ + IK</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Row;