import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { TitleBanner } from '../../components/TitleBanner';
import CustomButton from '../../components/Button';
import Loader from '../../components/Loader';
import RichTextRenderer from '../../components/RichTextRenderer';
import { ShareButtons } from '../../components/ShareButtons';
import GobackButton from '../../components/GoBackButton';

import { contactLaunched, getContentLaunched } from '../../store/reducer';
import { FORMATION } from '../../utils/constants';
import { addProtocol, formatDateToFrenchFormat, formatTimestampToDateWithDashes, formatHour } from '../../utils/services/formats';
import defaultPic from "../../assets/image/news.jpg";
import styles from './style';
import PastSessionContent from '../../components/TrainingSessions/PastSessionContent';

export const NewPage = ({ location }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { contentData, getContentLoading, contactLoading, user } = useSelector((state) => (
    {
      contentData: state.getIn(['store', 'contentData']),
      getContentLoading: state.getIn(['store', 'getContentLoading']),
      contactLoading: state.getIn(['store', 'contactLoading']),
      user: state.getIn(['app', 'userDynamo', 'user']),
    }
  ));
  const createdAt = contentData && new Date(contentData.createdAt);

  const [contentType, setContentType] = useState(null);
  useEffect(() => {
    dispatch(getContentLaunched({ contentId: id }));
  }, []);

  useEffect(() => {
    let contentFields = contentData?.fields;
    // if data has a property "name", we are on a training session details page
    if (contentData && Object.prototype.hasOwnProperty.call(contentFields, "name")) {
      setContentType(FORMATION)
    } else {
      setContentType(null)
    }
  }, [contentData])


  const TITLE = contentData?.fields?.titre || contentData?.fields?.title;
  const localizedDate = formatDateToFrenchFormat(contentData?.fields?.date);
  const DATE = localizedDate || `${createdAt?.getDate()}/${createdAt?.getMonth() + 1}/${createdAt?.getFullYear()}`;
  const dateFormation = DATE + " à " + formatHour(contentData?.fields.date);
  const NOW = formatTimestampToDateWithDashes(Date.now());
  const PAST_SESSION = contentData?.fields?.date <= NOW;
  const IMAGE_DETAILS = contentData?.fields?.image?.fields?.file;
  const socialTitle = TITLE;
  const slug = contentData?.fields?.slug;
  const url = `${addProtocol(process.env.REACT_APP_URL_PUBLIC_SITE)}actualites/${slug}`

  const sendSessionSignupEmail = () => {
    const infos = {
      link: contentData?.fields?.link,
      title: contentData?.fields?.title,
      date: new Date(contentData?.fields?.date),
      userId: user?.id
    }
    dispatch(contactLaunched({ type: 'FORMATION_MESSAGE', infos }))
  }

  return (
    <Grid container className={classes.wrapper}>
      {
        !getContentLoading && contentData
          ? (
            <Grid key={id} item container direction="column" className={classes.container}>
              <TitleBanner title={TITLE} color="secondary" />
              <Grid item container justify="center">
                <Grid className={classes.articleContainer}>
                  <img src={IMAGE_DETAILS.url || defaultPic} alt={IMAGE_DETAILS.title} className={classes.img} />
                  <Grid item container justify={contentType === FORMATION ? "space-between" : "flex-end"} className={classes.subTitleContainer}>
                    {
                      contentType === FORMATION ? (
                        <Typography variant="h5">
                          Le {dateFormation}
                        </Typography>
                      ) :
                        <Typography variant="h5">
                          Le {DATE}
                        </Typography>
                    }
                    {contentType === FORMATION
                      && (
                        <Typography variant="h5" className={classes.underline}>
                          Par {contentData.fields.name}
                        </Typography>
                      )
                    }
                  </Grid>
                  <RichTextRenderer details={contentData.fields.article || contentData.fields.description} />
                  {contentType === FORMATION
                    ? (
                      PAST_SESSION
                        ? (
                          <PastSessionContent content={contentData.fields} />
                        ) : (
                          <Grid container className={classes.buttonsWrapper}>
                            <Box className={classes.button}>
                              <GobackButton />
                            </Box>
                            <Box className={classes.button}>
                              <CustomButton title="Je m'inscris" variant="contained"
                                handleClick={() => sendSessionSignupEmail()}
                                color="secondary"
                                loading={contactLoading} />
                            </Box>
                            <Box className={classes.button}>
                              <a href={addProtocol(contentData.fields.link, 'https')} rel="noopener noreferrer" target="_blank" className={classes.buttonLink}>Je me connecte</a>
                            </Box>
                          </Grid>
                        )
                    )
                    :
                    <>
                      <ShareButtons
                        title={socialTitle}
                        url={url}
                      />
                      <GobackButton />
                    </>
                  }
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" className={classes.wrapper}>
              <Loader />
            </Grid>
          )
      }
    </Grid >
  );
};

export default NewPage;
