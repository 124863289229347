import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  rootContext: {
    maxWidth: 1000,
    height: '100%',
    padding: '100px 0',
  },
  infoButton: {
    color: "#000"
  },
  buttonIcon: {
    padding: 1,

    marginLeft:theme.spacing(-2),
    marginRight:theme.spacing(5),

    color: theme.palette.secondary.main

  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    width: 500
  },
  firstPaper: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '90%',
    borderRadius: 44,
    padding: theme.spacing(5, 2, 1, 2),
    margin: theme.spacing(2)
  },
  secondPaper: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '90%',
    borderRadius: 44,
    padding: theme.spacing(5, 2, 1, 2),
    margin: theme.spacing(2)
  },
  formControlLabelRoot: {
    alignItems: 'flex-start'
  },
  radioLabel: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#fcfcfb',
    borderRadius: 5,
    fontFamily: 'Roboto',
    fontSize: 'clamp(1rem, 2.5vw, 25px)',
    margin: 10,
    alignItems: 'flex-start'
  },
  checkIcon: {
    color: '#000'
  },
  radioColumn: {
    width: '80%',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    }
  },
  date: {
    height: 110
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff'
  },
  icon: {
    marginRight: 5
  },
  radioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 100
  },
  redError: {
    color: theme.palette.error.main
  },
  flexColumn: {
    [theme.breakpoints.down("xs")] : {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  typesWrapper: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 0, 0, 10),
    }
  },
  radioBox: {
    height: 50,
    [theme.breakpoints.up("sm")]: {
      height: 80,
    }
  },
  checkboxLabel: {
    margin: theme.spacing(0, 2),
  }
}));
