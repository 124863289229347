import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  wrapper: {
    minHeight: '79vh',
    padding: theme.spacing(3)
  },
  emailAddress: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  },
  subtitle: {
    fontSize: 'clamp(.9rem, 2.5vw, 20px)'
  }
}));
