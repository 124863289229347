import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import qualityImage from '../../assets/image/qualityTraceability.jpg';
import styles from './style';
import { getAllContentsLaunched } from '../../store/reducer';
import { TitleBanner } from '../../components/TitleBanner';
import Loader from '../../components/Loader';
import DownloadCard from '../../components/DownloadCard';
import DocumentCategoryIcon from '../../components/DocumentCategoryIcon';
import Subtitle from '../../components/Subtitle';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQueryParams(qName) {
  return (new URLSearchParams(useLocation().search)).get(qName);
}

export const TraceabilitiesPage = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { contentsList, getAllContentsLoading } = useSelector((state) => ({
    contentsList: state.getIn(['store', 'contentsList']),
    getAllContentsLoading: state.getIn(['store', 'getAllContentsLoading']),
  }));

  useEffect(() => {
    dispatch(getAllContentsLaunched({ contentType: 'TRACABILITY' }));
  }, []);

  const id = useQueryParams("id");

  return (
    <Grid item container direction="column" className={classes.container}>
      <TitleBanner title="QUALITÉ ET SÉCURITÉ DES SOINS" img={qualityImage} color="secondary" />
      <Subtitle text={"La profession infirmière s’investit depuis longtemps sur la sécurité des patients et la qualité des soins.\nSelon l’OMS, la qualité des soins est : « une démarche qui doit permettre de garantir à chaque patient la combinaison d’actes diagnostiques et thérapeutiques qui lui assurera le meilleur résultat en terme de santé, conformément à l’état actuel de la science médicale, au meilleur coût pour un même résultat, au moindre risque iatrogène et pour sa plus grande satisfaction en termes de procédures, de résultats et de contacts humains à l’intérieur du système de soins »"} />
      <Grid item container direction="row" alignContent="center" justify="center">
        {
          !getAllContentsLoading && contentsList && Array.isArray(contentsList)
            ? contentsList.map((content) => {
              return (
                <DownloadCard
                  key={content.category}
                  color="secondary"
                  title={content.title}
                  items={content.subCategory}
                  icon={<DocumentCategoryIcon section={content.section} index={content.priority} />}
                  searchedId={id}
                />
              )
            })
            : <Loader />
        }
      </Grid>
    </Grid>
  );
};

export default TraceabilitiesPage;
