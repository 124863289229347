import React from 'react';
import Typography from '@material-ui/core/Typography';

import styles from './styles';
import { Box } from '@material-ui/core';

export const TabPanel = ({ children, value, index, ...props }) => {
  const classes = styles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
