import { createMuiTheme } from '@material-ui/core/styles';
import 'fontsource-roboto';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C45824',
    },
    secondary: {
      light: '#116a4f',
      main: '#0E5C45',
    },
    neutral: {
      light: '#838383',
      main: '#707070',
      dark: '#000'
    },
    error: {
      main: '#D4473B'
    }
  },
  typography: {
    h1: {
      fontSize: 40,
      fontWeight: 'bold',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h2: {
      fontSize: 35,
      color: 'white',
      '@media (max-width:600px)': {
        fontSize: '1.3rem',
      },
    },
    h3: {
      fontSize: 35,
      fontWeight: 'bold',
      '@media (max-width:600px)': {
        fontSize: '1.3rem',
      },
    },
    h4: {
      fontSize: 33,
      fontWeight: 'bold',
      '@media (max-width:600px)': {
        fontSize: '1.2rem',
      },
    },
    h5: {
      fontSize: 25,
      fontWeight: 'bold',
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    h6: {
      fontSize: 25,
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    subtitle1: {
      fontSize: 20,
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    subtitle2: {
      fontSize: 18,
      fontStyle: 'italic',
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    body1: {
      fontSize: 18,
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    body2: {
      fontSize: 15,
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    caption: {
      fontSize: 15,
      '@media (max-width: 667px)': {
        fontSize: "0.7rem",
      },
    },
    fontFamily: [
      'Roboto',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});
export default theme;
