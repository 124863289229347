import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    marginTop: 60,
    [theme.breakpoints.up('md')]: {
      marginLeft: 200,
      marginTop: 190,
    }
  },
  imageBackground: {
    [theme.breakpoints.up('md')]: {
      display: 'block',
      left: 0,
      position: 'absolute',
      zIndex: 0,
      top: 730,
      height: 550,
      width: '70%'
    },
    display: 'none'
  },
  blockContainer: {
    zIndex: 1,
    backgroundColor: theme.palette.secondary.main,
    width: 536,
    height: 1084,
    [theme.breakpoints.up('md')]: {
      width: 1072,
      height: 762
    }
  },
  servicesContainer: {
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    width: 350, height: 335,
    padding: 50,
  },
  rowContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    width: 1072,
    height: 381,
    overflow: 'hidden'
  },
  container: {
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      height: 381,
    },
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  topLeft: {
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid grey',
    },
    borderBottom: '1px solid grey'
  },
  topRight: {
    borderBottom: '1px solid grey'
  },
  bottomLeft: {
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid grey',
    },
    borderBottom: '1px solid grey'
  },
  titleContainer: {
    width: '75%',
  },
  titleContainerSessions: {
    width: '75%',
    paddingBottom: 25
  },
  titleContainerTraceability: {
    width: '75%',
  },
  titleContainerLegal: {
    width: '75%',
  },
  iconContainer: {
    width: '25%',
    padding: 20,
    [theme.breakpoints.down('md')]: {
      top: 0,
      padding: "0 30 20 0"
    }
  },
  balanceContainer: {
    paddingRight: 25,
    width: '25%',
  },
  icon: {
    marginTop: 60,
    fontSize: 60,
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      fontSize: 30
    }
  },
  typo: {
    color: 'white'
  },
  typoTraceability: {
    color: 'white',
  },
  divider: {
    backgroundColor: "white",
    width: 100,
    height: 1,
    marginBottom: 20,
    marginTop: 4
  },
  // Mobile
  mobileContainer: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }
}));
