import React, { useState } from "react";
import styles from '../../styles';
import { IbanElement } from "@stripe/react-stripe-js";
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import ErrorIcon from '@material-ui/icons/Error';

const IbanField = (props) => {
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState(null);
  const [empty, setEmpty] = useState(true);
  const classes = styles({ focus, empty, error });

  const cardStyle = {
    base: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: 'Roboto, Open Sans',
      fontSize: "18px",
      iconColor : "#707070",
      "::placeholder": {
          color: "#707070"
      },
    },
    complete: {
      color: "black",
      iconColor: "black"
    },
    invalid: {
      color: "black",
      iconColor: "black"
    },
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    placeholderCountry: 'FR',
    style: cardStyle
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.cardContainer}>
        <Typography className={classes.typoIban}>IBAN</Typography>
         <IbanElement
           onBlur={() => {
             if (empty) {
               setError("L’IBAN que vous avez saisi est incomplet.")
               setFocus(false)
             } else {
               setFocus(false)
             }
           }}
           onFocus={() => setFocus(true)}
           onChange={(event) => {
             if (event.empty) {
               setEmpty(true)
               setError("L’IBAN que vous avez saisi est incomplet.")
             } else if (event.error) {
               setError(event.error.message)
             } else {
               setError(null)
               setEmpty(false)
             }
           }}
           options={IBAN_ELEMENT_OPTIONS}
         />
      </div>
      <FormHelperText error={error} classes={{ root: classes.rootError, error: classes.error }} htmlFor="filled-adornment-textfield">
        <span className={classes.helperText}>
          {error
            && <ErrorIcon className={classes.icon} />}
          {error}
        </span>
      </FormHelperText>
    </div>
  );
};

export default IbanField;
