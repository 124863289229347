import { fade } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  navLink: {
    fontSize: 19,
    color: ({ asButton }) => asButton ? "#fff" : theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: ({ asButton }) => asButton ? fade("#fff", .9) : theme.palette.primary.main
    },
    background: ({ asButton }) => asButton ? theme.palette.primary.main : "transparent",
    padding: ({ asButton }) => asButton ? `${theme.spacing(1)}px ${theme.spacing(3)}px` : "auto",
    borderRadius: ({ asButton }) => asButton ? 4 : "auto"
  },
}));
