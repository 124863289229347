import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  list: {
    minWidth: 250,
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  menuNavLink: {
    '&:hover': {
      background: theme.palette.secondary.main
    },
    '&:hover div span': {
      color: "#fff"
    },
    '&:hover p': {
      color: "#fff"
    }
  },
  navLink: {
    textDecoration: 'none',
    fontSize: 20,
    color: 'black',
    '@media (max-width: 1630px)': {
      fontSize: 15,
    },
    width: '100%'
  },
  orange: {
    border: "none",
    background: "none",
    textAlign: "left",
    textDecoration: "none",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    cursor: "pointer"
  },
  search: {
    position: 'relative',
    borderRadius: 0,
    boxShadow: `0px 3px 6px ${fade(theme.palette.primary.main, .19)}`,
    backgroundColor: fade(theme.palette.primary.main, 0),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0),
    },
    marginLeft: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
      width: '20vw',
    },
  },
}));
