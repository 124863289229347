import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  titleContainer: {
    minHeight: 104,
    padding: theme.spacing(4)
  },
  primary: {
    backgroundColor: theme.palette.primary.main
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main
  },
  title: {
    color: 'white',
    textTransform: ({ isSecondaryTitle }) => isSecondaryTitle ? "inherit" : "uppercase",
    fontSize: 'clamp(1.5rem, 3vw, 35px)'
  }
}));