import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import ErrorIcon from '@material-ui/icons/Error';

import styles from './styles';

export const CustomNumberField = ({ error, redError, errorMessage, numMax, formik, setFieldValue, name, little, handleChange, helperText, ...props }) => {
  const classes = styles({ little });

  return (
    <div style={{ height: '90px' }} className={little ? '' : classes.numberFieldWrapper}>
      <TextField
        variant="filled"
        color="secondary"
        error={error}
        name={name}
        className={redError ? classes.redError : classes.input}
        InputProps={{ classes, disableUnderline: true }}
        onChange={handleChange}
        {...props}
      />
      <FormHelperText error={error} classes={{ root: redError ? classes.redRootError : classes.rootError, error: classes.error }} htmlFor="filled-adornment-textfield">
        <span className={classes.helperText}>
          {helperText
            && <ErrorIcon className={classes.icon} />}
          {helperText}
        </span>
      </FormHelperText>
    </div>
  );
};

export default CustomNumberField;
