import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { Grid, Box, Typography } from '@material-ui/core';

import Loader from '../../components/Loader';
import QuotesForm from '../../components/Forms/QuotesForms/QuotesForm';
import { getQuotesLaunched } from '../../store/reducer';
import { switchAdminData } from '../../utils/services/formats';
import QuotesHeader from '../../components/Quotes/QuotesHeader';
import styles from './styles';

export const CotationSummary = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = styles();

  useEffect(() => {
    if (id) {
      dispatch(getQuotesLaunched({ quoteId: id }))
    }
  }, [id]);

  const { cotationData, medicActsData, getQuotesLoading, getMedicActsLoading, currentQuoteData } = useSelector((state) => ({
    cotationData: state.getIn(['store', 'cotationData']),
    medicActsData: state.getIn(['store', 'medicActsData']),
    getMedicActsLoading: state.getIn(['store', 'getMedicActsLoading']),
    getQuotesLoading: state.getIn(['store', 'getQuotesLoading']),
    currentQuoteData: state.getIn(['store', 'currentQuoteData']),
  }));

  const PATIENT = cotationData?.context;
  const actsList = currentQuoteData?.medicActs?.map(timeframe => timeframe?.acts || []).flat(1) || cotationData?.medicActs?.map(timeframe => timeframe?.acts || []).flat(1);
  const packageList = currentQuoteData?.medicActs?.map(timeframe => timeframe?.packageSession || []).flat(1) || cotationData?.medicActs?.map(timeframe => timeframe?.package || []).flat(1);
  const actIds = actsList?.concat(packageList);
  const [acts, setActs] = useState();

  useEffect(() => {
    let acts = [];
    if (actIds) {
      actIds?.map((actID) => {
        const act = medicActsData?.find(actData => actData.id === actID);
        let currentAct = {};
        let instructionsArray = []
        if (act?.bulles) {
          act.bulles.map((bulle) => {
            instructionsArray.push(bulle)
          })
        }
        currentAct.instructions = instructionsArray;
        currentAct.medicalAct = act.medicalAct;
        acts.push(currentAct)
      })
    }
    const cleanActs = Array.from(new Set(acts.map(JSON.stringify))).map(JSON.parse);
    setActs(cleanActs)
  }, [medicActsData]);

  return (
    <main>
      <div className={classes.toolbar} />
      <QuotesHeader title="Résultat de cotations optimisées des actes de la journée pour votre patient" isSummary />
      {getMedicActsLoading || getQuotesLoading
        ? <Grid container alignItems="center" className={classes.loaderWrapper}><Loader /></Grid>
        : <Grid container>
          <Box p={1}>
            <Formik>
              {(props) => <QuotesForm
                editable={false}
                results={cotationData?.medicActs}
                {...props}>
                {cotationData && (
                  <Box p={2}>
                    <Typography variant="h1">Contexte de soins</Typography>
                    <ul className={classes.actsList}>
                      <li><Typography>Age : {PATIENT?.age}</Typography></li>
                      <li><Typography>Lieu : {switchAdminData(PATIENT?.location) || PATIENT?.location}</Typography></li>
                      <li><Typography>Sexe : {switchAdminData(PATIENT?.sex) || PATIENT?.sex}</Typography></li>
                      {PATIENT?.patientType && <li><Typography>Type de patient : {PATIENT?.patientType?.map((x, key) => `${switchAdminData(x) || x}${key < PATIENT?.patientType?.length - 1 ? ', ' : ''}`)}</Typography></li>}
                    </ul>

                    <Typography variant="h1">Instructions</Typography>
                    {acts?.length > 0
                      ? <ul className={classes.actsList}>
                        {acts?.map((act, actKey) => <Box my={2} key={actKey}>
                          {act?.instructions.length > 0 &&
                            <li>
                              <Typography className={classes.actLabel}> {act.medicalAct} :</Typography>
                            </li>
                          }
                          <Box className={classes.instructionsBox}>
                            {act?.instructions?.map((instruction, key) =>
                              <Typography key={key}> {instruction}</Typography>
                            )}
                          </Box>
                        </Box>)}
                      </ul>
                      : <Typography>Pas d'instructions spécifiques</Typography>}
                  </Box>
                )}
              </QuotesForm>}
            </Formik>
          </Box>
        </Grid>
      }
    </main>
  )


};

export default CotationSummary;
