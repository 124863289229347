import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import ErrorIcon from '@material-ui/icons/Error';
import styles from './styles';

export const CustomPasswordField = ({
  label, error, redError, helperText, id, handleBlur, handleChange, isModalePasswordField }) => {
  const classes = styles({ isModalePasswordField });
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  const handleClickShowPassword = () => {
    setValues({ showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className={classes.wrapper}>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel color="secondary" error={error} classes={{ root: classes.rootError, error: classes.error }} htmlFor="filled-adornment-password">{label}</InputLabel>
        <FilledInput
          id={id}
          classes={{ root: classes.root }}
          type={values.showPassword ? 'text' : 'password'}
          // value={values.password}
          onChange={handleChange}
          disableUnderline
          error={error}
          name={id}
          onBlur={handleBlur}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
        />
        <FormHelperText error={error} classes={{ root: redError ? classes.redRootError : classes.rootError, error: classes.error }} htmlFor="filled-adornment-password">
          <span className={classes.helperText}>
            {helperText
              && <ErrorIcon className={classes.icon} />}
            {helperText}
          </span>
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default CustomPasswordField;
