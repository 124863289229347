import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
    marginBottom: 100
  },
  description: {
    width: "80%",
    marginTop: 100,
    marginBottom: 30,
    fontSize: "clamp(16px, 3vw, 23px)",
    '@media (max-width: 959px)': {
      marginTop: 10
    }  
  },
  legalCategory: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    height: 250,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  icon: {
    fontSize: 96,
    color: '#fff',
    marginBottom: 20
  },
  blockContainer: {
    width: 400,
    height: 500,
    [theme.breakpoints.up('md')]: {
      width: 800,
      height: 500
    }
  },
  columnContainer: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  rowContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    width: 800,
    height: 250,
    overflow: 'hidden'
  },
  imgContainer: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    width: '100%',
    height: '20%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  img: {
    width: '100%',

  },
  title: {
    color: 'white'
  },
  button: {
    paddingLeft: 10,
    marginBottom: 30,
    borderRadius: 50,
    height: 98,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    width: '95%',
    [theme.breakpoints.up("md")]: {
      width: 571,
    }
  },
  titleButton: {
    color: "#fff",
    textTransform: 'none',
    fontSize: 'clamp(1.5rem, 3vw, 25px)'
  },
}));
