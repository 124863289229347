import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#000000",
    '@media (max-width: 959px)': {
      height: '8%'
    }
  },
  inputRoot: {
    color: '#000000',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(2, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    fontSize: '85%',
    '&::placeholder': {
      fontStyle: 'italic',
      fontSize: '80%',
      color: '#000000'
    },
  },
  invisible: {
    display: 'none',
  }
}));