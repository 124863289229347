import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    '& table > *': {
      borderBottom: 'unset',
    },
    cursor: 'pointer',
    width: '100%'
  },
  bold: {
    fontWeight: 'bold',
  },
  noBorder: {
    border: 'none'
  },
  date: {
    margin: `0 ${theme.spacing(3)}px`
  },
  colWidth: {
    width: 200
  },
  tableHead: {
    width: '100%'
  },
  smallColumn: {
    width: '10%'
  },
  noPadding: {
    padding: 0
  },
  plus: {
    fontWeight: 900
  }
}));