import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LoginForm from '../../components/LoginForm';
import { loginLaunched } from '../../components/App/reducer';
import styles from '../../utils/styles';
import imageLock from '../../assets/image/lock.jpg';
import { TitleBanner } from '../../components/TitleBanner';

const SignInPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = styles();

  // Form data
  const initialValues = {
    email: '',
    password: ''
  };

  // Form Submitting Function
  const login = (credentials) => {
    credentials.email = credentials.email.toLowerCase();
    const payload = {
      ...credentials,
      redirect: true // From the login page, we want the saga to redirect (not from signup)
    }
    dispatch(loginLaunched(payload));
  };

  // Form Validation Schema
  const ValidationSchema = Yup.object().shape({
    email: Yup
      .string()
      .required('emailRequired'),
    password: Yup
      .string()
      .required('passwordRequired')
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <TitleBanner title="Espace connexion" color="secondary" />
      <Grid item container direction="row" alignItems="center" justify="center" className={classes.mainContainerPrimary}>
        <Grid item container direction="column" alignItems="center" justify="center" alignContent="center" className={classes.imgContainer}>
          <img src={imageLock} alt="news" className={classes.img} />
        </Grid>
        <Grid item container direction="column" alignItems="center" justify="center" className={classes.formContainer}>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={login}
          >
            {(props) => <LoginForm {...props} />}
          </Formik>
          <Typography variant="subtitle2"><NavLink className={classes.navlink} to="/password">{t('forgotPasswordButton')}</NavLink></Typography>
          <Typography variant="subtitle2"><NavLink className={classes.navlink} to="/signup">{t('signUpButton')}</NavLink></Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignInPage;
