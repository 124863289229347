import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import styles from './styles';

export const CustomSelect = ({
  label, error, options, helperText, onChange, value, ...props
}) => {
  const classes = styles();

  return (
    <div style={{ height: '90px', width: '100%' }}>
      <FormControl variant="filled" style={{ width: '100%' }} >
        <InputLabel color="secondary" id="select-filled-label" classes={{ root: classes.rootError, error: classes.error }}>{label}</InputLabel>
        <Select
          classes={{ root: classes.root }}
          id="select-filled"
          disableUnderline
          value={value}
          onChange={onChange}
          {...props}
        >
          {/*<MenuItem value="">
              <em>None</em>
            </MenuItem>} */}
          {
            options.map((option) => <MenuItem key={option.value} value={option.value}>{option.key}</MenuItem>)
          }
        </Select>
        <FormHelperText error={error} classes={{ root: classes.rootError, error: classes.error }} htmlFor="select-filled">{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default CustomSelect;
