import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography, IconButton, InputBase, Popover } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import styles from './styles';
import { getMedicActsLaunched, setSelectedHourIndex } from '../../../store/reducer';
import { arrayFromObjectProperties, groupObjectsByPropertyValue, roundUp } from '../../../utils/services/formats';
import { renderActCode } from '../../../utils/services/quotes';

const TimeFrame = ({ index, values, handleChange, setFieldValue, editable, results }) => {

  const { medicActsData, selectedHourIndex } = useSelector((state) => ({
    medicActsData: state.getIn(['store', 'medicActsData']),
    selectedHourIndex: state.getIn(['store', 'selectedHourIndex']),
  }));

  const isSelected = selectedHourIndex === index;
  const classes = styles({ isSelected, editable });
  const dispatch = useDispatch();
  const [acts, setActs] = useState([]);
  const actIds = values.medicActs[index].acts ? values.medicActs[index].acts.concat(values.medicActs[index]?.packageSession || values.medicActs[index]?.package) : values.medicActs[index]?.packageSession || values.medicActs[index]?.package;
  const [count, setCount] = useState(0); // Used in removeActFromTimeframe() to force component rerendering (the useEffect listens to the count state);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverId, setPopoverId] = React.useState(null);

  useEffect(() => {
    let acts = [];
    if (actIds) {
      actIds?.map((actID) => {
        const act = medicActsData?.find(actData => actData.id === actID);
        acts.push({
          title: act?.medicalAct,
          category: act?.type,
          id: act?.id,
          key: renderActCode(act),
          isPackage: act?.isPackage === "TRUE",
          bubble: act?.bulles
        });
      })
    }

    const actsByCategory = groupObjectsByPropertyValue(acts, "category");
    const arrayOfActsByCategory = arrayFromObjectProperties(actsByCategory);
    setActs(arrayOfActsByCategory)
  }, [values, count, medicActsData]);

  useEffect(() => {
    !medicActsData && dispatch(getMedicActsLaunched())
  }, [medicActsData])

  const removeActFromTimeframe = (act) => {
    const list = act.isPackage ? values?.medicActs[selectedHourIndex]?.packageSession : values?.medicActs[selectedHourIndex]?.acts;
    const idx = list.indexOf(act.id);
    if (idx > -1) {
      list.splice(idx, 1);
    }
    const fieldValueByActType = act.isPackage ? `medicActs.${selectedHourIndex}.packageSession` : `medicActs.${selectedHourIndex}.acts`;
    setFieldValue(fieldValueByActType, list);
    setCount(count + 1); // Force update
  }

  const handlePopoverOpen = (event, id) => {
    setPopoverId(id)
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverId(null)
    setAnchorEl(null);
  };

  const handleChangeClock = (event, index) => {
    const value = event.target.value.slice(event.target.value.length - 1).replace(/[^0-9]/g, '')
    if (event.target.value.length < 5) {
      setFieldValue(`medicActs.${index}.hourInString`, event.target.value.slice(0, event.target.value.length - 1) + value)
      if (event.target.value.length === 3 && values.medicActs[index].hourInString.length === 2) {
        if (parseInt(event.target.value.slice(0, event.target.value.length - 1)) > 23) {
          setFieldValue(`medicActs.${index}.hourInString`, '23:' + value)
        } else {
          setFieldValue(`medicActs.${index}.hourInString`, values.medicActs[index].hourInString + ':' + value)
        }
      } else if (event.target.value.length === 2 && values.medicActs[index].hourInString.length === 3) {
        setFieldValue(`medicActs.${index}.hourInString`, event.target.value)
      }
    } else if (event.target.value.length === 5) {
      if (parseInt(event.target.value.slice(event.target.value.length - 2)) > 59) {
        setFieldValue(`medicActs.${index}.hourInString`, event.target.value.slice(0, event.target.value.length - 2) + '59')
      } else {
        setFieldValue(`medicActs.${index}.hourInString`, event.target.value)
      }
    }
  }

  const open = Boolean(anchorEl);

  return (
    medicActsData ? (
      <div
        className={classes.timeWrapper}
        onClick={() => dispatch(setSelectedHourIndex(index))}
      >
        <Grid container justify="space-between" alignItems="flex-start" className={classes.hourHeight}>
          <Grid item xs={5} lg={3}>
            <Box mb={1}>
              <InputBase
                id={`medicActs.${index}.hourInString`}
                value={values.medicActs[index]?.hourInString}
                onChange={(event) => handleChangeClock(event, index)}
                disabled={!editable}
                placeholder="08:00"
                classes={{ root: classes.time, disabled: classes.disabled }}
                onBlur={(event) => {
                  if (event.target.value.length === 1) {
                    setFieldValue(`medicActs.${index}.hourInString`, '0' + event.target.value + ':00')
                  } else if (event.target.value.length === 2) {
                    setFieldValue(`medicActs.${index}.hourInString`, event.target.value + ':00')
                  } else if (event.target.value.length === 4) {
                    setFieldValue(`medicActs.${index}.hourInString`, event.target.value + '0')
                  }
                }}
              />
            </Box>
          </Grid>
          {results && (
            <Box m={2} key={index}>
              <Typography align="right" className={classes.resultText}>{results[index].results.litteral}, soit <span className={classes.bold}>{roundUp(results[index].results.number)} €</span></Typography>
            </Box>
          )}
        </Grid>

        {acts.map((actsList, idx) => {
          const categoryTitle = actsList?.find(x => x.category)?.category;
          return (
            <Box key={idx} my={2}>
              {categoryTitle && <Typography className={classes.category}>{categoryTitle}</Typography>}
              {actsList.map((act, actIndex) => {
                return act.title && (
                  <Box my={1} mx={2} key={`${idx}-${act.title}-${actIndex}`}>
                    <ul>
                      <Grid container>
                        <Grid item className={classes.actTitle}>
                          <li className={classes.listItem}>{act.title} <span className={classes.key}>{act.key}</span></li>
                        </Grid>
                        {editable && (
                          <Grid item className={classes.actButton} container justify="flex-start" alignItems="flex-start" direction="row">
                            <IconButton
                              className={classes.buttonIcon}
                              disabled={selectedHourIndex !== index}
                              onClick={() => removeActFromTimeframe(act)}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} className={classes.removeActButton} />
                            </IconButton>
                            {act.bubble?.length !== 0 &&
                              <IconButton
                                className={classes.buttonIcon}
                                disabled={selectedHourIndex !== index}
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={(event) => handlePopoverOpen(event, act.id)}
                                onMouseLeave={handlePopoverClose}
                              >
                                <FontAwesomeIcon icon={faQuestionCircle} className={classes.infoButton} />
                              </IconButton>
                            }
                            <Popover
                              id="mouse-over-popover"
                              className={classes.popover}
                              classes={{
                                paper: classes.paper,
                              }}
                              open={act.id === popoverId && open}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                            >
                              {act.bubble?.length !== 0 && act.bubble?.map((item) =>
                                <Typography key={item}>{item}</Typography>
                              )}
                            </Popover>

                          </Grid>
                        )}
                      </Grid>
                    </ul>
                  </Box>
                )
              })}
            </Box>
          )
        })}
        {/* Icon indicating timeframe must be selected to add new medical acts */}
        {selectedHourIndex !== index && editable && (
          <IconButton className={classes.addButtonIcon}>
            <FontAwesomeIcon icon={faPlusCircle} className={classes.addButton} />
          </IconButton>
        )}
      </div>
    )
      : <Skeleton variant="rect" width={'40vw'} height={150} />
  )
}

export default TimeFrame;

TimeFrame.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  editable: PropTypes.bool
};

TimeFrame.defaultProps = {
  editable: false
};