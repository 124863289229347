import { makeStyles } from '@material-ui/core/styles';
import { QUOTE_DRAWER_WIDTH } from '../../../utils/constants';

const drawerWidth = QUOTE_DRAWER_WIDTH;
const smallDrawer = 55;

export default makeStyles((theme) => ({
  appBar: {
    width: ({ quotePage, isSummary }) => (quotePage === 1 || isSummary) ? '100%' : `calc(100% - ${smallDrawer}px)`,
    [theme.breakpoints.up('md')]: {
      width: ({ quotePage, isSummary }) => (quotePage === 1 || isSummary) ? '100%' : `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    padding: theme.spacing(1)
  },
  quotesAppBar: {
    display: 'flex',
    justifyContent: 'center',
    width: '60%',
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      width: '90%',
    }
  },
  quotesTitle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'clamp(1.3rem, 2.3vw, 30px)'
  },
}));