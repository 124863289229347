import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { CustomButton } from '../../Button';
import styles from '../styles';
import { useHistory } from 'react-router-dom';
import BillingForm from '../BillingForm';
import Typography from '@material-ui/core/Typography';
import CardNumberField from './CardNumberField';
import { CustomTextField } from '../../Inputs/CustomTextField';
import CardExpiryField from './CardExpiryField';
import CardCvcField from './CardCvcField';
import { Box } from '@material-ui/core';

const CBForm = (props) => {
  const classes = styles();
  const history = useHistory();

  const [disabled, setDisabled] = React.useState(true);

  const { doPaymentError, doPaymentLoading } = useSelector((state) => ({
    doPaymentError: state.getIn(['app', 'doPaymentError']),
    doPaymentLoading: state.getIn(['app', 'doPaymentLoading'])
  }));
  const {
    handleSubmit
  } = props;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="row"
        alignItems="flex-start"
        className={classes.sameContainer}
      >
        <BillingForm props={props} />
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={classes.formContainer}
          xs={12}
          sm={6}
        >
          <Typography variant="h3" className={classes.title}>PAIEMENT</Typography>
          <Box>
            <CardNumberField setDisabled={setDisabled} />
            <CustomTextField
              name="paymentName"
              label="Titulaire de la carte"
            />
            <CardExpiryField setDisabled={setDisabled} />
            <CardCvcField setDisabled={setDisabled} />
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        className={classes.buttonContainer}
      >
        <CustomButton
          variant="contained"
          color="secondary"
          handleClick={() => history.push('/')}
          title="Annuler"
        />
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          handleClick={() => handleSubmit()}
          loading={doPaymentLoading}
          title="Adhérer"
        />
      </Grid>
    </Grid>
  );
};

export default CBForm;
