import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'black',
    transition: '.2s',
    '&:hover': {
      color: fade('#000', .8)
    }
  },
  container: {
    '& svg path': {
      color: theme.palette.secondary.main
    },
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1, 0)
  },
  title: {
    margin: `0 ${theme.spacing(2)}px`,
  }
}));