import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@material-ui/core';
import { faAddressCard, faFile } from '@fortawesome/free-solid-svg-icons';

import Title from '../../components/Title';
import ProfileForm from '../../components/Forms/ProfileForm';
import SectionTitle from '../../components/SectionTitle';
import { getUserLaunched } from '../../components/App/reducer';
import { getInvoicesLaunched } from '../../store/reducer';
import { getFullFrenchDate } from '../../utils/services/formats';
import styles from './style';
import InvoiceDownload from "../../components/InvoiceDownload";
import Loader from "../../components/Loader";
import CustomButton from '../../components/Button';

import { updateUserLaunched } from '../../components/App/reducer';

const MyAccount = (props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, userDynamo, invoicesData, getInvoicesLoading, getInvoicesError } = useSelector((state) => ({
    user: state.getIn(['app', 'userData']),
    userDynamo: state.getIn(['app', 'userDynamo']),
    invoicesData: state.getIn(['store', 'invoicesData']),
    getInvoicesLoading: state.getIn(['store', 'getInvoicesLoading']),
    getInvoicesError: state.getIn(['store', 'getInvoicesError']),
  }));
  // Form data
  const initialValues = {
    lastName: user && user.lastName || '',
    firstName: user && user.firstName || '',
    email: user && user.email || '',
    phone: user && user.phone || '',
    address: user && user.address || '',
    zipCode: user && user.zipCode || '',
    city: user && user.city || ''
  };

  useEffect(() => {
    if (userDynamo.user.id) {
      dispatch(getUserLaunched({ userId: userDynamo.user.id }))
    }
  }, []);

  useEffect(() => {
    if (userDynamo.user.id) {
      dispatch(getInvoicesLaunched({ userId: userDynamo.user.id }))
    }
  }, []);

  if (!user) {
    return null;
  }

  const updateProfile = (values) => {
    dispatch(updateUserLaunched({ payload: { ...values }, userId: user.id }));
  };

  const PHONE_NUMBER_ERROR_MESSAGE = "Merci de renseigner un numéro de téléphone valide";
  const ZIPCODE_ERROR_MESSAGE = "Merci de renseigner un code postal valide";

  // Form Validation Schema
  const validationSchema = Yup.object().shape({
    lastName: Yup.string().trim().required("Merci de renseigner votre nom de famille"),
    firstName: Yup.string().trim().required("Merci de renseigner votre prénom"),
    email: Yup.string().trim().email("Merci de renseigner un format d'email valide").required("L'adresse email est obligatoire"),
    phone: Yup.string().matches(/^[0-9]+$/, PHONE_NUMBER_ERROR_MESSAGE).trim().min(10, PHONE_NUMBER_ERROR_MESSAGE).trim().max(10, PHONE_NUMBER_ERROR_MESSAGE).required("Le numéro de téléphone est obligatoire"),
    address: Yup.string().trim(),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, ZIPCODE_ERROR_MESSAGE)
      .min(5, ZIPCODE_ERROR_MESSAGE)
      .max(5, ZIPCODE_ERROR_MESSAGE),
    city: Yup.string().trim(),
  });

  const getDates = (invoice) => {
    if (invoice.contractId === userDynamo?.contract?.id) {
      const startDate = getFullFrenchDate(userDynamo?.contract?.startDate, 'fr-FR');
      const endDate = getFullFrenchDate(userDynamo?.contract?.endDate, 'fr-FR');
      return `, valable du ${startDate} au ${endDate}.`
    }
    return '';
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="row"
    >

      <Title title={`Bienvenue sur votre profil, ${user.firstName}`} color={"secondary"} />
      <Box justify='center' mt={5}>
        {
          (new Date(userDynamo?.contract?.endDate).getTime() > new Date().getTime()) &&
          <Typography>Vous êtes adhérent jusqu' au {getFullFrenchDate(userDynamo?.contract?.endDate, 'fr-FR')}</Typography>
        }
        {
          (new Date(userDynamo?.contract?.endDate).getTime() < new Date().getTime()) &&
          <Grid >
            <Typography>Votre abonnement est terminé, veuillez renouveler votre adhésion pour pouvoir avoir de nouveau accès à toutes les fonctionnalités.</Typography>
            {/* <Typography>Votre compte est en accès limité. Il est en attente d'activation de notre part, veuillez réessayer ultérieurement.</Typography> */}
            <Grid container>

              <CustomButton
                title="Renouveler mon adhésion"
                loading={false}
                color="primary"
                type="button"
                variant="outlined"
                withoutPadding
                handleClick={() => history.push("/payment")}
              />
            </Grid>
          </Grid>
        }
      </Box>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={6}>
          <SectionTitle title="Mes coordonnées" icon={faAddressCard} />
        </Grid>
        <Grid item container xs={12} sm={6} direction="column">
          <Box className={classes.adherentNumberWrapper}>
            <Typography variant="h5" className={classes.uppercase}>N° Adhérent</Typography>
            <Typography>{user.adherentNumber}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={updateProfile}
        component={ProfileForm}
      />
      <Grid container className={classes.container} justify="flex-start">
        <SectionTitle title="Mes factures" icon={faFile} />
      </Grid>
      <Grid container className={classes.container} direction="column">
        {
          getInvoicesError &&
          <Typography>Une erreur est survenue au chargement de vos facture, veuillez actualiser la page ou réessayer ultérieurement.</Typography>
        }
        {
          (!getInvoicesLoading && invoicesData && invoicesData.length === 0) &&
          <Typography>Aucune facture n'est disponible pour le moment, veuillez réessayer ultérieurement.</Typography>
        }

        {
          getInvoicesLoading &&
          <Loader />
        }
        {
          (invoicesData && !getInvoicesLoading && invoicesData.length !== 0) && invoicesData.map((invoice) =>
            <Box key={invoice.id} className={classes.invoice}>
              <InvoiceDownload file={invoice.link} title={`Adhésion ${getFullFrenchDate(invoice?.createdDate, 'fr-FR')} ${getDates(invoice)}`} />
            </Box>
          )
        }
      </Grid>
    </Grid>
  )
};
export default MyAccount;
