import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';

const publicRender = (Component, isAuthenticated, rest) => (props) => {
  if (isAuthenticated === true) {
    return (
      <Redirect
        to={{
          pathname: '/home'
        }}
      />
    );
  } else {
    return (
      <div>
        <Component {...props} />
      </div>
    );
  }
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.getIn(['app', 'isAuthenticated']), null);
  return (<Route {...rest} render={publicRender(Component, isAuthenticated, rest)} />)
}

export default PublicRoute;
