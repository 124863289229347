import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './styles';
import { Grid, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import ErrorIcon from "@material-ui/icons/Error";
import FormHelperText from "@material-ui/core/FormHelperText";

const DatePicker = ({ name, handleChange, values, errors }) => {
  const classes = styles();
  const localeMap = {
    fr: frLocale,
  };
  const [updatedDate, setUpdatedDate] = useState(name === "contract.startDate" ? values.contract.startDate : values.contract.endDate)

  const handleDateChange = (e) => {
    let newValue;
    // if e is not a date, calling .getTime() will return NaN
    const isDate = !Number.isNaN(e.getTime());
    if (isDate) {
      newValue = e?.toISOString();
    }
    const updatedValue = {
      target: { name: name, value: newValue || e }
    }
    setUpdatedDate(updatedValue.target.value);
    handleChange(updatedValue);
  }
  return (
    <Grid item className={classes.date}>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={localeMap['fr']}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          id="date"
          value={updatedDate || values}
          name={name}
          onChange={(e) => handleDateChange(e)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          color="secondary"
          invalidDateMessage={name == 'contract.endDate' ? "La date de fin doit être ultérieure à celle de début" : ""}
          // minDateMessage={name == 'contract.endDate' ? "La date de fin ne peut être antérieure à aujourd'hui" : ''}
          className={classes.dateContainer}
          PopoverProps={{ PaperProps: { classes: { root: classes.paper } } }}
        />
      </MuiPickersUtilsProvider>
      {errors &&
        <FormHelperText
          error={!!errors}>
          <span className={clsx(classes.helperText, classes.redError)}>
            <ErrorIcon className={classes.icon} />
            {errors && Object.prototype.toString.call(values) === "[object Date]" && isNaN(values.getTime()) ? "Date invalide" : errors}
          </span>
        </FormHelperText>
      }
    </Grid>
  );
}

export default DatePicker;