import React from 'react';
import { useHistory } from "react-router-dom";
import { Paper, Typography, Grid, Box } from '@material-ui/core';
import { CustomButton } from '../Button';
import styles from './styles';

export const Article = ({ id, article, ...props }) => {
  const classes = styles();
  const history = useHistory();
  const title = article?.titre;
  const description = article?.description;

  return (
    <Paper elevation={0} {...props}>
      <Grid item container direction="column" alignContent="center" alignItems="center" justify="space-around" className={classes.container}>
        <Typography variant="h4" className={classes.title}>{title}</Typography>
        <img src={article?.image?.fields?.file?.url} className={classes.img} alt={article?.image?.fields?.file?.title} />
        <Typography className={classes.description}>
          {description}
        </Typography>
        <Box className={classes.buttonContainer}>
          <CustomButton variant="contained" color="primary" handleClick={() => history.push(`/news/${id}`)} title="Lire la suite" />
        </Box>
      </Grid>
    </Paper>
  );
};

export default Article;
