import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    width: '95%',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  action: {
    width: "40%"
  },
  email: {
    width: "15%"
  },
  field: {
    width: "11%"
  }
}));