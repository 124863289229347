import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SignUpForm from '../../components/SignUpForm';
import { signupLaunched } from '../../components/App/reducer';
import styles from '../../utils/styles';
import { TitleBanner } from '../../components/TitleBanner';
import imageLock from '../../assets/image/lock.jpg';
import { NavLink } from 'react-router-dom';

const SignUpPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = styles();

  // Form data
  const initialValues = {
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  };

  // Form Submitting Function
  const signup = (credentials) => {
    credentials.email = credentials.email.toLowerCase();
    dispatch(signupLaunched(credentials));
  };

  const PHONE_NUMBER_ERROR_MESSAGE = "Merci de renseigner un numéro de téléphone valide";

  // Form Validation Schema
  const ValidationSchema = Yup.object().shape({
    lastName: Yup.string().trim().required("Merci de renseigner votre nom de famille"),
    firstName: Yup.string().trim().required("Merci de renseigner votre prénom"),
    email: Yup.string().trim().email("Merci de renseigner un format d'email valide").required("L'adresse email est obligatoire"),
    phone: Yup.string().matches(/^\+?\d+$/, PHONE_NUMBER_ERROR_MESSAGE).trim().min(10, PHONE_NUMBER_ERROR_MESSAGE).trim().max(10, PHONE_NUMBER_ERROR_MESSAGE).required("Le numéro de téléphone est obligatoire"),
    password: Yup
      .string()
      .min(8, "8 caractères minimum")
      .required(t('signUpForm.passwordRequired')),
    confirmPassword: Yup
      .string()
      .required(t('signUpForm.confirmPasswordRequired'))
      .oneOf([Yup.ref('password'), null], t('signUpForm.matchPassword')),
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <TitleBanner title="Espace adhésion" color="primary" />
      <Grid item container direction="row" alignItems="center" justify="center" className={classes.mainContainerSecondary}>
        <Grid item container direction="column" alignItems="center" justify="center" alignContent="center" className={classes.imgContainer}>
          <img src={imageLock} alt="news" className={classes.img} />
        </Grid>
        <Grid item container direction="column" alignItems="center" justify="center" className={classes.formSignUpContainer}>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={signup}
          >
            {(props) => <SignUpForm {...props} />}
          </Formik>
          <Typography variant="subtitle2"><NavLink className={classes.navlink} to="/login">{t('loginButton')}</NavLink></Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUpPage;
