import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      padding: '5% 10%',
    }
  },
  image: {
    left: '50%',
    transform: 'translate(-50%)',
    [theme.breakpoints.up("md")]: {
      float: 'left',
      left: 0,
      transform: 'translate(0)',
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
  },
  alignEnd: {
    textAlign: 'end'
  },
  bold: {
    fontWeight: 'bold'
  },
  link: {
    color: "#000",
    transition: '.1s',
    '&:hover': {
      color: fade("#000", .8)
    }
  },
  descriptionContainer: {
    marginTop: 24,
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      marginBottom: 5,
      marginTop: 5
    },
  }
}));
