import React from 'react';
import { Table, TableBody, TableContainer, Paper } from '@material-ui/core';

import Row from '../Row';
import { sortArrayOfObjectsOnFieldName } from '../../../utils/services/formats';
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import styles from "./styles";

export const ContractsTable = ({ contracts, changesAllowed }) => {
  const classes = styles();

  const CONTRACTS_BY_USERS = sortArrayOfObjectsOnFieldName(contracts, "adherentNumber");

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell classes={{ head: classes.head, root: classes.email }}>Email</TableCell>
            <TableCell classes={{ head: classes.head, root: classes.field }} align="center">Prénom</TableCell>
            <TableCell classes={{ head: classes.head, root: classes.field }} align="center">Nom</TableCell>
            <TableCell classes={{ head: classes.head, root: classes.field }} align="center">Numéro d'adhérent</TableCell>
            <TableCell classes={{ head: classes.head, root: classes.field }} align="center">Téléphone</TableCell>
            <TableCell classes={{ head: classes.head, root: classes.field }} align="center">Adresse</TableCell>
            <TableCell classes={{ head: classes.head }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {CONTRACTS_BY_USERS?.map((row, key) => <Row key={key} row={row} changesAllowed={changesAllowed} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContractsTable;