import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import imageNews from "../../assets/image/news.jpg";
import legalFrame from '../../assets/image/legalFrame.jpg';
import styles from './style';
import { getAllContentsLaunched } from '../../store/reducer';
import { TitleBanner } from '../../components/TitleBanner';
import Loader from '../../components/Loader';
import DownloadCard from '../../components/DownloadCard';
import Button from '@material-ui/core/Button';
import DocumentCategoryIcon from '../../components/DocumentCategoryIcon';
import Subtitle from '../../components/Subtitle';


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQueryParams(qName) {
  return (new URLSearchParams(useLocation().search)).get(qName);
}


export const LegalFramePage = (props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contentsList, getAllContentsLoading } = useSelector((state) => ({
    contentsList: state.getIn(['store', 'contentsList']),
    getAllContentsLoading: state.getIn(['store', 'getAllContentsLoading']),
  }));

  useEffect(() => {
    dispatch(getAllContentsLaunched({ contentType: 'LEGAL', section: 'CADRE_JURIDIQUE' }));
  }, []);

  const id = useQueryParams("id");


  return (
    <Grid item container direction="column" className={classes.container}>
      <TitleBanner title="CADRE JURIDIQUE" img={legalFrame} color="secondary" />
      <Subtitle text={"Le SAIIL vous aide à respecter vos obligations législatives, réglementaires, déontologiques et de bonnes pratiques.\n L’exercice de la profession infirmière en libéral est assujetti aux règles de droit qui sont nombreuses et complexes. Les textes de loi, les décrets et arrêtés, les ordonnances, les circulaires, les chartes, les recommandations des autorités savantes et la jurisprudence constituent le cadre juridique.\n Le droit de la santé est en perpétuelle évolution tant en matière de régime de financement que du rôle des professionnels de santé !"} />
      <Grid item container direction="row" alignContent="center" justify="center">
        <Button
          variant="contained"
          classes={{ root: classes.button }}
          onClick={() => history.push('/contact')}
        >
          <Typography variant="h1" className={classes.title}>Contactez l'aide juridique</Typography>
        </Button>        {
          !getAllContentsLoading && contentsList && Array.isArray(contentsList)
            ? contentsList.map((content) => {
              return (
                <DownloadCard
                  key={content.title}
                  color="secondary"
                  title={content.title}
                  items={content.subCategory}
                  icon={<DocumentCategoryIcon section={content.section} index={content.priority} />}
                  searchedId={id}
                />
              )
            }
            )
            : <Loader />
        }
      </Grid>
    </Grid>
  );
};

export default LegalFramePage;
