import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { Form } from 'formik';

import CustomTextField from '../../Inputs/CustomTextField';
import CustomButton from '../../Button';
import CustomAlert from '../../Modale';
import ChangePasswordModale from '../../Modale/ChangePasswordModale';

import { compareArraysOfObjects, objectToArrayOfKeyPairs, switchLabel } from '../../../utils/services/formats';
import styles from './styles';

export const ProfileForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  initialValues,
}) => {

  const classes = styles();

  const fields = Object.keys(values);

  const { updateUserLoading } = useSelector((state) => ({
    updateUserLoading: state.getIn(['app', 'updateUserLoading']),
  }));

  const initialValuesArrayOfKeyPairs = objectToArrayOfKeyPairs(initialValues);
  const credentialsArrayOfKeyPairs = objectToArrayOfKeyPairs(values);
  const diff = compareArraysOfObjects(initialValuesArrayOfKeyPairs, credentialsArrayOfKeyPairs);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (diff.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [diff]);

  const [modaleOpen, setModaleOpen] = useState(false);

  return (
    <Form>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={3}
        className={classes.formContainer}
      >
        {fields.map((field, key) => {
          return (
            <Grid item key={key} xs={12} sm={field === "address" ? 12 : 6}>
              <CustomTextField
                label={switchLabel(field)}
                type={field === "zipCode" ? "number" : "text"}
                fullWidth={field === "address"}
                error={!!touched[field] && !!errors[field]}
                helperText={touched[field] && errors[field]}
                id={field}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values[field]}
              />
            </Grid>
          )
        })}
        <Grid container justify="space-between" className={classes.buttonsWrapper} spacing={3}>
          <Grid item>
            <CustomButton
              title="Changer mon mot de passe"
              loading={false}
              color="primary"
              type="button"
              variant="outlined"
              withoutPadding
              handleClick={() => setModaleOpen(true)}
            />
          </Grid>
          <Grid item>
            <CustomButton
              title="Modifier mon profil"
              loading={updateUserLoading}
              color="primary"
              variant="contained"
              withoutPadding
              disabled={disabled}
              type="submit"
            />
          </Grid>
        </Grid>
        {modaleOpen && (
          <CustomAlert squareModale setOpenModale={() => setModaleOpen(!modaleOpen)} openFromParent={modaleOpen} id="change password">
            <ChangePasswordModale />
            <Box className={classes.closeButtonWrapper}>
              <IconButton onClick={() => setModaleOpen(false)} classes={{ root: classes.buttonRoot }}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} className={classes.icon} />
              </IconButton>
            </Box>
          </CustomAlert>
        )}
      </Grid>
    </Form>
  );
};

export default ProfileForm;
