import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: 'white'
  },
  appWrapper: {
    minHeight: '79vh'
  }
}));
