import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  button: {
    paddingLeft: 10,
    marginTop: 30,
    borderRadius: 50,
    height: 98,
    backgroundColor: theme.palette.primary.main,
    width: '95%',
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("md")]: {
      width: 571,
    }
  },
  buttonSecondary: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    }
  },
  icon: {
    color: "white",
    fontSize: 30,
    [theme.breakpoints.up("sm")]: {
      fontSize: 60,
    }
  },
  arrowIcon: {
    color: "white",
    fontSize: 50,
    [theme.breakpoints.up("sm")]: {
      fontSize: 80,
    }
  },
  listWrapper: {
    width: "95%",
    [theme.breakpoints.up("md")]: {
      width: "75%",
    }
  },
  list: {
    backgroundColor: 'white',
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
    }
  },
  title: {
    color: "#fff",
    width: '55%',
    fontSize: 'clamp(1.1rem, 3vw, 25px)'
  },
  downloadLine: {
    marginLeft: '10vw',
    width: 'calc(100% - 12vw)',
  },
  downloadButton: {
    height: 35,
    width: 35
  },
  subcategoryName: {
    '& span': {
      position: 'relative',
      fontWeight: 'bold'
    }
  },
  subcategoryNameSearched: {
    '& span': {
      position: 'relative',
      fontWeight: 'bold',
      backgroundColor: 'yellow'
    }
  },
  subcategoryAnchor: {
    position: 'absolute',
    top: '-100px',
  },
  listItemAvatar: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingBottom: '10%'
    }
  },
  documentContainer: {
    display: "flex",
    flexDirection: "column",
    width: 'calc(100% - 12vw)'
  },
  secondaryAction: {
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      top: theme.spacing(3),
      margin: "auto"
    }
  }
}));
