import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';

import { TitleBanner } from '../../components/TitleBanner';
import SessionsList from '../../components/TrainingSessions/SessionsList';
import Title from '../../components/Title';
import Loader from '../../components/Loader';

import { getAllContentsLaunched } from '../../store/reducer';
import formationPicture from '../../assets/image/formationPage.jpg';
import styles from './styles';
import { FORMATION } from '../../utils/constants';
import { addDaysToTimestamp } from '../../utils/services/formats';

export const FormationsPage = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const tomorrow = addDaysToTimestamp(Date.now(), 1);
  const isoDate = new Date(tomorrow).toISOString();

  useEffect(() => {
    dispatch(getAllContentsLaunched({ contentType: FORMATION, date: isoDate }))
  }, []);

  const { previousSessions, futureSessions, getAllContentsLoading } = useSelector((state) => ({
    previousSessions: state.getIn(['store', 'contentsList', 'previousSessions']),
    futureSessions: state.getIn(['store', 'contentsList', 'futureSessions']),
    getAllContentsLoading: state.getIn(['store', 'getAllContentsLoading']),
  }));

  const sortedPastSessions = previousSessions?.sort((a, b) => new Date(b.fields.date) - new Date(a.fields.date))
  const sortedFutureSessions = futureSessions?.sort((a, b) => new Date(a.fields.date) - new Date(b.fields.date))

  return (
    <>
      <TitleBanner img={formationPicture} title="Formations" color="secondary" />
      <Grid item container alignContent="center" justify="center">
        <Typography variant="body1" className={classes.description}>
          Le temps est compté : vie professionnelle et vie familiale ! Alors suivre des formations pour actualiser ses connaissances sur la NGAP, les soins infirmiers, la comptabilité, la prévoyance, la retraite n’est pas toujours aisé, alors le SAIIL opte pour vous faire gagner du temps pour rester informée en vous proposant des webinaires sur 1h -1h30 que vous pouvez suivre en direct si vous êtes disponible ou en visionnant les supports des formations quand vous le pouvez !
        </Typography>
      </Grid>
      <Grid container direction="column" className={classes.wrapper}>
        {getAllContentsLoading
          ? (
            <Grid container alignItems="center" className={classes.wrapper}>
              <Loader />
            </Grid>
          ) : (
            <Grid>
              {futureSessions?.length > 0
                ?
                <Box my={3}>
                  <Title title="Formations à venir" color="secondary" isSecondaryTitle />
                  <SessionsList sessions={sortedFutureSessions} />
                </Box>
                : <Box my={3}>
                  <Typography className={classes.noContent}>Pas de formations prévues pour le moment</Typography>
                </Box>
              }
              <Box my={3}>
                <Grid item md={8}>
                  <Title title="Formations passées consultables" color="secondary" isSecondaryTitle />
                </Grid>
              </Box>
              {previousSessions?.length > 0
                ? <SessionsList sessions={sortedPastSessions} />
                : <Box my={3}>
                  <Typography className={classes.noContent}>Il n'y a pas encore de sessions passées</Typography>
                </Box>
              }
            </Grid>
          )
        }
      </Grid>
    </>
  )
};

export default FormationsPage;