import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  quotesFormWrapper: {
    height: '66vh',
    padding: theme.spacing(0, 2),
  },
  addButton: {
    color: theme.palette.primary.main,
    padding: 0,
    fontSize: 40,
    margin: theme.spacing(0, 2)
  },
  subtitle: {
    fontSize: 22,
    fontWeight: 'bold',
    background: theme.palette.primary.main,
    color: "#fff",
    padding: theme.spacing(.5, 2),
    borderRadius: 22
  },
  paper: {
    width: '100%',
    borderRadius: 20
  },
  quotesTitleWrapper: {
    height: '10vh',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '20px 20px 0 0',
    textAlign: 'center',
    '@media (max-width:431px)': {
      marginTop: 60
    }
  },
  quotesTitle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'clamp(1.1rem, 1.5vw, 30px)',
    paddingTop: 0
  },
  timeList: {
    maxHeight: '55vh',
    width: '100%',
    position: 'relative',
    overflowY: 'scroll',
    scrollbarColor: `${theme.palette.primary.main} transparent`, // Firefox scrollbar
    scrollbarWidth: 'thin', // Firefox scrollbar,
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      background: `transparent transparent`,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 16,
    },
    padding: theme.spacing(0, 1, 0, 0)
  },
  addNewTimeFrameWrapper: {
    height: 75,
  },
  addTimeButton: {
    display: "flex",
    background: 'none',
    border: 'none',
  },
  addButtonIcon: {
    cursor: 'pointer'
  },
  timeframeWrapper: {
    position: 'relative',
    '@media (max-width:1080px)': {
      marginTop: '25%',
    },
  },
  removeIcon: {
    color: theme.palette.error.main,
    fontSize: 32
  },
  removeButtonIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1)
  },
  actsSelectionWrapper: {
    order: ({ editable }) => editable ? 1 : 2
  },
  actsSummaryWrapper: {
    order: ({ editable }) => editable ? 2 : 1,
    minWidth: ({ editable }) => !editable ? '45vw' : 0
  },
  bold: {
    fontWeight: 'bold',
    margin: theme.spacing(0, 0.5),
  },
  downloadButton: {
    display: 'flex',
    color: theme.palette.secondary.main,
    margin: theme.spacing(6, 0)
  },
  downloadText: {
    fontWeight: 'bold'
  },
  form: {
    width: '100%',
    '@media (max-width:431px)': {
      marginTop: '15%'
    }
  },
  searchbar: {
    width: '100%'
  },
  searchPaper: {
    borderRadius: theme.spacing(0, 0, 2, 2)
  },
  listbox: {
    background: fade(theme.palette.secondary.main, .1)
  },
  error: {
    fontWeight: 'bold',
    fontSize: 18,
    color: "red"
  },
  // autocomplete renderOption
  clickableAct: {
    paddingLeft: "1.8px",
    color: '#000000DE',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    textAlign: 'left',
    margin: theme.spacing(2, 1),
    background: 'none',
    border: 'none',
    transition: '.1s',
    '& p': {
      fontSize: 14,
      display: 'inline',
    },
    '&:not(:disabled)': {
      cursor: 'pointer'
    },
    '&:not(:disabled):active': {
      transform: 'translateY(2px)',
    },
    '&:disabled': {
      color: '#00000061'
    },
    '&:disabled svg path': {
      fill: '#00000061'
    }
  },
  iconWrapper: {
    padding: theme.spacing(0, 1.2, 0, 0),
    paddingLeft: 2
  },
  addAct: {
    '& path': {
      fill: fade("#000000DE", .7)
    },
    fontSize: 20,
    marginLeft: -2,

  },
  checkbox: {
    background: 'none !important',
  },
  option: {
    padding: '0 !important',
    position: 'relative',
    '& span': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    '& .checkbox-label': {
      paddingLeft: '40px !important',
      fontSize: 14,
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'left',
      '& p': {
        fontSize: 14,
        display: 'inline',
      },
    }
  }
}));