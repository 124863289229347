import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import legalImage from "../../assets/image/legalPage.jpg";
import litigationPic from '../../assets/image/litigation.jpg';
import legalFrame from '../../assets/image/legalFrame.jpg';
import styles from './style';
import { TitleBanner } from '../../components/TitleBanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEuroSign,
  faBalanceScale
} from '@fortawesome/free-solid-svg-icons'
import Button from "@material-ui/core/Button";

export const LegalPage = (props) => {
  const classes = styles();
  const history = useHistory();

  return (
    <Grid item container direction="column" alignItems="center" className={classes.container}>
      <TitleBanner title="JURIDIQUE" img={legalImage} color="secondary" />
      <Grid item container alignContent="center" justify="center">
        <Typography variant="h6" className={classes.description}>
          Parce que l’exercice libéral de la profession d’IDE n’est pas une sinécure, le SAIIL vous propose de vous offrir l’expertise du cabinet CHOLEY & VIDAL Avocats spécialisés en droit des professions libérales pour tout besoin de conseils et d’orientation. Le SAIIL vous permet de contacter par mail gratuitement et directement les avocats qui vous accompagneront rapidement dans vos problématiques (procédures contentieuses avec l’assurance maladie et disciplinaires, conseils juridiques et rédaction de contrats d’exercice en commun, de collaboration et de cession de patientèle, parts de société...) et vous feront bénéficier de tarifs privilégiés.
        </Typography>
      </Grid>
      <Grid item container direction="row" alignContent="center" justify="center">
        <Button
          variant="contained"
          classes={{ root: classes.button }}
          onClick={() => history.push('/contact')}
        >
          <Typography variant="h1" className={classes.titleButton}>Contactez l'aide juridique</Typography>
        </Button>
      </Grid>
      <Grid item container direction="column" className={classes.blockContainer}>
        <Grid item container direction="row" className={classes.rowContainer}>
          <Grid item className={classes.imgContainer}>
            <img src={legalFrame} alt="cadre juridique" className={classes.img} />
          </Grid>
          <Grid item container direction="column" alignContent="center" justify="center" alignItems="center" className={classes.legalCategory} onClick={() => history.push('/litigation')}>
            <FontAwesomeIcon icon={faEuroSign} className={classes.icon} />
            <Typography variant="h3" className={classes.title}>
              CONTENTIEUX
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" className={classes.rowContainer}>
          <Grid item container direction="column" alignContent="center" justify="center" alignItems="center" className={classes.legalCategory} onClick={() => history.push('/legalFrame')}>
            <FontAwesomeIcon icon={faBalanceScale} className={classes.icon} />
            <Typography variant="h3" className={classes.title}>
              CADRE JURIDIQUE
            </Typography>
          </Grid>
          <Grid item className={classes.imgContainer}>
            <img src={litigationPic} alt="contentieux" className={classes.img} />
          </Grid>
        </Grid>
        {/*Mobile*/}
        <Grid item container direction="column" className={classes.columnContainer}>
          <Grid item className={classes.imgContainer}>
            <img src={litigationPic} alt="contentieux" className={classes.img} />
          </Grid>
          <Grid item container direction="column" alignContent="center" justify="center" alignItems="center" className={classes.legalCategory} onClick={() => history.push('/litigation')}>
            <FontAwesomeIcon icon={faEuroSign} className={classes.icon} />
            <Typography variant="h3" className={classes.title}>
              CONTENTIEUX
            </Typography>
          </Grid>
          <Grid item className={classes.imgContainer}>
            <img src={legalFrame} alt="cadreJuridique" className={classes.img} />
          </Grid>
          <Grid item container direction="column" alignContent="center" justify="center" alignItems="center" className={classes.legalCategory} onClick={() => history.push('/legalFrame')}>
            <FontAwesomeIcon icon={faBalanceScale} className={classes.icon} />
            <Typography variant="h3" className={classes.title}>
              CADRE JURIDIQUE
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LegalPage;
