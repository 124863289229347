import { fade, makeStyles } from '@material-ui/core/styles/';

export default makeStyles((theme) => ({
  goBackButton: {
    background: theme.palette.primary.main,
    padding: theme.spacing(1),
    '&:hover': {
      background: fade(theme.palette.primary.main, .9)
    }
  },
  icon: {
    color: '#fff'
  },
}));
