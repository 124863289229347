import React from 'react';
import PropTypes from 'prop-types';
import SessionCard from '../SessionCard';
import { Grid } from '@material-ui/core';
import { formatDateToFrenchFormat, formatHour } from '../../../utils/services/formats';

export const SessionsList = ({ sessions }) => {

  return <Grid container direction="column" alignItems="center">
    {sessions?.map((session, key) => {
      const localizedDate = formatDateToFrenchFormat(session.fields.date) + " à " + formatHour(session.fields.date);
      return <SessionCard
        key={key}
        title={session.fields.title}
        image={session.fields.image.fields.file.url}
        date={localizedDate}
        author={session.fields.name}
        description={session.fields.excerpt}
        id={session.id}
      />
    })}
  </Grid>
};

export default SessionsList

SessionsList.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    fields: PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      excerpt: PropTypes.object.isRequired,
    })
  })).isRequired
};