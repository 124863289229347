import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import NewsPageImage from '../../assets/image/newsPage.jpg';
import styles from './style';
import { getAllContentsLaunched } from '../../store/reducer';
import { Article } from '../../components/Article';
import { TitleBanner } from '../../components/TitleBanner';
import Loader from '../../components/Loader';
import Subtitle from '../../components/Subtitle';

export const NewsPage = (props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { contentsList, getAllContentsLoading } = useSelector((state) => (
    {
      contentsList: state.getIn(['store', 'contentsList']),
      getAllContentsLoading: state.getIn(['store', 'getAllContentsLoading']),
    }
  ));

  useEffect(() => {
    dispatch(getAllContentsLaunched({ contentType: 'PREMIUM_ARTICLE' }));
  }, []);

  return (
    <Grid item container direction="column" className={classes.container}>
      <TitleBanner title="ACTUALITÉS" img={NewsPageImage} color="secondary" />
      <Subtitle text={"Information politique, news & événements : ne pas perdre le fil de l’actualité pour rester informé : des articles de décryptage de l’actualité santé et des articles en lien direct avec l’actualité de la profession infirmière. \n Difficile parfois sur les réseaux sociaux de trouver des informations fiables, vérifiées et non partisanes. Fort de son indépendance et de son expertise de longue date du monde de la santé, le SAIIL entend décrypter l’essentiel de l’actualité pour y porter un regard aiguisé et stimulant. Elle est appuyée en cela par des journalistes spécialisés et apolitiques."} />
      <Grid item container direction="row" alignContent="center" justify="center">
        {
          // check contentsList isArray to prevent error & force refetching data when coming from /formations
          !getAllContentsLoading && contentsList && Array.isArray(contentsList)
            ? contentsList?.map((article) => (
              <Grid key={article.id} item className={classes.article}>
                <Article
                  id={article.id}
                  article={article.fields}
                />
              </Grid>
            ))
            : <Loader />
        }
      </Grid>
    </Grid>
  );
};

export default NewsPage;
