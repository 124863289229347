import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button from '@material-ui/core/Button';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Collapse from '@material-ui/core/Collapse';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'

export default function DownloadCard({ color, title, items, icon, searchedId }) {
  const classes = styles();
  let isOpenByDefault = false;
  if(items) {
    items.map(item => {
      item?.contents?.map((content) => {
        if(content.id === searchedId) isOpenByDefault = true;
      });
    });
  }

  const [open, setOpen] = useState(isOpenByDefault);

  const myRef = useRef(null)

  useEffect(() => {
    if(searchedId && myRef && myRef.current) {
      myRef.current.scrollIntoView(true);
    }
  }, [searchedId, myRef.current]);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Button
        variant="contained"
        classes={{ root: clsx(classes.button, { [classes.buttonSecondary]: color === 'secondary' }) }}
        onClick={() => setOpen(!open)}
      >
        <Grid item container direction="row" alignContent="center" alignItems="center" justify="space-evenly">
          {icon}
          <Typography variant="h5" className={classes.title}>{title}</Typography>
          {
            !open
              ? <ExpandMoreRoundedIcon className={classes.arrowIcon} />
              : <ExpandLessRoundedIcon className={classes.arrowIcon} />
          }
        </Grid>
      </Button>
      <Collapse in={open}
        className={classes.listWrapper}>
        <Card>
          <List className={classes.list}>
            {
              items?.map(item =>
                <>
                  <ListItem key={item.title}>
                    <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
                      <FiberManualRecordIcon fontSize="small" />
                    </ListItemAvatar>
                    <ListItemText primary={item.title} className={classes.subcategoryName} />
                  </ListItem>
                  {item?.contents?.map((content) => (
                    <ListItem key={content.fields.title} className={classes.downloadLine}>

                      <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
                        <FiberManualRecordIcon fontSize="small" color="secondary" />
                      </ListItemAvatar>
                      <Grid className={classes.documentContainer}>
                        <ListItemText primary={content.fields.title} className={searchedId === content.id ? classes.subcategoryNameSearched : classes.subcategoryName} />
                        <ListItemSecondaryAction classes={{ root: classes.secondaryAction }}>
                          <div className={classes.subcategoryAnchor} ref={searchedId === content.id ? myRef: null}></div>
                          <a
                            href={content.fields?.file?.fields?.file?.url}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            edge="end"
                            aria-label="download"
                            className={classes.downloadButton}>
                            <FontAwesomeIcon icon={faArrowAltCircleDown} color="black" size="2x" />
                          </a>
                        </ListItemSecondaryAction>
                      </Grid>
                    </ListItem>
                  ))
                  }
                </>
              )
            }
          </List>
        </Card>
      </Collapse>
    </Grid>
  );
}
