import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid, IconButton,
  InputAdornment,
  Paper, Popover,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx'

import CustomTextField from '../../../Inputs/CustomTextField';
import CustomButton from '../../../Button';
import Loader from "../../../Loader/index";
import styles from './styles';
import { switchPatientTypes } from '../../../../utils/services/formats';
import { PATIENT_STANDARD } from '../../../../utils/constants';
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ContextForm = ({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
  const { age, location, sex, patientType } = values.context;
  const classes = styles();
  const PATIENT_TYPES = ["CANCEREUX", "DIABETIQUE", "DEPENDANCE", "MUCOVISCIDOSE", "PSYCHO_DISORDER", "IMMUNODEPRIME", "PATIENT_PALLIATIF", PATIENT_STANDARD];
  const HALF_PATIENT_TYPES_LENGTH = Math.ceil(PATIENT_TYPES.length / 2);
  const STANDARD_CHECKED = values.context.patientType.includes(PATIENT_STANDARD);
  const PATIENT_TYPES_LIST_WITHOUT_STANDARD = PATIENT_TYPES.filter(x => x !== PATIENT_STANDARD);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const { getQuotesLoading } = useSelector((state) => ({
    getQuotesLoading: state.getIn(['store', 'getQuotesLoading']),
  }));

  return (
    <Grid item container direction="column" alignItems="center" justify="space-evenly"
      className={classes.rootContext}>
      <Box my={5}>
        <Typography variant="h3">CONTEXTE DE SOIN</Typography>
      </Box>

      <Paper className={classes.firstPaper}>
        <FormControl component="fieldset" className={classes.radioWrapper}>
          <RadioGroup row aria-label="location" name="context.location" value={location}
            onChange={handleChange} className={classes.flexColumn}>
            <Box>
              <FormControlLabel classes={{ label: classes.radioLabel }} value="CABINET" control={<Radio
                checkedIcon={<CheckCircleOutlineIcon className={classes.checkIcon} />} />} label="Cabinet" />
              <IconButton
                className={classes.buttonIcon}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={(event) => handlePopoverOpen(event)}
                onMouseLeave={handlePopoverClose}
              >
                <FontAwesomeIcon icon={faQuestionCircle} className={classes.infoButton} />
              </IconButton>
            </Box>
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography>Attention : Certains actes ne sont pas facturables s’ils sont réalisés au cabinet, ces actes ne vous seront donc pas proposés en choix possibles pour la cotation</Typography>
            </Popover>
            <FormControlLabel classes={{ label: classes.radioLabel }} value="DOMICILE" control={<Radio
              checkedIcon={<CheckCircleOutlineIcon className={classes.checkIcon} />} />} label="Domicile" />
          </RadioGroup>
          {!!touched?.context?.location && !!errors?.context?.location && (
            <FormHelperText error={!!touched?.context?.location && !!errors?.context?.location}
              classes={{ root: classes.redRootError }} htmlFor="filled-adornment-textfield">
              <span className={classes.helperText}>
                <ErrorIcon className={classes.icon} />
                {errors?.context?.location}
              </span>
            </FormHelperText>
          )}
        </FormControl>
      </Paper>

      <Paper className={clsx(classes.secondPaper, classes.flexColumn)}>
        <Box mx={2} my={1}>
          <CustomTextField
            endAdornment={<InputAdornment position="end">ans</InputAdornment>}
            value={age}
            id="context.age"
            onBlur={handleBlur}
            onChange={handleChange}
            label={"Age"}
            error={!!touched?.context?.age && !!errors?.context?.age}
            helperText={!!touched?.context?.age && !!errors.context?.age ? errors?.context?.age : ''}
            redError={errors?.context?.age ? true : false}
            type="number"
          />
        </Box>
        <FormControl component="fieldset" className={classes.radioWrapper}>
          <RadioGroup row aria-label="genre" name="context.sex" value={sex} onChange={handleChange}
            className={classes.flexColumn}>
            <FormControlLabel classes={{ label: classes.radioLabel }} value="FEMALE" control={<Radio
              checkedIcon={<CheckCircleOutlineIcon className={classes.checkIcon} />} />} label="Femme" />
            <FormControlLabel classes={{ label: classes.radioLabel }} value="MALE" control={<Radio
              checkedIcon={<CheckCircleOutlineIcon className={classes.checkIcon} />} />} label="Homme" />
          </RadioGroup>

          {!!touched?.context?.sex && !!errors?.context?.sex && (
            <FormHelperText error={!!touched?.context?.sex && !!errors?.context?.sex}
              classes={{ root: classes.redRootError }} htmlFor="filled-adornment-textfield">
              <span className={classes.helperText}>
                <ErrorIcon className={classes.icon} />
                {errors?.context?.sex}
              </span>
            </FormHelperText>
          )}
        </FormControl>
      </Paper>

      {/* The patient-types array is cut in halves to render a 2-columns grid */}
      {getQuotesLoading ?
        <Loader />
        :
        <Grid container className={classes.typesWrapper}>
          <Grid item xs={12} sm={6}>
            {PATIENT_TYPES.slice(0, HALF_PATIENT_TYPES_LENGTH).map((type, key) => {
              return (
                <Box key={key} my={1} className={classes.radioBox}>
                  <FormControlLabel
                    control={<Checkbox
                      name={`context.patientType`}
                      id={`patientType`}
                      value={type}
                      onChange={handleChange}
                      checked={patientType?.indexOf(type) > -1}
                      disabled={type !== PATIENT_STANDARD && STANDARD_CHECKED || type === PATIENT_STANDARD && values.context.patientType?.some(r => PATIENT_TYPES_LIST_WITHOUT_STANDARD.includes(r))}
                    />}
                    label={switchPatientTypes(type)}
                    classes={{
                      label: clsx(classes.radioLabel, classes.checkboxLabel),
                      root: classes.formControlLabelRoot
                    }}
                  />
                </Box>
              )
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {PATIENT_TYPES?.slice(HALF_PATIENT_TYPES_LENGTH, PATIENT_TYPES.length).map((type, key) => {
              return (
                <Box key={key} my={1} className={classes.radioBox}>
                  <FormControlLabel
                    control={<Checkbox
                      name={`context.patientType`}
                      id={`patientType`}
                      value={type}
                      onChange={handleChange}
                      checked={patientType?.indexOf(type) > -1}
                      disabled={type !== PATIENT_STANDARD && STANDARD_CHECKED || type === PATIENT_STANDARD && values.context.patientType?.some(r => PATIENT_TYPES_LIST_WITHOUT_STANDARD.includes(r))}
                    />}
                    label={switchPatientTypes(type)}
                    classes={{
                      label: clsx(classes.radioLabel, classes.checkboxLabel),
                      root: classes.formControlLabelRoot
                    }}
                  />
                </Box>
              )
            })}
          </Grid>
          {!!touched?.context?.patientType && !!errors?.context?.patientType && (

            <FormHelperText error={!!touched?.context?.patientType && !!errors?.context?.patientType}
              classes={{ root: classes.redRootError }} htmlFor="filled-adornment-textfield">
              <span className={clsx(classes.helperText, classes.redError, classes.textLeft)}>
                <ErrorIcon className={classes.icon} />
                {errors?.context?.patientType}
              </span>
            </FormHelperText>
          )}
        </Grid>
      }

      <CustomButton
        variant="contained"
        title="SAISIE DES ACTES"
        type="submit"
        handleClick={handleSubmit}
      />
    </Grid>
  );
};

export default ContextForm;
