import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  img: {
    height: 593,
    width: "100%"
  },
  titleContainer: {
    height: 104,
  },
  primary: {
    backgroundColor: theme.palette.primary.main
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main
  },
  title: {
    color: 'white'
  }
}));
