import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tabsContainerRoot: {
    width: '100%',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center'
    }
  },
  rootTab: {
    width: '24%',
    [theme.breakpoints.up('md')]: {
      width: 250,
    },
  },
  icon: {
    fontSize: 65,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: 25,
    }
  },
  sepa: {
    height: 70,
    width: 100,
    objectFit: 'cover',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      height: 40,
      width: 'auto'
    }
  },
  tabPanel: {
    '& .MuiBox-root': {
      width: '100%',
      padding: theme.spacing(3, 1)
    }
  }
}));
