import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';
import MyAccount from '../../pages/MyAccount';
import PaymentPage from '../../pages/PaymentPage';

const privateRender = (Component, isAuthenticated, isAuthenticating, userDynamo, rest) => (props) => {
  const { fixed } = rest;
  const nowToISOdateFormat = new Date(Date.now()).toISOString();

  if (isAuthenticating || isAuthenticating === undefined) {
    // DO NOTHING
  } else if (isAuthenticated === true) {
    // User Authentified
    if (userDynamo.user.isAdmin) {
      return <Component {...props} />
    } else {
      if (props.location.pathname === '/admin') {
        return <Redirect to="/home" />
      }

      if (userDynamo?.contract?.endDate < nowToISOdateFormat) {
        // if the user's contract is expired
        if (props.location.pathname === '/payment') {
          // only allow access to the payment page and the profile page
          return <PaymentPage {...props} />
        } else {
          return <MyAccount {...props} />
        }
      } else {
        if (userDynamo?.contract?.paymentDone === false && (userDynamo?.contract?.paymentStatus === 'waitingForPayment' || userDynamo?.contract?.paymentStatus === 'waitingForSubscription')) {
          // if the user's contract is not paid
          return <MyAccount />;
        } else if (userDynamo?.contract?.paymentDone === true) {
          // if the user's contract is paid
          return <Component {...props} />;
        } else if ((userDynamo?.contract?.paymentDone === undefined) || (userDynamo?.contract?.paymentStatus === 'paymentFailed')) {
          // if the user's payment failed
          return <PaymentPage {...props} />;
        }
      }
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location.pathname + props.location.search }
        }}
      />
    );
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.getIn(['app', 'isAuthenticated']), null);
  const isAuthenticating = useSelector((state) => state.getIn(['app', 'isAuthenticating']), null);
  const userDynamo = useSelector((state) => state.getIn(['app', 'userDynamo']), null);
  return <Route {...rest} render={privateRender(Component, isAuthenticated, isAuthenticating, userDynamo, rest)} />;
};

export default PrivateRoute;
