import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { NavLink } from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import { Divider } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import ListSubheader from '@material-ui/core/ListSubheader';
import styles from './styles';
import { logoutLaunched } from '../App/reducer';

export const CustomSwipeableDrawer = ({ menu, profile, ...props }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (anchor) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(!open);
  };

  const { user, email } = useSelector((state) => ({
    user: state.getIn(['app', 'userDynamo', 'user']),
    email: state.getIn(['app', 'userData', 'email']),
  }));

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List
        subheader={(
          <ListSubheader component="div" id="nested-list-subheader">
            Menu
          </ListSubheader>
        )}
      >
        {menu.map((item, index) => (
          item.to.startsWith("/") && (
            <NavLink to={item.to} key={index} className={classes.navLink} target={item.target}>
              <ListItem button classes={{ root: classes.menuNavLink }}>
                <ListItemText primary={item.title} />
              </ListItem>
            </NavLink>
          ),
          !item.to.startsWith("/") && (
            <a href={item.to} key={index} className={classes.navLink} rel="noopener noreferrer" target={item.target}>
              <ListItem button classes={{ root: classes.menuNavLink }}>
                <ListItemText primary={item.title} />
              </ListItem>
            </a>
          )
        ))}
      </List>
      <Divider />
      <List
        subheader={(
          <ListSubheader component="div" id="nested-list-subheader">
            {email || user?.email}
          </ListSubheader>
        )}
      >
        {profile.map((item, index) => (
          <NavLink to={item.to} key={index} className={clsx(classes.navLink, classes.menuNavLink)}>
            <ListItem button key={item.title} className={classes.menuNavLink}>
              <ListItemText primary={item.title} />
            </ListItem>
          </NavLink>
        ))}
        <button onClick={() => dispatch(logoutLaunched())} className={clsx(classes.navLink, classes.orange, classes.menuNavLink)}><Typography>Déconnexion</Typography></button>
      </List>
    </div>
  );

  return (
    <React.Fragment key="left">
      <IconButton edge="start" className={classes.menuButton} aria-label="menu" onClick={toggleDrawer()}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer()}
        onOpen={toggleDrawer()}
      >
        {list('left')}
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default CustomSwipeableDrawer;
