import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, IconButton } from '@material-ui/core';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import styles from './styles';

export const GobackButton = () => {
  const classes = styles();
  const history = useHistory();

  return (
    <Box my={2}>
      <IconButton onClick={() => history.goBack()} className={classes.goBackButton}>
        <ArrowBackSharpIcon fontSize="large" className={classes.icon} />
      </IconButton>
    </Box>
  );
}

export default GobackButton