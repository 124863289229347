import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import IconButton from '@material-ui/core/IconButton';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from './styles';
import { Link } from "react-router-dom";

export default function CustomCarousel({ articles, ...props }) {
  const classes = styles();

  return (
    <Carousel
      autoPlay
      infiniteLoop
      renderArrowPrev={(onClickHandler, hasPrev) => hasPrev && (
        <IconButton onClick={onClickHandler} className={classes.arrowButtonLeft}>
          <ArrowBackIosRoundedIcon className={classes.arrowIcon} />
        </IconButton>
      )}
      renderArrowNext={(onClickHandler, hasNext) => hasNext && (
        <IconButton onClick={onClickHandler} className={classes.arrowButtonRight}>
          <ArrowForwardIosRoundedIcon className={classes.arrowIcon} />
        </IconButton>
      )}
      showStatus={false}
      showThumbs={false}
      renderItem={(item) => (
        <div className={classes.view}>
          {item}
        </div>
      )}
      className={classes.root}
    >
      {
        articles.map((article) => (
          <Grid container key={article.title} justify="center">
            <img src={article.image} alt="carousel" />
            <Grid item container alignContent="center" alignItems="center" justify="center" className={classes.mainContainer}>
              <Link to={`/news/${article.id}`} className={classes.link}>
                <Grid item zeroMinWidth container alignContent="center" alignItems="center" justify="space-around" direction="column" className={classes.titleContainer} spacing={0}>
                  <Typography variant="h5" className={classes.title}>{article.title}</Typography>
                  <Typography variant="body2">{article.description}</Typography>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        ))
      }
    </Carousel>
  );
}
