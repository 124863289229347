import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff"
  },
  article: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20
  },
  description: {
    width: "60%",
    marginTop: 100,
    marginBottom: 100
  }
}));
