import React from 'react'
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import styles from './styles';

const InfoBulle = ({ children, isPrimary }) => {
  const classes = styles({ isPrimary });
  return (
    <Box py={7} px={6} className={classes.root}>
      <Typography variant="subtitle1">{children}</Typography>
    </Box>
  )
}

export default InfoBulle

InfoBulle.propTypes = {
  children: PropTypes.node.isRequired,
  isPrimary: PropTypes.bool
};

InfoBulle.defaultProps = {
  isPrimary: 'true'
};