import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    borderRadius: 20,
    width: '100%',
    margin: theme.spacing(1, 0),
    maxHeight: '77.5vh',
    overflow: 'hidden'
  },
  titleWrapper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '20px 20px 0 0',
    textAlign: 'center',
    height: '10vh',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'clamp(1.3rem, 1.5vw, 30px)'
  },
  contentWrapper: {
    overflow: 'scroll',
    maxHeight: 'calc(100% - 10vh)',
    padding: theme.spacing(2),
    scrollbarColor: `${theme.palette.secondary.main} transparent`, // Firefox scrollbar
    scrollbarWidth: 'thin', // Firefox scrollbar
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      background: `transparent transparent`,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: 16,
    },
  },
  clickableAct: {
    paddingLeft: "1.8px",
    fontSize: 18,
    color: '#000000DE',
    display: 'flex',

    alignItems: 'center',
    textAlign: 'left',
    margin: theme.spacing(1, 0),
    border: 'none',
    background: 'none',
    transition: '.1s',
    '&:not(:disabled)': {
      cursor: 'pointer'
    },
    '&:not(:disabled):active': {
      transform: 'translateY(2px)',
    },
    '&:disabled': {
      color: '#00000061'
    },
    '&:disabled svg path': {
      fill: '#00000061'
    }
  },
  iconWrapper: {
    padding: theme.spacing(0, 1.2, 0, 0),
    paddingLeft: 2
  },
  addAct: {
    '& path': {
      fill: fade("#000000DE", .7)
    },
    fontSize: 20,
    marginLeft: -2,

  },
  bold: {
    fontWeight: 'bold'
  },
}));
