import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  timeWrapper: {
    background: ({ isSelected, editable }) => isSelected && editable ? fade(theme.palette.secondary.main, .1) : null,
    border: '2px solid',
    borderColor: ({ isSelected, editable }) => isSelected || !editable ? 'transparent' : fade(theme.palette.neutral.main, .3),
    margin: theme.spacing(0, 0),
    minHeight: "5rem",
    borderRadius: 16,
    padding: theme.spacing(2),
    paddingTop: 0,

  },
  dialogRoot: {
    '& .MuiBackdrop-root': {
      background: fade("#000", .3)
    }
  },
  paperRoot: {
    borderRadius: 30
  },
  category: {
    background: theme.palette.secondary.main,
    padding: theme.spacing(0.3, 2),
    borderRadius: 16,
    color: "#fff",
    fontSize: 16,
    fontWeight: 'bold'
  },
  listItem: {
    fontSize: 16
  },
  buttonIcon: {
    padding: 1,
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main
  },
  removeActButton: {
    color: "#000"
  },
  infoButton: {
    color: "#000"
  },
  time: {
    fontSize: 32,
    borderRadius: 8,
    paddingLeft: theme.spacing(2),
    background: fade('#fff', .3),
    '@media (max-width:599px)': {
      fontSize: 20
    },
  },
  hourHeight: {
    paddingTop: 0,
  },
  key: {
    fontWeight: 'bold'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1),
    width: 500
  },
  actTitle: {
    width: '90%'
  },
  actButton: {
    width: '10%'
  },
  resultText: {
    fontWeight: 'bold',
    fontSize: 21
  },
  disabled: {
    color: "#000"
  }
}));