import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box } from '@material-ui/core';
import styles from './styles';

export const Subtitle = ({ text }) => {
  const classes = styles();
  return (
    <Grid container className={classes.description}>
      {text.split(`\n`).map((p, key) => (
        <Box my={2} key={key}>
          <Typography variant="subtitle1" className={classes.text}>{p.replace(/\n/g, '<br/>')}</Typography>
        </Box>
      ))}
    </Grid >
  );
};

export default Subtitle;

Subtitle.propTypes = {
  text: PropTypes.string.isRequired
};