import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FIRST_TIMEFRAME_KEY } from '../../../utils/constants';
import styles from './styles';

export const FirstTimeframeInfo = ({ setFirstTimeFrameOpen }) => {
  const classes = styles();

  const handleFirstTimeFrame = () => {
    setFirstTimeFrameOpen(false);
    localStorage.setItem(FIRST_TIMEFRAME_KEY, false)
  };

  return (
    <div className={classes.firstTimeFrameZone}>
      <Typography className={classes.firstTimeFrameText}>Commencez par écrire un horaire</Typography>
      <button
        className={classes.firstTimeFrameButton}
        onClick={() => handleFirstTimeFrame()}
      >J'ai compris</button>
      <div className={classes.arrowLeft}></div> {/* Zone's left arrow */}
    </div>
  );
};

export default FirstTimeframeInfo;