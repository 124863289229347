import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff"
  },
  button: {
    paddingLeft: 10,
    marginTop: 30,
    borderRadius: 50,
    height: 98,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    width: '95%',
    [theme.breakpoints.up("md")]: {
      width: 571,
    }
  },
  title: {
    color: "#fff",
    textTransform: 'none',
    fontSize: 'clamp(1.5rem, 3vw, 25px)'
  },
}));
