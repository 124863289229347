import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Grid, Typography} from '@material-ui/core';
import Title from '../../components/Title';
import QuotesTable from '../../components/QuotesTable/Table';
import Loader from '../../components/Loader';
import {getAllQuotesLaunched} from '../../store/reducer';
import styles from './styles';
import {useParams} from "react-router-dom";

const ListQuotationPage = () => {
        const classes = styles();
        const dispatch = useDispatch();
        let { id } = useParams();

        const { user, allQuotes, getAllQuotesLoading } = useSelector((state) => ({
            user: state.getIn(['app', 'userDynamo', 'user']),
            allQuotes: state.getIn(['store', 'allQuotes']),
            getAllQuotesLoading: state.getIn(['store', 'getAllQuotesLoading']),
        }));

        useEffect(() => {
            if (user?.isAdmin) {
                dispatch(getAllQuotesLaunched(id))
            }
        }, []);

    return (
            user?.isAdmin && (
                <>
                    <Title title={`Historique des cotations - ${user.firstName}`} color={"secondary"}/>
                    <Grid item container direction="column" alignContent="center" className={classes.root}>
                        {getAllQuotesLoading
                             &&
                            <Grid container alignItems="center" className={classes.loaderWrapper}>
                                <Loader/>
                            </Grid>
                        }
                        {
                            !getAllQuotesLoading && allQuotes?.length === 0 ?
                                <Grid container justify="center">
                                    <Typography>Cet utilisateur n'a pas encore fait de calcul de cotation</Typography>
                                </Grid>
                            :
                            <QuotesTable quotes={allQuotes}/>
                        }
                    </Grid>
                </>
            )
        );
    }
;
export default ListQuotationPage;
