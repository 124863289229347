import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import QuitQuotationModale from '../../Modale/QuitQuotationModale';

import { setCotationId, setCurrentQuoteData, setQuotePage, setQuotesDefault } from '../../../store/reducer';
import styles from './styles';

export const QuotesHeader = ({ title, isSummary }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { quotePage, } = useSelector((state) => ({
    quotePage: state.getIn(['store', 'quotePage']),
  }));

  const classes = styles({ isSummary, quotePage });
  const [open, setOpen] = useState(false);

  const handleNav = () => {
    if (location?.pathname?.includes("cotation-synthese")) {
      setOpen(true);
    } else {
      dispatch(setQuotePage(1));
      dispatch(setQuotesDefault(null))
      history.push('/home')
    }
  }

  const handleEndQuote = () => {
    dispatch(setQuotePage(1));
    dispatch(setCurrentQuoteData(null));
    dispatch(setQuotesDefault(null));
    dispatch(setCotationId(''));
    history.push('/home')
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Grid item container alignItems="center" justify="center">
          <div className={classes.quotesAppBar}>
            <Typography className={classes.quotesTitle}>{title}</Typography>
          </div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => handleNav()}
            className={classes.homeButton}
          >
            <FontAwesomeIcon icon={faHome} />
          </IconButton>
        </Grid>
      </Toolbar>
      {open && <QuitQuotationModale endQuoteOpen={open} setEndQuoteOpen={setOpen} handleEndQuote={handleEndQuote} fromQuotationNav />}
    </AppBar>
  );
};

export default QuotesHeader;

QuotesHeader.propTypes = {
  title: PropTypes.string.isRequired,
  isSummary: PropTypes.bool
};

QuotesHeader.defaultProps = {
  isSummary: false
};