import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import styles from './style';
import {TitleBanner} from '../../components/TitleBanner';
import {useDispatch, useSelector} from "react-redux";
import {getAllContentsLaunched} from "../../store/reducer";
import Loader from "../../components/Loader";
import Typography from "@material-ui/core/Typography";
import RichTextRenderer from "../../components/RichTextRenderer";

export const LegalMentionsPage = () => {
    const classes = styles();
    const dispatch = useDispatch();

    const {contentsList, getAllContentsLoading} = useSelector((state) => (
        {
            contentsList: state.getIn(['store', 'contentsList']),
            getAllContentsLoading: state.getIn(['store', 'getAllContentsLoading']),
        }
    ));

    useEffect(() => {
        dispatch(getAllContentsLaunched({contentType: 'LEGAL_MENTIONS'}));
    }, []);

    return (
        <Grid item container>
            <TitleBanner title="Mentions Légales" color="secondary"/>
            <Grid item container direction="column" alignItems="center" className={classes.container}>
            {
                getAllContentsLoading && !contentsList ?
                    <Loader/>
                    :
                    <>
                        <Grid item container justify="center" className={classes.titleContainer}>
                            <Typography variant="h5" className={classes.secondTitle}>{contentsList?.map((item) => item.fields.firstSubtitle)}</Typography>
                        </Grid>
                        <Grid item container justify="center" className={classes.parapgraphContainer}>
                        {
                            contentsList?.map((item) => <RichTextRenderer key={item.id} details={item.fields.paragraphe1}/>)
                        }
                        </Grid>
                        <Grid item container justify="center" className={classes.titleContainer}>
                            <Typography variant="h5" className={classes.secondTitle}>{contentsList?.map((item) => item.fields.secondSubtitle)}</Typography>
                        </Grid>
                        <Grid item container justify="center" className={classes.parapgraphContainer}>
                        {
                            contentsList?.map((item) => <RichTextRenderer key={item.id} details={item.fields.paragraphe2}/>)
                        }
                        </Grid>
                    </>
            }
            </Grid>
        </Grid>
    );
};

export default LegalMentionsPage;

