import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import ResultCard from '../../components/ResultCard';
import InfoBulle from '../../components/InfoBulle';
import Loader from '../../components/Loader';
import Icon from '../../components/Icon';
import GobackButton from '../../components/GoBackButton';
import { useLocation } from "react-router-dom";
import { push } from 'connected-react-router';
import { generateResultDetails } from '../../utils/services/search';
import { getContentTypeEntriesLaunched, getSearchLaunched, setSearchSkip, searchSubCategory } from '../../store/reducer';
import { SEARCH_SKIP, SEARCH_LIMIT, JURIDIQUE, CONTENTIEUX } from '../../utils/constants';
import { formatTimestampToDateWithDashes } from '../../utils/format';
import defaultImage from '../../assets/image/logo.svg';
import useStyles from './styles';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQueryParams(qName) {
  return (new URLSearchParams(useLocation().search)).get(qName);
}

export const SearchResultsPage = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const searchValue = useQueryParams("q");

  const { searchResults, isLoading, searchSkip, total, contentTypeEntries } = useSelector(state => ({
    searchResults: state.getIn(['store', 'searchResults']),
    total: state.getIn(['store', 'searchResults', 'res', 'total']),
//    searchValue: state.getIn(['store', 'searchValue', 'value']),
    isLoading: state.getIn(['store', 'isLoading']),
    searchSkip: state.getIn(['store', 'searchSkip']),
    contentTypeEntries: state.getIn(['store', 'contentTypeEntries']),
  }));
  useEffect(() => {
//    if(!searchValue) return
    if(!searchValue) {
      dispatch(push("/"));
    } else {
      dispatch(getSearchLaunched({
        value: searchValue,
        skip: SEARCH_SKIP
      }));
      dispatch(setSearchSkip(SEARCH_SKIP));
    }
  }, [searchValue]);

  // We need to filter out past training sessions since the runtime call to contentful's API
  // returns every matches, whereas we display only future training sessions
  const formattedDate = formatTimestampToDateWithDashes(Date.now());
  // We need a custom map to always return the results that don't have a date field
  const filterPastResults = searchResults.items?.map(result => {
    if (!result.fields.date) {
      return result
    } else {
      if (result.fields.date > formattedDate) {
        return result
      }
      return null
    }
  });
  const resultsFilteredByDate = filterPastResults?.filter(x => !!x);

  // START FETCHING SECTION_NAME
  const [ids, setIds] = useState([]);
  // Fetch the categories to extract the section name and enable redirection to the right documents page
  useEffect(() => {
    dispatch(getContentTypeEntriesLaunched({ id: 'categories' }))
  }, [dispatch]);

  useEffect(() => {
    const sections = contentTypeEntries.map(entry => {
      return {
        id: entry?.sys?.id,
        sectionName: entry?.fields?.section
      }
    })
    setIds(sections)
  }, [contentTypeEntries])

  const switchDocsLink = (categoryId) => {
    const section = ids?.find(x => x.id === categoryId)?.sectionName || categoryId;
    switch (section) {
      case JURIDIQUE:
        return `/legalFrame`
      case CONTENTIEUX:
        return `/litigation`
      case "tracabilite":
        return `/traceability`
      default:
        return `/${section}`
    }

  }
  // END FETCHING SECTION_NAME

  // START PAGINATION
  const getFirstResults = () => {
    dispatch(getSearchLaunched({
      value: searchValue,
      skip: SEARCH_SKIP
    }))
    dispatch(setSearchSkip(SEARCH_SKIP));
  }

  const getMoreResults = () => {
    dispatch(getSearchLaunched({
      value: searchValue,
      skip: searchSkip + SEARCH_LIMIT
    }))
    dispatch(setSearchSkip(searchSkip + SEARCH_LIMIT));
  }

  const getLessResults = () => {
    dispatch(getSearchLaunched({
      value: searchValue,
      skip: Math.max(searchSkip - SEARCH_LIMIT, 0)
    }))
    dispatch(setSearchSkip(Math.max(searchSkip - SEARCH_LIMIT, 0)));
  }

  const getLastPage = () => {
    dispatch(getSearchLaunched({
      value: searchValue,
      skip: total - SEARCH_LIMIT
    }))
    dispatch(setSearchSkip(total - SEARCH_LIMIT));
  }

  const CHECK_FIRST_PAGE = searchSkip === 0;
  const CHECK_LAST_PAGE = (resultsFilteredByDate?.length < SEARCH_LIMIT);
  const PAGE_NUMBER = Math.round((searchSkip + SEARCH_LIMIT) / SEARCH_LIMIT);
  // END PAGINATION

  const dispatchSearchSubCategory = (result) => {
    dispatch(searchSubCategory(result))
  }
  const renderSearchResults = () => (
    resultsFilteredByDate?.length > 0
      ? (
        <>
          <Box p={6}>
            <Typography variant="subtitle1" className={classes.bold}>« {searchValue} »</Typography>
          </Box>
          {resultsFilteredByDate?.map((result, key) => {
            const resultTitle = result?.fields?.title
            const subCategory = result?.fields?.parent?.fields?.title
            const resultDetails = generateResultDetails(result?.sys?.contentType?.sys?.id, result, searchValue);
            const section = result?.fields?.parent?.fields?.parent?.fields?.section || result?.fields?.parent?.fields?.section || result?.fields?.section
            return <ResultCard
              key={key}
              description={resultDetails.description}
              image={resultDetails.image || defaultImage}
              link={resultDetails.link || switchDocsLink(result?.fields?.parent?.fields?.parent?.sys?.id || section)}
              internal={resultDetails.internal}
              title={resultDetails.title}
              searchValue={searchValue}
              author={resultDetails.author}
              date={resultDetails.date}
              subCategory={subCategory}
              resultTitle={resultTitle}
              dispatchSearchSubCategory={dispatchSearchSubCategory}
            />
          })}
          <Grid container alignItems="center" justify="center">
            <IconButton onClick={() => getFirstResults()} disabled={CHECK_FIRST_PAGE}>
              <Icon icon={faAngleDoubleLeft} size="1x" variant="black" disabled={CHECK_FIRST_PAGE} />
            </IconButton>
            <IconButton onClick={() => getLessResults()} disabled={CHECK_FIRST_PAGE}>
              <Icon icon={faAngleLeft} size="1x" variant="black" disabled={CHECK_FIRST_PAGE} />
            </IconButton>
            <Box px={2}>
              <Typography variant="subtitle1" className={classes.bold}>{PAGE_NUMBER}</Typography>
            </Box>
            <IconButton onClick={() => getMoreResults()} disabled={CHECK_LAST_PAGE || searchSkip + SEARCH_LIMIT === total}>
              <Icon icon={faAngleRight} size="1x" variant="black" disabled={CHECK_LAST_PAGE || searchSkip + SEARCH_LIMIT === total} />
            </IconButton>
            <IconButton onClick={() => getLastPage()} disabled={CHECK_LAST_PAGE || searchSkip + SEARCH_LIMIT === total || !!searchResults?.items?.find(result => result.fields.date)}>
              <Icon icon={faAngleDoubleRight} size="1x" variant="black" disabled={CHECK_LAST_PAGE || searchSkip + SEARCH_LIMIT === total || !!searchResults?.items?.find(result => result.fields.date)} />
            </IconButton>
          </Grid>
        </>
      )
      : searchValue && (
        <>
          <InfoBulle isPrimary className={classes.bold}>Aucun résultat ne correspond à votre recherche « {searchValue} »</InfoBulle>
          <Box my={6}>
            <GobackButton action={searchSkip > 0 ? () => getLessResults() : () => <Redirect to={{pathname:'/'}} /> } />
          </Box>
        </>
      )
  );

  return (
    <div>
      {!searchValue
        ? (
          <Grid container direction="column" className={classes.wrapper} justify="center" alignItems="center">
            <Loader />
          </Grid>
        ) : (
          <>
            <Grid container direction="column" className={classes.wrapper} justify="center" alignItems="center">
              {isLoading
                ? <Loader />
                : renderSearchResults()
              }
            </Grid>
          </>
        )}
    </div>
  )
}

export default SearchResultsPage;
