import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  description: {
    width: "90%",
    margin: "80px auto 30px",
    '@media (max-width: 959px)': {
      margin: "10px auto 10px"
    }  
  },
  text: {
    lineHeight: 1.3,
    fontSize: "clamp(16px, 3vw, 23px)",
    '@media (max-width: 959px)': {
      marginTop: 0,
    }  
  }
}));