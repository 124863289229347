import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Box, Typography } from '@material-ui/core';

import styles from './styles';
import { addProtocol } from '../../utils/services/formats';

export const RichTextRenderer = ({ details }) => {
  const classes = styles();
  const title = details?.titre || details?.title?.title

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span><strong>{text}</strong></span>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Box className={classes.descriptionContainer}>
          <Typography variant="body1">{children}</Typography>
        </Box>
      ),
      [BLOCKS.HEADING_1]: (node, children) => <Typography variant="subtitle1">{children}</Typography>,
      [BLOCKS.HEADING_2]: (node, children) => <Typography variant="body1">{children}</Typography>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => <img src={node.data.target.gatsbyImageData} alt={title} className={classes.image} />,
      [INLINES.HYPERLINK]: ({ data }, children) => <a href={addProtocol(data.uri, 'https')} target="_blank" rel="noreferrer" className={classes.link}>{children}</a>
    },
  };

  return documentToReactComponents(details, options);
}

export default RichTextRenderer

RichTextRenderer.propTypes = {
  details: PropTypes.object.isRequired,
};
