import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import styles from './styles';

export const SectionTitle = ({ title, icon }) => {
  const classes = styles();
  return (
    <Box className={classes.wrapper}>
      <Grid container className={classes.container} justify="center" alignItems="center">
        <FontAwesomeIcon icon={icon} size="2x" className={classes.icon} color="white" />
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <div className={classes.background}></div>
    </Box>
  )
};

export default SectionTitle;

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object
};


SectionTitle.defaultProps = {
  icon: faFile
};