import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 1,
    margin: 'clamp(3rem, 3vw, 5rem) 0'
  },
  container: {
    width: 'clamp(250px, 35vw, 400px)',
    height: "6vw",
    minHeight: 80,
    background: theme.palette.secondary.main,
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: `0 ${theme.spacing(2)}px`,
    color: '#fff',
    fontSize: 'clamp(.9rem, 2vw, 25px)',
  },
  background: {
    background: theme.palette.primary.main,
    position: 'absolute',
    top: 30,
    left: 30,
    width: 'clamp(250px, 35vw, 400px)',
    height: '6vw',
    minHeight: 80,
    content: '""',
    zIndex: -1
  }
}));