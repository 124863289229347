import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import styles from './styles';
import { Box } from '@material-ui/core';
import { addProtocol } from '../../utils/format';

export default function Footer({ ...props }) {
  const classes = styles();

  return (
    <Grid container alignItems="center" justify="center" className={classes.container} {...props}>
      <Divider className={classes.divider} />
      <Box>
        <Typography variant="body2" className={classes.typo}>SAIIL 2021 - Réalisé par BeeForYou - 
          <a href={`${addProtocol(process.env.REACT_APP_URL_PUBLIC_SITE)}/legal`} target="_blank" rel="noreferrer noopener" className={classes.navLink}>Mentions légales</a>
        </Typography>
      </Box>
    </Grid>
  );
}
